import React, { Component, forwardRef } from 'react';
import { Link, Redirect } from 'react-router-dom';

import MaterialTable from 'material-table';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { LocalizationTheme } from '../../../../utils/utils.js';
import { GeTypeMapping, DisplayMessage } from '../../../../services/helpers.js';
import FontDownloadRoundedIcon from '@material-ui/icons/FontDownloadRounded';
import { FormGroup, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import { SelectTheme } from '../../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";
import Loader from '../../../Loader.js';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    FontDownloadRoundedIcon: forwardRef((props, ref) => <FontDownloadRoundedIcon {...props} ref={ref} />)
};

export default class AgendasTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataGrid: [],
            year: (new Date()).getFullYear(),
            fetchingData: true,
            showDelete: false,
            showCopy: false,
            deletedItem: 0,
            copiedAgenda: undefined,
            accessibleMoboOutlets: [],
            editedAgendaId: undefined,
            editedAgenda: undefined,
            showEditAgenda: false,
            requestInProgress: false,
            agendasWasCreated: false,
            redirect: null,
        };
    }

    componentDidMount() {
        this.getData(this.state.year);
    }

    componentWillReceiveProps(newProps) {
        if (newProps.refreshGrid) {
            this.getData(this.state.year);
        }
        this.setState({ redirect: null });
    }

    handleChangeYear = e => {
        this.setState({ year: parseInt(e.target.value) }, () => this.getData(this.state.year));
    };

    getData = (year) => {
        this.setState({ fetchingData: true });
        let initialData = {};
        var resources = this.props.resources;
        initialData.columns = [
            { title: resources["Description"], width: "65%", field: 'description', options: { filter: true, sort: true }, customSort: (a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()) },
            { title: resources["Year"], field: 'year', options: { filter: true, sort: true } },
            { title: resources["IsActive"], field: 'isActive', options: { filter: true, sort: true } },
        ];

        var actions = [
            {
                icon: (rowData) => <Edit />,
                tooltip: resources["Edit"],
                onClick: (event, rowData) => this.setState({ redirect: `agendas/${rowData.id}`, editedAgendaId: rowData.id, year: rowData.year })
            },
            {
                icon: () => <DeleteOutline />,
                tooltip: resources["Delete"],
                onClick: (event, rowData) => this.handleShowDelete(rowData.id)
            }
        ];

        initialData.actions = actions;
        fetch(`/api/mobopro/v1/workshopagendas`,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })

            .then(response => {
                if (!response.ok) {
                    for (var i = 0; i < response.errors.length; i++) {
                        DisplayMessage(response.errors[i], "error");
                    }
                }
                return response.json();
            }).then(result => {
                const currentRows = [];

                result.forEach(function (rowData) {
                    if (rowData.year === year) {

                        currentRows.push({
                            id: rowData.id,
                            description: rowData.description,
                            isActive: rowData.isActive ? resources["True"] : resources["False"],
                            year: rowData.year,
                        });
                    }
                });

                initialData.data = currentRows;

                this.setState({
                    dataGrid: initialData,
                    fetchingData: false
                });
            });
    }

    handleEditAgenda = (itemId, showEditAgendaDialog) => {
        if (showEditAgendaDialog === false) { this.getData(this.state.outletId); }
        this.setState({ showEditAgenda: showEditAgendaDialog, editedAgendaId: itemId });
    }

    handleShowDelete = (itemId) => {
        this.setState({ showDelete: true, deletedItem: itemId });
    }

    handleCloseDelete = () => {
        this.setState({ showDelete: false, deletedItem: undefined });
    }

    handleDeleteAgenda = () => {
        let resources = this.props.resources;
        this.setState({ requestInProgress: true });
        const url = "/api/mobopro/v1/workshopagendas/" + this.state.deletedItem;
        fetch(url, {
            method: 'DELETE',
            headers: new Headers({
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
        })
            .then(response => {
                if (response.ok) {
                    DisplayMessage(resources["AgendaWasDeleted"], "success");
                    this.setState({ showDelete: false, deletedItem: 0, requestInProgress: false });
                    this.getData(this.state.year);
                }
                else {
                    response.json().then(data => {
                        for (var i = 0; i < data.errors.length; i++) {
                            DisplayMessage(data.errors[i], "error");
                        }
                        setTimeout(() => {
                            this.setState({ showDelete: false, deletedItem: 0, requestInProgress: false });
                        }, 300);
                    })
                }
            })
    }

    setLastYearUsed = (year) => {
        this.setState({
            year: year
        });
    }

    render() {
        let resources = this.props.resources;
        const { redirect, editedAgendaId, year, dataGrid, fetchingData, requestInProgress } = this.state;

        if (redirect) { return <Redirect to={{ pathname: redirect, state: { editedAgendaId: editedAgendaId, year: year } }} /> }

        var helperTextYear = year === "" ? resources["YearHelperText"] : '';

        return (
            <div>
                <div className="position-relative py-4">
                    <h3>{resources["PlanningAgendas"]}</h3>
                    <div className={"centered-loader " + (fetchingData ? 'fadeIn' : 'fadeOut')}><CircularProgress /></div>
                    <div className={fetchingData ? 'fadeOut' : 'fadeIn'}>
                        <div className="float-left mt-3">
                            <ThemeProvider theme={SelectTheme}>
                                <FormGroup className=" col-md-10">
                                    <TextField className="helper-text-red " id="year" inputProps={{ maxLength: 4 }} name="year" value={year}
                                        type="number" label={resources["Year"]} helperText={helperTextYear}
                                        variant="outlined" onChange={this.handleChangeYear} />
                                </FormGroup>
                            </ThemeProvider>
                        </div>
                        <div className="float-right mt-3">
                            <div className="float-right mb-3" >

                                <Button id="addNew" component={Link}
                                    to={{ pathname: "/agendas/new", state: { year: this.state.year } }} color="primary" variant="contained" >
                                    <i className="fas fa-plus mr-2" title={resources["AddNewAgenda"]} />
                                    {resources["AddNewAgenda"]}
                                </Button>
                            </div>
                        </div>

                        <div style={{ clear: "both", }}></div>

                        <Paper elevation={3} className="p-3 mt-3">
                            <MuiThemeProvider theme={LocalizationTheme()}>
                                <MaterialTable
                                    icons={tableIcons}
                                    title={""}
                                    localization={{
                                        header: { actions: resources["Actions"] },
                                        body: {
                                            emptyDataSourceMessage: resources["NoRecordsToBeDisplayed"],
                                            deleteTooltip: resources["DeleteTooltip"],
                                            editTooltip: resources["EditTooltip"],
                                        },
                                        toolbar: {
                                            searchPlaceholder: resources["Search"],
                                            searchTooltip: resources["Search"]
                                        },
                                        pagination: {
                                            firstTooltip: resources["FirstTooltip"],
                                            lastTooltip: resources["LastTooltip"],

                                            previousTooltip: resources["PreviousTooltip"],
                                            nextTooltip: resources["NextTooltip"],

                                            labelRowsPerPage: resources["LabelRowsPerPage"],
                                            labelRowsSelect: resources["LabelRowsSelect"],
                                        }
                                    }}
                                    data={dataGrid.data}
                                    columns={dataGrid.columns}
                                    actions={dataGrid.actions}
                                    options={{ emptyRowsWhenPaging: false, pageSize: 10, pageSizeOptions: [10, 15, 20] }}
                                />
                            </MuiThemeProvider>
                        </Paper>
                    </div>
                    <div className={"loader-overlap position-absolute " + (fetchingData ? 'fadeIn' : 'fadeOut')}><div className="container"><Loader /></div></div>

                    <Rodal visible={this.state.showDelete} onClose={this.handleCloseDelete} className="rodal" customStyles={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                    }}>
                        <div className="modal-body" style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>{resources["AreYouSureYouWantToDeleteAgenda"]}</div>
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <button id="btnCancelDelete" className="btn btn-secondary" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }} onClick={this.handleCloseDelete}
                                disabled={requestInProgress}>{resources["Cancel"]}</button>
                            <button className="btn btn-primary" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }} onClick={this.handleDeleteAgenda}
                                disabled={requestInProgress}>{resources["Yes"]}</button>
                        </div>
                        <span className="rodal-close"></span>
                    </Rodal>

                </div>
            </div >
        )
    };
}