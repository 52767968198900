import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import toastr from 'toastr';
import MaterialTable from 'material-table';
import { MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import Rodal from 'rodal';
import 'rodal/lib/rodal.css';

import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import { LocalizationTheme } from '../../../../utils/utils.js';
import { DisplayMessage } from '../../../../services/helpers.js';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { TableIcons } from '../../../../utils/TableIcons'

export default class TimetablesGrid extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: null,
            fetchingData: true,
            selectedTimetableOnModal: null,
            deleteInProgress: false,
            copyInProgress: false,
            loadingCopyModal: false,
            copyTimetable: null,
            copyDescription: '',
            copyValidationEnabled: false,
            showDeleteModal: false,
            showCopyModal: false,
            timetables: [],
            columns: [],
            actions: []
        }
    }

    componentWillMount() {
        this.retrieveTimetables();
        this.populateColumns();
    }

    componentWillReceiveProps(newProps) {
        if (newProps.refreshGrid) {
            this.retrieveTimetables();
            this.populateColumns();
        }

        this.setState({ redirect: null });
    }

    populateColumns = () => {

        const { resources } = this.props;

        var columns = [
            { title: resources["Description"], field: 'description', width: "70%", options: { filter: true, sort: true }, customSort: (a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()) },
            { title: resources["IsActive"], field: 'isActive', width: "20%", options: { filter: true, sort: true }, render: rowData => rowData.isActive ? resources["Yes"] : resources["No"] },
        ];

        var actions = [
            {
                icon: () => <Edit />,
                tooltip: resources["Edit"],
                onClick: (event, rowData) => this.setState({ redirect: `timetables/${rowData.id}` })
            },
            {
                icon: () => <DeleteOutline />,
                tooltip: resources["Delete"],
                onClick: (event, rowData) => this.popDeleteModal(true, rowData.id)
            },
            {
                icon: () => <FileCopyIcon />,
                tooltip: resources["Copy"],
                onClick: (event, rowData) => this.popCopyModal(true, rowData.id)
            }
        ]

        this.setState({ columns, actions })
    }

    retrieveTimetables = () => {
        this.setState({ fetchingData: true }, () => {
            var publicKey = localStorage.getItem('publicKey');
            var authorizationToken = localStorage.getItem('authorizationToken');
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'PublicKey': publicKey, 'Authorization-token': authorizationToken }
            };

            fetch("/api/mobopro/v1/workshoptimetables", requestOptions)
                .then(response => {
                    response.json().then(data => {
                        if (!response.ok) {
                            for (var i = 0; i < data.errors.length; i++) {
                                toastr.error(data.errors[i]);
                            }
                            this.setState({ fetchingData: false, timetables: [] })
                        }
                        else {
                            this.setState({ fetchingData: false, timetables: data })
                        }
                    })
                });
        })
    }

    deleteSelectedTimetable = () => {
        const { deleteInProgress } = this.state;
        const { resources } = this.props;
        if (deleteInProgress) return;

        this.setState({ deleteInProgress: true }, () => {
            const { selectedTimetableOnModal } = this.state;

            var publicKey = localStorage.getItem('publicKey');
            var authorizationToken = localStorage.getItem('authorizationToken');
            const requestOptions = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json', 'PublicKey': publicKey, 'Authorization-token': authorizationToken }
            };

            fetch(`/api/mobopro/v1/workshoptimetables/${selectedTimetableOnModal}`, requestOptions)
                .then(response => {
                    this.setState({ showDeleteModal: false, selectedTimetableOnModal: null }, () => {
                        if (response.ok) {
                            this.retrieveTimetables();
                            setTimeout(() => { this.setState({ deleteInProgress: false }) }, 300);
                            DisplayMessage(resources["TimetableDeletedSuccessfully"], "success", 0);
                        }
                        else {
                            response.json().then(data => {
                                for (var i = 0; i < data.errors.length; i++) {
                                    toastr.error(data.errors[i]);
                                }
                                setTimeout(() => { this.setState({ deleteInProgress: false }) }, 300);
                            })
                        }
                    });
                })
        })
    }

    loadTimetableWhichShouldBeCopied = () => {

        const { selectedTimetableOnModal } = this.state;

        var publicKey = localStorage.getItem('publicKey');
        var authorizationToken = localStorage.getItem('authorizationToken');
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'PublicKey': publicKey, 'Authorization-token': authorizationToken }
        };

        fetch(`/api/mobopro/v1/workshoptimetables/${selectedTimetableOnModal}`, requestOptions)
            .then(response => {
                response.json().then(data => {
                    if (!response.ok) {
                        for (var i = 0; i < data.errors.length; i++) {
                            toastr.error(data.errors[i]);
                        }
                        this.setState({ loadingCopyModal: false, copyTimetable: null })
                    }
                    else {
                        this.setState({ loadingCopyModal: false, copyTimetable: data })
                    }
                })
            });
    }

    copySelectedTimetable = () => {
        const { copyTimetable, copyDescription } = this.state;
        const { resources } = this.props;

        var payload = {
            description: copyDescription,
            isActive: copyTimetable.isActive,
            details: copyTimetable.details
        }

        fetch('/api/mobopro/v1/workshoptimetables', {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: JSON.stringify(payload)
        }).then(response => {
            response.json().then(data => {
                if (!response.ok) {
                    var errors = data.errors
                    for (var i = 0; i < errors.length; i++) {
                        DisplayMessage(errors[i], "error", 0);
                    }
                }
                else {
                    this.popCopyModal(false, null);
                    DisplayMessage(resources["TimetableCopiedSuccessfully"], "success", 0);
                    this.retrieveTimetables();
                }
                this.setState({ copyInProgress: false });
            })
        });
    }

    popDeleteModal = (showModal, selectedTimetableOnModal) => {
        this.setState({ showDeleteModal: showModal, selectedTimetableOnModal });
    }

    popCopyModal = (showModal, selectedTimetableOnModal) => {
        this.setState({ showCopyModal: showModal, loadingCopyModal: true, copyDescription: '', selectedTimetableOnModal }, () => {
            if (showModal) {
                this.loadTimetableWhichShouldBeCopied();
            }
        });
    }

    renderDeleteModal = () => {
        const { showDeleteModal, requestInProgress } = this.state;
        const { resources } = this.props;

        return (< Rodal visible={showDeleteModal} onClose={() => this.popDeleteModal(false, null)} className="rodal" customStyles={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
        }}>
            <div className="modal-body" style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>{resources["AreYouSureYouWantToDeleteTimeTable"]}</div>
            <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <button className="btn btn-secondary" id="btnCancelDelete" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                    onClick={() => this.popDeleteModal(false, null)} disabled={requestInProgress}>{resources["Cancel"]}</button>
                <button className="btn btn-primary" id="btnDelete" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }}
                    onClick={this.deleteSelectedTimetable} disabled={requestInProgress}>{resources["Yes"]}</button>
            </div>
            < span className="rodal-close"></span>
        </Rodal >
        )
    }

    onChangeCopyDescription = (value) => {
        this.setState({ copyDescription: value });
    }

    renderCopyModal = () => {
        const { showCopyModal, loadingCopyModal, copyInProgress, requestInProgress, copyDescription, copyTimetable, copyValidationEnabled } = this.state;
        const { resources } = this.props;

        return (
            <Rodal visible={showCopyModal} onClose={() => this.popCopyModal(false)} className="rodal" height="270" >
                {(copyInProgress || loadingCopyModal) ? <div className="centered-loader"><CircularProgress /></div> : (
                    <div>
                        <div className="modal-body d-flex flex-column">
                            <div>{resources["CopyTimetable"]}</div>
                            <div className="mt-3">{copyTimetable && copyTimetable.description}</div>
                            <div className="mt-3">
                                <TextField id="timetable-description-copy-input" className="helper-text-red" value={copyDescription} inputProps={{ maxLength: 100 }} error={copyValidationEnabled} label={resources["Description"]} onChange={(e) => this.onChangeCopyDescription(e.target.value)} size="small" helperText="Input of description is mandatory" variant="outlined" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" id="btnCancel" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }} onClick={() => this.popCopyModal(false)} disabled={requestInProgress} variant="outlined" color="primary" >
                                {resources["Cancel"]}
                            </button>
                            <button className="btn btn-primary" id="btnCopy" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }} onClick={() => this.copySelectedTimetable()} disabled={requestInProgress} variant="contained" color="primary" >
                                {resources["Copy"]}
                            </button>
                        </div>
                        <span className="rodal-close"></span>
                    </div>)}
            </Rodal>)
    }

    render() {
        const { redirect, fetchingData, timetables, columns, actions } = this.state;
        const { resources } = this.props;

        if (redirect) { return <Redirect to={{ pathname: redirect, state: { refreshGrid: true } }} /> }
        if (fetchingData) return (<div className="offcentered-loader"><CircularProgress /></div>)

        return (
            <div>
                <div className="position-relative py-4">
                    <h3>{resources["PlanningTimetablesTitle"]}</h3>
                    <div className={"mt-4 " + (fetchingData ? 'fadeOut' : 'fadeIn')}>
                        <div style={{ float: "right", marginBottom: "10px" }}>
                            <Button component={Link} to="/timetables/new" color="primary" variant="contained" >
                                <i className="fas fa-plus mr-2" title={resources["AddTimetable"]} />
                                {resources["AddTimetable"]}
                            </Button>
                        </div>

                        <div style={{ clear: "both", }}></div>
                        <Paper elevation={3} className="p-3 mt-3">
                            <MuiThemeProvider theme={LocalizationTheme()}>
                                <MaterialTable
                                    icons={TableIcons}
                                    title={""}
                                    localization={{
                                        header: { actions: resources["Actions"] },
                                        body: {
                                            emptyDataSourceMessage: resources["NoRecordsToBeDisplayed"],
                                            deleteTooltip: resources["DeleteTooltip"],
                                            editTooltip: resources["EditTooltip"],
                                        },
                                        toolbar: {
                                            searchPlaceholder: resources["Search"],
                                            searchTooltip: resources["Search"]
                                        },
                                        pagination: {
                                            firstTooltip: resources["FirstTooltip"],
                                            lastTooltip: resources["LastTooltip"],

                                            previousTooltip: resources["PreviousTooltip"],
                                            nextTooltip: resources["NextTooltip"],

                                            //  labelDisplayedRows: resources["LabelDisplayedRows"],
                                            labelRowsPerPage: resources["LabelRowsPerPage"],
                                            labelRowsSelect: resources["LabelRowsSelect"],
                                        }
                                    }}
                                    data={timetables}
                                    columns={columns}
                                    actions={actions}
                                    options={{ emptyRowsWhenPaging: false, pageSize: 10, pageSizeOptions: [10, 15, 20] }}
                                />
                            </MuiThemeProvider>
                        </Paper>
                    </div>
                    {this.renderDeleteModal()}
                    {this.renderCopyModal()}
                </div>
            </div >
        )
    }
}
