import { createTheme } from "@material-ui/core";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#53B3EA',
            dark: '#4e9bc7',
            contrastText: "#FFF",
            light: '#c8e4f3',
        },
        secondary: {
            main: '#008265'
        }
    },
    typography: {
        root:{
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        },
        h3: {
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            fontSize: '1.75rem',
            marginBottom: '0.5rem',
            fontWeight: '500',
            lineHeight: '1.2',
        },
        h6 : {
            fontSize: '1.25rem',
            lineHeight: '1.6',
            fontWeight: '500'
        },
        body1:{
            width: '100%',
            textAlign: 'left',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        }
    },
    overrides: {
        // localization theme
        MuiIcon: {
            root: {
              fontSize: "1rem"
            }
          },
          MuiTable: {
            root: {
              width: "98%",
              margin: "auto"
            }
          },
          MuiTableCell: {
            head: {
              fontWeight: "600"
            }
          },
          MuiInput: {
            underline: {
              '&:after': {
                borderBottomColor: "#53B3EA !important",
              }
            },
          },
        
        //select theme
        MuiInputBase: {
          root: {
            backgroundColor: "white",
            "& .fontSizeOverride": {
              fontSize: "14px !important",
            },
          },
        },
        MuiFormGroup: {
          root: {
            paddingLeft: "0px"
          }
        },
        MuiFormLabel: {
          root: {
            "&$focused": {
              color: "#53B3EA"
            }
          },
        },
        MuiOutlinedInput: {
          root: {
            "&$focused": {
              notchedOutline: {
                borderColor: "#53B3EA !important",
              },
            },
          },
          multiline: {
            padding: '0 !important',
          },
          input: {
            padding: "9px 14px !important"
          },
        },
        MuiInputLabel: {
          outlined: {
            transform: "translate(14px, 11px) scale(1)"
          }
        },

        //dropzone
        MuiDropzoneArea:{
            icon:{
                color: "#53B3EA !important",
            }
        },
        MuiDropzonePreviewList:{
            root: {
              margin: '0px',
            },
            imageContainer: {
              minWidth: '250px',
              marginRight: '16px',
            },
            image:{
                color: "#53B3EA !important",
            },
            removeButton:{
              top: '25px',
              color: '#53B3EA'
            }
        },
        MuiFormHelperText:{
          root:{
            '&$error': {
              color: '#C4455B'
            }
          }
        }
      }
});