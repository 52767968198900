import React from 'react';
import { Route, Switch } from 'react-router';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import  BookingDateMarkersTable  from '../components/WorkshopPlanning/General/BookingDateMarkers/BookingDateMarkersTable';
import  BookingDateMarkersCreation  from '../components/WorkshopPlanning/General/BookingDateMarkers/BookingDateMarkersCreation';
import { Permissions } from '../utils/Permissions';

export default function BookingDateMarkersRouting(props) {
    const { currentIdentity, resources } = props;
    return (
        <>
            <RestrictedRoute path='/bookingdatemarkers' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.WorkshopSettings]} resources={resources} render={(props) => {
                return (<BookingDateMarkersTable resources={resources} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets}
                    culture={currentIdentity.culture}
                    refreshGrid={props && props.location && props.location.state && props.location.state.refreshGrid}
                    outletId={props && props.location && props.location.state && props.location.state.outletId}                    
                    year={props && props.location && props.location.state && props.location.state.year} />)
            }} />
            <Switch>
                <Route exact path='/bookingdatemarkers/new' render={(props) => (<BookingDateMarkersCreation outletId={props && props.location && props.location.state && props.location.state.outletId}                    
                    year={props && props.location && props.location.state && props.location.state.year} resources={resources}
                    accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} />)} />
            </Switch>
        </>
    )
}