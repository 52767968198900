import React from 'react';
import { Route, Switch } from 'react-router';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import LinkAgendasTable from '../components/WorkshopPlanning/Planning/LinkAgendas/LinkAgendasTable';
import LinkAgendaNew from '../components/WorkshopPlanning/Planning/LinkAgendas/LinkAgendaNew';
import { Permissions } from '../utils/Permissions';

export default function LinkedAgendasRouting(props) {
    const { currentIdentity, resources } = props;
    return (
        <>
            <RestrictedRoute path='/linkagendas' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.WorkshopSettings]} resources={resources} render={(props) => {
                return (<LinkAgendasTable resources={resources} refreshGrid={props && props.location && props.location.state && props.location.state.refreshGrid} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} linkAgendaWasCreated={props && props.location && props.location.state && props.location.state.linkAgendaWasCreated} />)
            }} />
            <Switch>
                <Route exact path='/linkagendas/new' render={(props) => (<LinkAgendaNew resources={resources} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets}  {...props} />)} />
                <Route exact path='/linkagendas/:outletId/:linkagendaId' render={(props) => {
                    return (<LinkAgendaNew resources={resources}
                        accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets}
                        outletId={props.match.params.outletId}
                        agendas={props && props.location && props.location.state && props.location.state.agendas}
                        outletUsers={props && props.location && props.location.state && props.location.state.outletUsers}
                        linkAgendasItems={props && props.location && props.location.state && props.location.state.linkAgendasItems}
                        linkagendaId={props.match.params.linkagendaId} {...props} />)
                }} />
            </Switch>
        </>
    )
}