import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { DisplayMessage, GetFormattedDate, ValidateDate } from '../../../../services/helpers.js';
import { TextField } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

import { SelectTheme } from '../../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";

export default class TeamMemberNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            teamId: 0,
            outletUserId: 0,
            memberName: undefined,
            memberFrom: "",
            memberTill: "",
            createdOn: undefined,
            createdByName: undefined,
            outletUsers: [],
            loading: true,
            redirect: false,
            requestInProgress: false
        };
    }

    componentDidMount() {
        this.getOutletUsers();
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };


    handleTyping = e => {
        this.setState({ requestInProgress: false });
    };
    saveTeamMember = () => {
        this.setState({ requestInProgress: true });

        if (this.state.memberFrom === "" || this.state.memberFrom === undefined || !ValidateDate(this.state.memberFrom)) {
            DisplayMessage(this.props.resources["InvalidStartDate"], "error");
            return;
        }

        if (this.state.memberFrom != "" && !ValidateDate(this.state.memberTill)) {
            DisplayMessage(this.props.resources["InvalidEndDate"], "error");
            return;
        }
        let memberFrom = GetFormattedDate(this.state.memberFrom);
        let memberTill = GetFormattedDate(this.state.memberTill == "" ? undefined : this.state.memberTill);

        if (memberTill !== null && (memberFrom > memberTill || memberTill.indexOf("NaN") > -1)) {
            DisplayMessage(this.props.resources["InvalidEndDate"], "error");
            return;
        }

        var json = JSON.stringify({
            teamId: this.state.teamId,
            outletUserId: this.state.outletUserId,
            memberFrom: memberFrom,
            memberTill: memberTill,
        });
        const url = "/api/mobopro/v1/planningteammembers";
        fetch(url, {
            method: 'POST',

            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        })
            .then(response => {
                if (!response.ok) {
                    if (response.statusText.length > 0) {
                        DisplayMessage(response.statusText, "error");
                    }
                    else {
                        for (var i = 0; i < response.errors.length; i++) {
                            DisplayMessage(response.errors[i], "error");
                        }
                    }
                }
                this.setState({ requestInProgress: false });
                return response.json();
            }).then(result => {
                if (result.id !== undefined) {
                    DisplayMessage("New Team Member was saved!", "success");
                }
                this.handleCancel();

            });
    }

    handleCancel = () => {
        this.props.closeDialogMembers();
    }

    getOutletUsers = () => {
        const url = "/api/mobopro/v1/outletusers";
        fetch(url,
            {
                method: 'GET',
                headers: new Headers
                    ({
                        'Content-Type': 'application/json',
                        'PublicKey': localStorage.getItem('publicKey'),
                        'Authorization-token': localStorage.getItem('authorizationToken'),
                    })
            })
            .then(response => response.json())
            .then(result => {
                const outletUsers = [];

                result.forEach(function (item) {

                    if (item.isDeleted === false && item.isActive === true) {
                        outletUsers.push({
                            id: item.id,
                            description: item.firstName + " " + item.lastName,
                        });
                    }
                });
                const outletUsersSorted = outletUsers.sort((a, b) => (a.description > b.description) ? 1 : -1)
                this.setState({
                    outletUsers: outletUsersSorted,
                    teamId: this.props.teamId,
                    memberFrom: this.state.memberFrom,
                    memberTill: this.state.memberTill,
                    loading: false
                });
            });
    }

    renderOutletUsers = () => {
        if (this.state.outletUsers === undefined) return;
        return this.state.outletUsers.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
    }

    renderForm() {

        let resources = this.props.resources;
        var helperTextMemberFrom = this.state.memberFrom === "" ? "helper-text-red" : "helper-text-grey";
        return (
            <Form  >
                <Form.Group style={{ display: "flex" }} onClick={this.handleTyping}>
                    <div className="col-2"></div>
                    <FormControl className="col-8 helper-text-red" variant="outlined" onChange={this.handleChange}>
                        <ThemeProvider theme={SelectTheme}>
                            <InputLabel htmlFor="team-members-outlet-user">{resources["OutletUser"]}</InputLabel>
                        </ThemeProvider>
                        <Select
                            native
                            label="OutletUsers"
                            value={this.state.outletUserId}
                            inputProps={{
                                name: 'outletUserId',
                                id: 'team-members-outlet-user',
                            }}>
                            <option value={0}>{resources["DefaultDropdownValue"]}</option>
                            {this.renderOutletUsers()}
                        </Select>
                        <FormHelperText className={this.state.outletUserId < 1 ? 'color-red' : 'color-grey'}>{resources["SelectionOfUserIsMandatory"]}</FormHelperText>
                    </FormControl>
                </Form.Group>

                <Form.Group style={{ display: "flex" }} onKeyDown={this.handleTyping} onClick={this.handleTyping}>
                    <Form.Label className="col-2 label-mt my-auto" > </Form.Label>
                    <TextField id="dateMemberFrom" className={"col-4 pr-1 white-date " + helperTextMemberFrom}
                        helperText={resources["PleasePickADate"]}

                        name="memberFrom" variant="outlined" label={resources["MemberFrom"]} type="date" required
                        value={this.state.memberFrom} InputLabelProps={{ shrink: true, required: false }} onChange={this.handleChange} />
                    <TextField id="dateMemberTill" className="col-4 pl-1 white-date" name="memberTill" variant="outlined" label={resources["MemberTill"]} type="date"
                        value={this.state.memberTill} InputLabelProps={{ shrink: true }} onChange={this.handleChange} />
                </Form.Group>
                <Form.Group style={{ display: "flex" }} >
                    <div className="col-2">
                    </div>
                    <div className="col-8 pr-0">
                        <div className="float-right mr-0">
                            <Button className="btn btn-primary pull-right margin my-2 mx-2" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }} title={resources["Cancel"]} type="button" onClick={this.handleCancel} >
                                {resources["Cancel"]}
                            </Button>
                            <Button className="btn btn-primary pull-right margin my-2 ml-2" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }} title={resources["Save"]} type="button" onClick={this.saveTeamMember} disabled={this.state.requestInProgress}>
                                {resources["Save"]}
                            </Button>
                        </div>
                    </div>
                </Form.Group>
            </Form>);
    }

    render() {
        let resources = this.props.resources;
        return (
            <div className={"py-4 " + (this.state.loading ? 'fadeOut' : 'fadeIn')}>
                <div className="mb-4  text-center">
                    <h5>{resources["AddNewTeamMemberTitle"]}</h5>
                </div>
                <div className="pt-2" style={{ textAlign: "center" }}>
                    {this.renderForm()}
                </div>

            </div>
        );
    }
}