import React from 'react';
import { Route, Switch } from 'react-router';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import TeamsTable from '../components/WorkshopPlanning/Planning/Teams/TeamsTable';
import TeamNew from '../components/WorkshopPlanning/Planning/Teams/TeamNew';
import { Permissions } from '../utils/Permissions';

export default function TeamsRouting(props) {
    const { currentIdentity, resources } = props;
    return (
        <>
            <RestrictedRoute path='/teams' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.WorkshopSettings]} resources={resources} render={(props) =>
                (<TeamsTable resources={resources} refreshGrid={props && props.location && props.location.state && props.location.state.refreshGrid} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} />)} />
            <Switch>
                <Route exact path='/teams/new' render={(props) => (<TeamNew resources={resources} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} />)} />
                <Route exact path='/teams/:teamId' render={(props) => {
                    return (<TeamNew resources={resources} teams={props && props.location && props.location.state && props.location.state.teams} teamId={props.match.params.teamId} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} />)
                }} />
            </Switch>
        </>
    )
}