import React from 'react';
import { Route, Switch } from 'react-router';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import OutletUsersTable from '../components/General/OutletUsers/OutletUsersTable';
import OutletUserCreation from '../components/General/OutletUsers/OutletUserCreation';
import OutletUserEditing from '../components/General/OutletUsers/OutletUserEditing';
import { Permissions } from '../utils/Permissions';

export default function OutletUsersRouting(props) {
    const { currentIdentity, resources } = props;
    return (
        <>
             <RestrictedRoute path='/outletusers' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.AccountManagement]} resources={resources} render={(props) => {
                return (<OutletUsersTable resources={resources} refreshGrid={props && props.location && props.location.state && props.location.state.refreshGrid} currentOutletUserId={currentIdentity.id} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} linkAgendaWasCreated={props && props.location && props.location.state && props.location.state.linkAgendaWasCreated} />)
            }} />
            <Switch>
                <Route exact path='/outletusers/new' render={(props) => (<OutletUserCreation resources={resources} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} outletId={props && props.location && props.location.state && props.location.state.outletId} />)} />
                <Route exact path='/outletusers/:outletUserId' render={(props) => {
                    return (<OutletUserEditing resources={resources} outletUserId={props.match.params.outletUserId} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} {...props} />)
                }} />
            </Switch>
        </>
    )
}