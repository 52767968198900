import React, { Component, forwardRef } from 'react';
import MaterialTable from 'material-table';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Loader from '../../../Loader.js';
import moment from 'moment';
import Rodal from 'rodal';
import { DisplayMessage, GetFormattedDateEuro, ValidateDate, CompareDates } from '../../../../services/helpers.js';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { LocalizationTheme } from '../../../../utils/utils.js';
import PopupTeamMemberNew from './PopupTeamMemberNew.js';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default class TeamMembersTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            teamMemberId: 0,
            teamMembers: props.teamMembers,
            dataGrid: [],
            fetchingData: true,
            showDelete: false,
            deletedMemberId: 0,
            editedMemberId: 0,
            showEditMember: false,
            teamId: 0
        };
    }

    componentWillMount() {
        this.getData();
    }
    handleEdit = (itemId) => {
        this.setState({ showEditMember: true, editedMemberId: itemId });
    }
    getData = () => {
        this.setState({ fetchingData: true });
        let initialData = {};
        let resources = this.props.resources;
        initialData.columns = [

            { title: resources['Username'], field: 'memberName', editable: 'never', removable: false },
            {
                title: resources['MemberFrom'], field: 'memberFrom', options: { filter: true, sorting: true },
                customSort: (a, b) => {
                    var a1 = moment(a.memberFrom, "DD/MM/YYYY");
                    var b1 = moment(b.memberFrom, "DD/MM/YYYY");
                    if (a1.isAfter(b1))
                        return 1;
                    else if (a1.isBefore(b1))
                        return -1;
                    else
                        return 0;
                }
            },
            {
                title: resources['MemberTill'], field: 'memberTill', options: { filter: true, sort: false }
            },
            {
                title: resources['CreatedOn'], field: 'createdOn', editable: 'never', options: { filter: true, sorting: true },
                customSort: (a, b) => {
                    var a1 = moment(a.createdOn, "DD/MM/YYYY");
                    var b1 = moment(b.createdOn, "DD/MM/YYYY");
                    if (a1.isAfter(b1))
                        return 1;
                    else if (a1.isBefore(b1))
                        return -1;
                    else
                        return 0;
                }
            },

            { title: resources['CreatedByName'], field: 'createdByName', editable: "never", options: { filter: true, sort: true } },

        ];
        const currentRows = [];
        if (this.state.teamMembers !== undefined) {
            this.state.teamMembers.forEach(function (item) {

                if (this === undefined || this.state.deletedItem !== item.id) {
                    currentRows.push({
                        id: item.id,
                        memberName: item.memberName,
                        memberFrom: moment(item.memberFrom).format('DD/MM/YYYY'),
                        memberTill: item.memberTill != null ? moment(item.memberTill).format('DD/MM/YYYY') : "",
                        createdOn: moment(item.createdOn).format('DD/MM/YYYY'),
                        createdByName: item.createdByName,
                    });
                }
            });
        }
        initialData.data = currentRows;

        this.setState({
            dataGrid: initialData,
            fetchingData: false,
            show: false,
            deletedItem: 0,
            teamId: this.props.teamId
        });
    }

    handleCloseDelete = () => {
        this.setState({ showDelete: false, deletedMemberId: 0 });
    }

    handleShowDeletePopup = (itemId) => {
        this.setState({ showDelete: true, deletedMemberId: itemId });
    }

    deleteTeamMember = (data) => {
        const {resources} = this.props;

        if (data.id > 0) {
            const url = "/api/mobopro/v1/planningteammembers/" + data.id;
            fetch(url, {
                method: 'DELETE',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
                .then(response => {
                    if (!response.ok) {
                        if (response.statusText.length > 0) {
                            DisplayMessage(response.statusText, "error");
                            return response.json();
                        }

                        for (var i = 0; i < response.errors.length; i++) {
                            DisplayMessage(response.errors[i], "error");
                        }
                    }
                }).then(result => {
                    this.getTeamByIdForReloadGrid(this.state.teamId);
                    DisplayMessage(resources["TeamMemberWasDeleted"], "success");
                });
        }
    }

    saveEditedTeamMember = (data) => {
        if (data.id <= 0) return;

        if (!ValidateDate(data.memberFrom)) {
            DisplayMessage(this.props.resources["InvalidStartDate"], "error");
            return;
        }

        if (!ValidateDate(data.memberTill)) {
            DisplayMessage(this.props.resources["InvalidEndDate"], "error");
            return;
        }
        const {resources} = this.props;

        let memberFrom = GetFormattedDateEuro(data.memberFrom);
        let memberTill = null;

        if (data.memberTill !== "") {
            memberTill = GetFormattedDateEuro(data.memberTill);
            if (memberFrom > memberTill || memberTill.indexOf("NaN") > -1) {
                DisplayMessage(this.props.resources["InvalidEndDate"], "error");
                return;
            }
        }

        var request = {
            id: data.id,
            memberFrom: memberFrom,
            memberTill: memberTill
        }

        const url = "/api/mobopro/v1/planningteammembers/" + data.id;
        fetch(url, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: JSON.stringify(request),
        }).then(response => {
            response.json().then(result => {

                if (!response.ok) {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                }
                else {
                    if (result.id !== undefined) {
                        DisplayMessage(resources["TeamMembersWasUpdated"], "success");
                        this.getTeamByIdForReloadGrid(this.state.teamId);
                    }
                }
            })
        });

    }

    setShowEditMember = (value) => {
        this.setState({ showEditMember: value }, () => {
            this.props.getTeamById(this.props.teamId);
        });

    }

    getTeamByIdForReloadGrid = (teamId) => {
        const url = "/api/mobopro/v1/planningteams/" + teamId;
        fetch(url, {
            method: 'GET',

            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
        })
            .then(response => response.json()).then(result => {
                if (result.id !== undefined) {
                    this.setState({
                        teamMembers: result.members
                    }, () => { this.getData() });

                }
            });
    }

    render() {
        let resources = this.props.resources;
        return (
            <div>
                <div className="position-relative pt-4 pb-1">
                    <div className={(this.state.fetchingData ? 'fadeOut' : 'fadeIn')}>
                        <div style={{ float: "right" }}>
                            <PopupTeamMemberNew accessibleMoboOutlets={this.props.accessibleMoboOutlets}
                                showEditMember={this.state.showEditMember}
                                teamId={this.state.teamId}
                                memberFrom={this.state.memberFrom}
                                memberTill={this.state.memberTill}
                                setShowEditMember={this.setShowEditMember}
                                resources={resources} />

                        </div>
                        <div style={{ clear: "both" }}></div>
                        <MuiThemeProvider theme={LocalizationTheme()}>
                            <MaterialTable
                                icons={tableIcons}
                                title={resources["PlanningTeamMembers"]}

                                localization={{
                                    header: { actions: resources["Actions"] },
                                    body: {
                                        emptyDataSourceMessage: resources["NoRecordsToBeDisplayed"],
                                        deleteTooltip: resources["DeleteTooltip"],
                                        editTooltip: resources["EditTooltip"],
                                        editRow: {
                                            saveTooltip: resources["SaveTooltip"],
                                            cancelTooltip: resources["CancelTooltip"],
                                            deleteText: resources["DeleteText"]
                                        }
                                    },
                                    toolbar: {
                                        searchPlaceholder: resources["Search"],
                                        searchTooltip: resources["Search"]
                                    },
                                    pagination: {
                                        firstTooltip: resources["FirstTooltip"],
                                        lastTooltip: resources["LastTooltip"],

                                        previousTooltip: resources["PreviousTooltip"],
                                        nextTooltip: resources["NextTooltip"],

                                        labelRowsPerPage: resources["LabelRowsPerPage"],
                                        labelRowsSelect: resources["LabelRowsSelect"],
                                    }
                                }}
                                data={this.state.dataGrid.data}
                                columns={this.state.dataGrid.columns}
                                options={{ emptyRowsWhenPaging: false, pageSize: 10, pageSizeOptions: [10, 15, 20] }}
                                editable={{
                                    onRowUpdate: (newData) =>
                                        new Promise((resolve) => {
                                            setTimeout(() => {
                                                this.saveEditedTeamMember(newData);
                                                resolve();
                                            }, 1000);

                                        }),

                                    onRowDelete: oldData =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                this.deleteTeamMember(oldData);
                                                resolve();
                                            }, 1000)
                                        }),
                                }}

                            />
                        </MuiThemeProvider>
                    </div>
                    <div className={"loader-overlap position-absolute " + (this.state.fetchingData ? 'fadeIn' : 'fadeOut')}><div className="container"><Loader id="loader-teammembers" /></div></div>
                    <Rodal visible={this.state.showDelete} onClose={this.handleCloseDelete} className="rodal" customStyles={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                    }}>
                        <div className="modal-body" style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>{resources["AreYouSureYouWantToDeleteMember"]}</div>
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <button className="btn btn-secondary" onClick={this.handleCloseDelete}>{resources["Cancel"]}</button>
                            <button className="btn btn-primary" onClick={this.deleteTeamMember}>{resources["Yes"]}</button>
                        </div>
                        <span className="rodal-close"></span>
                    </Rodal>
                </div>
            </div >
        )
    };
}