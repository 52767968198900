import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import "toastr/build/toastr.min.css";
import { DisplayMessage } from "../../../../services/helpers.js";
import DelayedRedirect from "../../../../utils/DelayedRedirect";
import { Transition } from "../../../../utils/ModalHelpers";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import "toastr/build/toastr.min.css";
import Select from "@material-ui/core/Select";

import { SelectTheme } from "../../../../utils/utils.js";
import { ThemeProvider } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { PictureAsPdf } from "@material-ui/icons";

const snippetTypes = Object.freeze({
  SnippetMediaFileNames: 5,
  SnippetPDFLibrary: 6,
});

const SnippetCreation = (props) => {
  const { resources, language } = props;
  const [state, setState] = useState({
    id: 0,
    type: 0,
    language: language,
    title: "",
    content: "",
    isDefaultLocked: false,
    requestInProgress: true,
    redirect: null,
    open: true,
    snippetWasCreated: false,
    file: undefined,
  });

  useEffect(() => {
    checkIfRequestIsValid(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.content,
    state.title,
    state.type,
    state.file,
    state.isDefaultLocked,
  ]);

  const checkIfRequestIsValid = (displayErrorMessage) => {
    var isValidRequest = true;

    if (state.title.trim().length <= 0) {
      if (displayErrorMessage) {
        DisplayMessage(resources["InvalidTitle"], "error");
      }
      isValidRequest = false;
    }

    if (
      state.content.trim().length <= 0 &&
      state.type !== snippetTypes.SnippetMediaFileNames &&
      state.type !== snippetTypes.SnippetPDFLibrary
    ) {
      if (displayErrorMessage) {
        DisplayMessage(resources["InvalidSnippet"], "error");
      }
      isValidRequest = false;
    }

    if (state.type === 0) {
      if (displayErrorMessage) {
        DisplayMessage(resources["InvalidType"], "error");
      }
      isValidRequest = false;
    }

    setState((prevState) => ({
      ...prevState,
      requestInProgress: !isValidRequest,
    }));
    return isValidRequest;
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      open: false,
      redirect: "/snippets",
    }));
  };

  const handleChange = (e) => {
    if (e.target.name === "isDefaultLocked") {
      setState((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.checked,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleChangeType = (e) => {
    setState((prevState) => ({
      ...prevState,
      type: parseInt(e.target.value),
      isDefaultLocked: false,
      file: undefined,
      base64file: "",
    }));
  };

  //dropzone
  const handleFileChange = (files) => {
    var firstfile = files.length > 0 ? files[0] : undefined;
    setState((prevState) => ({ ...prevState, file: firstfile }));
  };

  const fileToBase64 = async (file) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file);
      reader.onload = function () {
        var base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = () => reject("");
    });
  };

  const saveNewSnippet = async () => {
    setState((prevState) => ({ ...prevState, requestInProgress: true }));

    if (!checkIfRequestIsValid(true)) return;

    var newContent = state.content;
    switch (state.type) {
      case snippetTypes.SnippetMediaFileNames:
        newContent = null;
        break;
      case snippetTypes.SnippetPDFLibrary:
        newContent = null;
        if (state.file === undefined) {
          DisplayMessage(resources["PleaseSelectAPdfFile"], "error");
          return;
        }
        break;
      default:
        break;
    }

    const base64file =
      state.file !== undefined ? await fileToBase64(state.file) : null;

    var json = JSON.stringify({
      type: parseInt(state.type),
      language: state.language,
      title: state.title,
      content: newContent,
      isDefaultLocked: state.isDefaultLocked,
      file: base64file,
    });
    fetch("/api/mobopro/v1/snippets", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        PublicKey: localStorage.getItem("publicKey"),
        "Authorization-token": localStorage.getItem("authorizationToken"),
      }),
      body: json,
    }).then((response) => {
      response.json().then(() => {
        if (!response.ok) {
          response.json().then((result) => {
            for (var i = 0; i < result.errors.length; i++) {
              DisplayMessage(result.errors[i], "error");
            }
          });
        } else {
          DisplayMessage(resources["NewSnippetWasSaved"], "success");
          setState((prevState) => ({
            ...prevState,
            snippetWasCreated: true,
            redirect: "/snippets",
          }));
        }
      });
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    await saveNewSnippet();
  };

  const renderTypesDropdown = () => {
    var snippetsTypes = [
      { name: resources["SnippetServiceQuote"], value: 1 },
      { name: resources["SnippetExternalDialog"], value: 2 },
      { name: resources["SnippetExternalDossierChat"], value: 3 },
      { name: resources["SnippetInternalDossierChat"], value: 4 },
      { name: resources["SnippetMediaFileNames"], value: 5 },
      { name: resources["SnippetPDFLibrary"], value: 6 },
    ].sort((a, b) => (a.name > b.name ? 1 : -1));

    snippetsTypes.unshift({
      name: resources["DefaultDropdownValue"],
      value: 0,
    });

    snippetsTypes = snippetsTypes.map((x) => (
      <option key={x.value} value={x.value}>
        {" "}
        {x.name}
      </option>
    ));
    return snippetsTypes;
  };

  const handlePreviewIcon = (fileObject, classes) => {
    const iconProps = {
      className: classes.image,
    };
    return <PictureAsPdf {...iconProps} />;
  };

  const renderForm = () => {
    const { language, title, content, type, isDefaultLocked, file } = state;

    var helperTextTitle =
      title.trim().length <= 0 ? "helper-text-red" : "helper-text-grey";
    var helperTextContent =
      content.trim().length <= 0 ? "helper-text-red" : "helper-text-grey";
    var displayPdf = file !== undefined ? "dropzone-hidden" : "dropzone";

    return (
      <div>
        <Paper elevation={3} className="p-4 mt-3 mb-3">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="d-flex">
              <div className="col-3"></div>
              <FormControl
                className="col-6 helper-text-red"
                variant="outlined"
                onChange={handleChangeType}
              >
                <ThemeProvider theme={SelectTheme}>
                  <InputLabel htmlFor="outlined-type-native-simple">
                    {resources["Type"]}
                  </InputLabel>
                </ThemeProvider>
                <Select
                  id="snippets-type"
                  native
                  label={resources["Type"]}
                  value={type}
                  inputProps={{
                    name: "type",
                    id: "snippets-type",
                  }}
                >
                  {renderTypesDropdown()}
                </Select>
                <FormHelperText
                  className={type < 1 ? "color-red" : "color-grey"}
                >
                  {resources["SelectionOfTypeIsMandatory"]}
                </FormHelperText>
              </FormControl>
              <div className="col-3"></div>
            </Form.Group>
            <Form.Group className="d-flex">
              <div className="col-3"></div>
              <FormControl
                className="col-6 helper-text-red"
                variant="outlined"
                onChange={handleChange}
              >
                <ThemeProvider theme={SelectTheme}>
                  <InputLabel htmlFor="outlined-type-native-simple">
                    {resources["Language"]}
                  </InputLabel>
                </ThemeProvider>
                <Select
                  id="snippets-language"
                  native
                  label={resources["Language"]}
                  value={language}
                  inputProps={{
                    name: "language",
                    id: "snippets-language",
                  }}
                >
                  <option value={"nl"} key={"nl"}>
                    {"Nederlands"}
                  </option>
                  <option value={"fr"} key={"fr"}>
                    {"Français"}
                  </option>
                  <option value={"en"} key={"en"}>
                    {"English"}
                  </option>
                  <option value={"de"} key={"de"}>
                    {"Deutsch"}
                  </option>
                  <option value={"cs"} key={"cs"}>
                    {"Czech"}
                  </option>
                </Select>
              </FormControl>
              <div className="col-3"></div>
            </Form.Group>
            <Form.Group className="d-flex">
              <div className="col-3"></div>
              <ThemeProvider theme={SelectTheme}>
                <TextField
                  className={"col-6 " + helperTextTitle}
                  id="snippet-title"
                  value={title}
                  label={resources["Title"]}
                  helperText={resources["InputOfTitleIsMandatory"]}
                  variant="outlined"
                  name="title"
                  onChange={handleChange}
                  inputProps={{ maxLength: 100 }}
                />
              </ThemeProvider>
            </Form.Group>
            <Form.Group className="d-flex">
              <div className="col-3"></div>
              <ThemeProvider theme={SelectTheme}>
                <TextField
                  className={"col-6 " + helperTextContent}
                  id="snippet-contnet"
                  value={content}
                  multiline
                  minRows={4}
                  label={resources["Snippet"]}
                  helperText={
                    type === snippetTypes.SnippetMediaFileNames ||
                    type === snippetTypes.SnippetPDFLibrary
                      ? ""
                      : resources["InputOfSnippetIsMandatory"]
                  }
                  autoComplete="off"
                  variant="outlined"
                  name="content"
                  onChange={handleChange}
                  disabled={
                    type === snippetTypes.SnippetMediaFileNames ||
                    type === snippetTypes.SnippetPDFLibrary
                  }
                />
              </ThemeProvider>
            </Form.Group>
            <Form.Group
              className={
                type !== snippetTypes.SnippetMediaFileNames &&
                type !== snippetTypes.SnippetPDFLibrary
                  ? "d-none"
                  : "d-flex"
              }
            >
              <div className="col-3 "> </div>
              <ThemeProvider theme={SelectTheme}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="snippet-isDefaultLocked"
                      checked={isDefaultLocked}
                      onChange={handleChange}
                      name="isDefaultLocked"
                      color="primary"
                    />
                  }
                  label={resources["IsDefaultLocked"]}
                />
              </ThemeProvider>
            </Form.Group>
            <Form.Group
              className={
                type === snippetTypes.SnippetPDFLibrary ? "d-flex" : "d-none"
              }
            >
              <div className="col-3"></div>
              <ThemeProvider theme={SelectTheme}>
                <DropzoneArea
                  className="col-4"
                  acceptedFiles={[".pdf"]}
                  filesLimit={1}
                  showAlerts={false}
                  onAlert={(message, variant) =>
                    variant === "error"
                      ? DisplayMessage(message, variant)
                      : undefined
                  }
                  showPreviews={true}
                  previewText=""
                  getPreviewIcon={handlePreviewIcon}
                  dropzoneClass={displayPdf}
                  dropzoneParagraphClass={"dropzone-p"}
                  maxFileSize={30000000}
                  dropzoneText={resources["PDFDocument"]}
                  onChange={handleFileChange}
                />
              </ThemeProvider>
            </Form.Group>
          </Form>
        </Paper>
      </div>
    );
  };

  const { redirect, open, snippetWasCreated, outletId, requestInProgress } =
    state;
  return (
    <div>
      {redirect && (
        <DelayedRedirect
          to={{
            pathname: redirect,
            state: { refreshGrid: snippetWasCreated, outletId },
          }}
          delay={250}
        />
      )}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className="position-sticky ">
          <Toolbar>
            <IconButton
              id="close-add-Snippet"
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" style={{ flex: 1 }}>
              {resources["AddNewSnippetTitle"]}
            </Typography>
            <Button
              id="Snippet-save"
              color="inherit"
              onClick={saveNewSnippet}
            >
              {resources["Save"]}
            </Button>
          </Toolbar>
        </AppBar>
        <div className="container">
          <div className={"py-4 grey fadeIn"} style={{ textAlign: "center" }}>
            {renderForm()}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default SnippetCreation;
