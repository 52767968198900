import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import 'toastr/build/toastr.min.css';
import { DisplayMessage, ExcludeSpecialCharactersFromInputTypeNumber } from '../../../services/helpers.js';
import DelayedRedirect from '../../../utils/DelayedRedirect';
import { Transition } from '../../../utils/ModalHelpers';
import { SelectTheme } from '../../../utils/utils.js';

import { ThemeProvider } from "@material-ui/core";
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import CheckIcon from '@material-ui/icons/Check';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import DeleteIcon from '@material-ui/icons/Delete';

import moment from 'moment';
import 'toastr/build/toastr.min.css';

export default class TaskWorkflowCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taskWorkflowId: this.props.taskWorkflowId != undefined ? this.props.taskWorkflowId : 0,

      loading: false,
      redirect: null,
      open: true,
      TaskWorkflowWasCreated: false,
      showAddLanguage: false,
      showAddCondition: false,

      description: '',
      outletId: this.props.outletId,
      teamId: 0,
      isActive: true,
      hasOneTask: false,
      expiryPeriod: '',

      triggeredByOtherWorkflow: false,

      triggers: [],
      triggerCode: 0,
      oldValue: null,
      newValue: null,

      taskTexts: [],
      language: null,
      languageDescription: '',
      hasUsedGroupName: false,

      conditions: [],
      additionalConditions: [],
      conditionCode: null,
      conditionValue: ' ',

      outletUsers: [],
      sendTo: 99,
      sendToOutletUser: 0,
      userGroups: [],
      sendToUserGroup: 0,

      workflows: [],
      workflowId: 0,

      modifiedOn: "",
      modifiedByName: "",
      modifiedByAvatar: "",

      textToBeAdded: [],
      conditionToBeAdded: [],

      textToBeDeleted: [],
      conditionToBeDeleted: [],

      triggerValueLessThan100: false,
    };
  }

  componentDidMount() {
    if (this.state.taskWorkflowId != 0)
      this.getEventTaskWorkflow();
    this.getTriggersAndConditions();
    this.getOutletUsers();
    this.getUserGroups();
    this.getWorkflows();
  }

  getEventTaskWorkflow() {
    const { taskWorkflowId } = this.state;

    fetch(`/api/mobopro/v1/eventtasks/workflows/${taskWorkflowId}`,
      {
        method: 'get',
        headers: new Headers({
          'PublicKey': localStorage.getItem('publicKey'),
          'Authorization-token': localStorage.getItem('authorizationToken'),
        }),
      })
      .then(response => {
        if (!response.ok) { throw new Error(); }
        return response.json();
      }).then(result => {
        const additionalConditions = [];

        result.conditions.forEach(function (condition) {
          additionalConditions.push({
            id: condition.id,
            code: condition.code,
            codeDescription: condition.codeDescription,
            applicationValue: condition.actualValue,
            applicationValueDescription: condition.actualValueDescription
          });
        });

        const taskTexts = [];
        var usedGroupName = false;
        result.texts.forEach(function (text) {
          if (text.text.includes("#GroupName#"))
            usedGroupName = true;
          taskTexts.push({
            id: text.id,
            language: text.language,
            languageDescription: text.text,
          });
        });

        this.setState({
          description: result.description,

          outletId: result.outletId != null ? result.outletId : 0,
          teamId: result.teamId != null ? parseInt(result.teamId) : 0,

          isActive: result.isActive,
          hasOneTask: result.hasOneTask,
          expiryPeriod: result.expiryPeriod,

          triggeredByOtherWorkflow: result.trigger.code != null ? false : true,

          triggerCode: result.trigger.code != null ? result.trigger.code : 0,
          triggerValueLessThan100: result.trigger.code != null && result.trigger.code < "100",
          oldValue: result.trigger.oldValue,
          newValue: result.trigger.newValue,

          taskTexts: taskTexts,
          additionalConditions: additionalConditions,

          hasUsedGroupName: usedGroupName,
          sendTo: result.recipient.type,
          sendToOutletUser: result.recipient.type == 20 ? result.recipient.id : 0,
          sendToUserGroup: result.recipient.type == 30 ? result.recipient.id : 0,

          workflowId: result.nextWorkflowId != null ? result.nextWorkflowId : 0,

          modifiedOn: result.modifiedOn,
          modifiedByName: result.modifiedByName,
          modifiedByAvatar: result.modifiedByAvatar,
        });
      });
  }

  getTriggersAndConditions() {
    fetch(`/api/mobopro/v1/eventtasks/workflows/triggersandconditions`,
      {
        method: 'get',
        headers: new Headers({
          'PublicKey': localStorage.getItem('publicKey'),
          'Authorization-token': localStorage.getItem('authorizationToken'),
        }),
      })
      .then(response => {
        if (!response.ok) { throw new Error(); }
        return response.json();
      }).then(result => {
        const triggersData = [];

        result.triggers.forEach(function (rowData) {
          const selectionValuesData = [];

          rowData.sectionValues.forEach(function (selectionValue) {
            selectionValuesData.push({
              applicationValue: selectionValue.applicationValue,
              applicationValueDescription: selectionValue.applicationValueDescription
            });
          });

          triggersData.push({
            code: rowData.code,
            codeDescription: rowData.codeDescription,
            selectionValues: selectionValuesData
          });
        });

        const conditionsData = [];
        result.conditions.forEach(function (rowData) {
          const conditionSelectionValuesData = [];

          rowData.sectionValues.forEach(function (selectionValue) {
            conditionSelectionValuesData.push({
              applicationValue: selectionValue.applicationValue,
              applicationValueDescription: selectionValue.applicationValueDescription
            });
          });

          conditionsData.push({
            code: rowData.code,
            codeDescription: rowData.codeDescription,
            selectionValues: conditionSelectionValuesData
          });
        });

        this.setState({
          triggers: triggersData,
          conditions: conditionsData
        });
      });
  }

  getOutletUsers() {
    fetch(`/api/mobopro/v1/outletusers?includedeleted=false`,
      {
        method: 'get',
        headers: new Headers({
          'PublicKey': localStorage.getItem('publicKey'),
          'Authorization-token': localStorage.getItem('authorizationToken'),
        }),
      })
      .then(response => {
        if (!response.ok) { throw new Error(); }
        return response.json();
      }).then(result => {
        const outletUsers = [];

        result.forEach(function (rowData) {
          outletUsers.push({
            id: rowData.id,
            name: rowData.firstName + " " + rowData.lastName
          });
        });

        this.setState({
          outletUsers: outletUsers
        });
      });
  }

  getUserGroups() {
    fetch(`/api/mobopro/v1/internalchatgroups?includenonactive=false`,
      {
        method: 'get',
        headers: new Headers({
          'PublicKey': localStorage.getItem('publicKey'),
          'Authorization-token': localStorage.getItem('authorizationToken'),
        }),
      })
      .then(response => {
        if (!response.ok) { throw new Error(); }
        return response.json();
      }).then(result => {
        const userGroups = [];

        result.forEach(function (rowData) {
          userGroups.push({
            id: rowData.id,
            description: rowData.description
          });
        });

        this.setState({
          userGroups: userGroups
        });
      });
  }

  getWorkflows() {
    fetch(`/api/mobopro/v1/eventtasks/workflows`,
      {
        method: 'get',
        headers: new Headers({
          'PublicKey': localStorage.getItem('publicKey'),
          'Authorization-token': localStorage.getItem('authorizationToken'),
        }),
      })
      .then(response => {
        if (!response.ok) { throw new Error(); }
        return response.json();
      }).then(result => {
        const workflows = [];

        result.forEach(function (rowData) {
          workflows.push({
            id: rowData.id,
            description: rowData.description,
            isTriggeredByOtherWorkflow: rowData.isTriggeredByOtherWorkflow,

          });
        });

        this.setState({
          workflows: workflows
        });
      });
  }

  handleClose = () => {
    this.setState({ open: false, redirect: "/taskworkflows" });
  };

  handleChange = e => {
    if (e.target.name === "description") {
      if (!(e.target.value.length > 0 && e.target.value.replace(/\s/g, '').length === 0))
        this.setState({ [e.target.name]: e.target.value });
      return;
    }

    if (e.target.name === "languageDescription") {
      if (!(e.target.value.length > 0 && e.target.value.replace(/\s/g, '').length === 0))
        this.setState({ [e.target.name]: e.target.value });
      return;
    }

    if (e.target.name === "expiryPeriod") {
      if (e.target.value === '' || (parseInt(e.target.value) >= 0 && parseInt(e.target.value) < 100))
        this.setState({ [e.target.name]: parseInt(e.target.value) });
      return;
    }

    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeOutlet = e => {
    if (e.target.value == 0) this.setState({ teamId: 0 });
    this.setState({ outletId: e.target.value });
  };

  handleChangeTeam = e => {
    this.setState({ teamId: e.target.value });
  };

  handleChangeCheckBox = e => {
    if (e.target.name == "triggeredByOtherWorkflow" && e.target.checked) {
      this.setState({ triggerCode: 0, oldValue: null, newValue: null });
    }
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleChangeTrigger = e => {
    this.setState({ triggerCode: e.target.value, triggerValueLessThan100: e.target.value < "100", additionalConditions: [] });
  };

  handleChangeOldValue = e => {
    let value = e.target.value;
    this.setState({ oldValue: value === 'null' ? null : value });
  };

  handleChangeNewValue = e => {
    let value = e.target.value;
    this.setState({ newValue: value === 'null' ? null : value });
  };

  handleChangeWorkflow = e => {
    this.setState({ workflowId: e.target.value });
  };

  toggleAddLanguage = () => {
    const { taskTexts } = this.state;

    let selectedLanguage = this.props.language;
    if (taskTexts.find(x => x.language === selectedLanguage) !== undefined) {
      selectedLanguage = taskTexts.find(x => x.language === 'cs') === undefined ? 'cs' : selectedLanguage;
      selectedLanguage = taskTexts.find(x => x.language === 'fr') === undefined ? 'fr' : selectedLanguage;
      selectedLanguage = taskTexts.find(x => x.language === 'de') === undefined ? 'de' : selectedLanguage;
      selectedLanguage = taskTexts.find(x => x.language === 'nl') === undefined ? 'nl' : selectedLanguage;
      selectedLanguage = taskTexts.find(x => x.language === 'en') === undefined ? 'en' : selectedLanguage;
    }
    this.setState({ showAddLanguage: true, language: selectedLanguage });
  }

  saveLanguage() {
    const { taskWorkflowId, language, languageDescription, taskTexts, hasUsedGroupName, textToBeAdded } = this.state;
    if (language === '' || languageDescription === '' || languageDescription === null || languageDescription === undefined) {
      return;
    }

    if (!hasUsedGroupName && languageDescription.includes("#GroupName#"))
      this.setState({ hasUsedGroupName: true });

    if (taskWorkflowId !== 0) {
      var id = Math.floor(Math.random() * 10000);
      taskTexts.push({ id: id, language, languageDescription });
      textToBeAdded.push({ id: id, language: language, text: languageDescription });
      this.setState({ showAddLanguage: false, language: null, languageDescription: null });

    }
    else {
      taskTexts.push({ id: Math.floor(Math.random() * 10000), language, languageDescription });
      this.setState({ taskTexts, showAddLanguage: false, language: null, languageDescription: null });
    }
  }

  closeAddLanguage() {
    this.setState({ showAddLanguage: false, language: null, languageDescription: null });
  }

  removeLanguage = (taskTextId) => {
    const { taskWorkflowId, taskTexts, hasUsedGroupName, textToBeAdded, textToBeDeleted } = this.state;

    const index = taskTexts.findIndex((i) => {
      return i.id === taskTextId;
    });

    if (taskWorkflowId !== 0) {

      var text = textToBeAdded.find(x => x.id === taskTextId);
      if (text === undefined || text === null) {
        textToBeDeleted.push(taskTextId);
      }
      else {
        const indexTextToBeAdded = textToBeAdded.findIndex((i) => {
          return i.id === taskTextId;
        });

        textToBeAdded.splice(indexTextToBeAdded, 1);
        this.setState({ textToBeAdded })
      }
    }

    taskTexts.splice(index, 1);

    var hasGroupNameTextIndex = taskTexts.findIndex((i) => {
      return i.languageDescription.includes("#GroupName#");
    });

    if (hasUsedGroupName && hasGroupNameTextIndex === -1) {
      this.setState({ hasUsedGroupName: false });
    }

    this.setState({ taskTexts });
  }

  toggleAddCondition() {
    var toggleAddCondition = this.state.showAddCondition;
    this.setState({ showAddCondition: !toggleAddCondition, conditionCode: 0 });
  }

  saveCondition() {
    const { taskWorkflowId, conditions, conditionCode, conditionValue, additionalConditions, conditionToBeAdded } = this.state;

    if (conditionCode === '0' || conditionValue === ' ') {
      return;
    }

    const conditionIndex = conditions.findIndex((i) => {
      return i.code === parseInt(conditionCode);
    })
    var condition = conditions[conditionIndex];

    const valueIndex = condition.selectionValues.findIndex((i) => {
      return i.applicationValue === conditionValue;
    })
    var selectionValue = condition.selectionValues[valueIndex];

    if (taskWorkflowId != 0) {
      var id = Math.floor(Math.random() * 10000);

      additionalConditions.push({
        id: id,
        code: condition.code,
        codeDescription: condition.codeDescription,
        applicationValue: selectionValue.applicationValue,
        applicationValueDescription: selectionValue.applicationValueDescription
      });
      conditionToBeAdded.push({ id: id, code: conditionCode, actualValue: conditionValue })
      this.setState({ showAddCondition: false })
    }
    else {
      additionalConditions.push({
        id: Math.floor(Math.random() * 10000),
        code: condition.code,
        codeDescription: condition.codeDescription,
        applicationValue: selectionValue.applicationValue,
        applicationValueDescription: selectionValue.applicationValueDescription
      });

      if (conditionCode != null && conditionValue != null) {
        this.setState({ additionalConditions, showAddCondition: false, conditionCode: null, conditionValue: null });
      }
    }
  }

  closeAddCondition() {
    this.setState({ showAddCondition: false, conditionCode: null, conditionValue: null });
  }

  removeCondition = (conditionId) => {
    const { taskWorkflowId, additionalConditions, conditionToBeDeleted, conditionToBeAdded } = this.state;

    const index = additionalConditions.findIndex((i) => {
      return i.id === conditionId;
    })

    if (taskWorkflowId !== 0) {

      var condition = conditionToBeAdded.find(x => x.id === conditionId);
      if (condition === undefined || condition === null) {
        conditionToBeDeleted.push(conditionId);
      }
      else {
        const indexConditionToBeAdded = conditionToBeAdded.findIndex((i) => {
          return i.id === conditionId;
        })
        conditionToBeAdded.splice(indexConditionToBeAdded, 1);
        this.setState({ conditionToBeAdded: conditionToBeAdded });

      }
    }

    additionalConditions.splice(index, 1);

    this.setState({ additionalConditions });
  }

  handleSendTo(sendTo) {
    if (sendTo !== undefined) {
      this.setState({ sendTo: parseInt(sendTo) });
      switch (sendTo) {
        case 20:
          this.setState({ sendToUserGroup: 0 });
          break;
        case 30:
          this.setState({ sendToOutletUser: 0 });
          break;
        default: this.setState({ sendToOutletUser: 0, sendToUserGroup: 0 });
          break;
      }
    }
  }

  handleSubmit = () => {
    const { taskWorkflowId } = this.state;
    if (taskWorkflowId === 0) {
      this.saveNewTaskWorkflow();
    }
    else {
      this.saveEditedTaskWorkflow();
    }
  }

  saveNewTaskWorkflow = () => {
    this.setState({ loading: true });

    const { resources } = this.props;
    const { outletId, description, teamId, sendTo, sendToOutletUser, sendToUserGroup, isActive, hasOneTask, workflowId, triggerCode, oldValue, newValue, expiryPeriod, taskTexts, additionalConditions, textToBeAdded } = this.state;

    var recipientId = null;
    switch (sendTo) {
      case 20: recipientId = parseInt(sendToOutletUser);
        break;
      case 30: recipientId = parseInt(sendToUserGroup);
        break;
      default: break;
    }

    var texts = [];
    taskTexts.forEach(function (rowData) {
      texts.push({
        language: rowData.language,
        text: rowData.languageDescription
      });
    });

    var conditions = [];
    additionalConditions.forEach(function (rowData) {
      conditions.push({
        code: parseInt(rowData.code),
        actualValue: rowData.applicationValue
      });
    });

    var json = JSON.stringify({
      outletId: outletId == 0 ? null : parseInt(outletId),
      description: description,
      teamId: teamId == 0 ? null : parseInt(teamId),
      recipientType: sendTo,
      recipientId: recipientId,
      isActive: isActive,
      hasOneTask: hasOneTask,
      expiryPeriod: expiryPeriod == '' ? null : parseInt(expiryPeriod),
      trigger: triggerCode != 0 ? {
        code: parseInt(triggerCode),
        oldValue: oldValue,
        newValue: newValue
      } : null,
      texts: texts.length == 0 ? null : texts,
      conditions: conditions.length == 0 ? null : conditions,
      nextWorkflowId: workflowId == 0 ? null : parseInt(workflowId),

    });

    fetch('/api/mobopro/v1/eventtasks/workflows', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'PublicKey': localStorage.getItem('publicKey'),
        'Authorization-token': localStorage.getItem('authorizationToken'),
      }),
      body: json
    }).then(response => {

      if (!response.ok) {
        response.json().then(result => {
          for (var i = 0; i < result.errors.length; i++) {
            DisplayMessage(result.errors[i], "error");
          }
          this.setState({
            loading: false
          });
        });
      }
      else {
        DisplayMessage(resources["NewWorkflowWasSaved"], "success");
        this.setState({ loading: false, TaskWorkflowWasCreated: true, redirect: "/taskworkflows" });
      }
    });
  }

  saveEditedTaskWorkflow = () => {
    const { resources } = this.props;
    const { taskWorkflowId, description, isActive, hasOneTask, expiryPeriod, workflowId, sendTo, sendToOutletUser, sendToUserGroup, triggerCode, oldValue, newValue, textToBeAdded, conditionToBeAdded, textToBeDeleted, conditionToBeDeleted } = this.state;

    var recipientId = null;
    switch (sendTo) {
      case 20: recipientId = parseInt(sendToOutletUser);
        break;
      case 30: recipientId = parseInt(sendToUserGroup);
        break;
      default: break;
    }

    var request = {
      description: description,
      recipientType: sendTo,
      recipientId: recipientId,
      isActive: isActive,
      hasOneTask: hasOneTask,
      expiryPeriod: expiryPeriod == '' ? null : parseInt(expiryPeriod),
      nextWorkflowId: workflowId == 0 ? null : parseInt(workflowId)
    };

    if (triggerCode != 0) {
      request.trigger = {
        oldValue: oldValue,
        newValue: newValue,
      };
    }

    this.setState({ loading: true });

    const performAsyncOperations = async () => {
      this.setState({ loading: true });

      const patchRequest = fetch(`/api/mobopro/v1/eventtasks/workflows/${taskWorkflowId}`, {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/json',
          'PublicKey': localStorage.getItem('publicKey'),
          'Authorization-token': localStorage.getItem('authorizationToken'),
        }),
        body: JSON.stringify(request)
      });

      const postConditionsAndTexts = async () => {
        if (textToBeAdded.length > 0 || conditionToBeAdded.length > 0) {
          const json = JSON.stringify({
            texts: textToBeAdded,
            conditions: conditionToBeAdded
          });

          const response = await fetch(`/api/mobopro/v1/eventtasks/workflows/${taskWorkflowId}/conditionsandtexts`, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'PublicKey': localStorage.getItem('publicKey'),
              'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
          });

          if (!response.ok) {
            const result = await response.json();
            for (let i = 0; i < result.errors.length; i++) {
              DisplayMessage(result.errors[i], "error");
            }
          } else {
            DisplayMessage(resources["TaskTextSaved"], "success");
            DisplayMessage(resources["ConditionSaved"], "success");
          }
        }
      };

      const deleteTexts = async () => {
        if (textToBeDeleted.length > 0) {
          const ids = textToBeDeleted.join(`&id=`);
          const response = await fetch(`/api/mobopro/v1/eventtasks/workflows/${taskWorkflowId}/conditionsandtexts?istext=true&id=${ids}`, {
            method: 'DELETE',
            headers: new Headers({
              'Content-Type': 'application/json',
              'PublicKey': localStorage.getItem('publicKey'),
              'Authorization-token': localStorage.getItem('authorizationToken'),
            })
          });

          if (response.ok) {
            DisplayMessage(resources["TaskTextRemoved"], "success");
          }
          else {
            const result = await response.json();
            for (let i = 0; i < result.errors.length; i++) {
              DisplayMessage(result.errors[i], "error");
            }
          }
        }
      };

      const deleteConditions = async () => {
        if (conditionToBeDeleted.length > 0) {
          const ids = conditionToBeDeleted.join(`&id=`);
          const response = await fetch(`/api/mobopro/v1/eventtasks/workflows/${taskWorkflowId}/conditionsandtexts?istext=false&id=${ids}`, {
            method: 'DELETE',
            headers: new Headers({
              'Content-Type': 'application/json',
              'PublicKey': localStorage.getItem('publicKey'),
              'Authorization-token': localStorage.getItem('authorizationToken'),
            })
          });

          if (response.ok) {
            DisplayMessage(resources["ConditionRemoved"], "success");
          }
          else {
            const result = await response.json();
            for (let i = 0; i < result.errors.length; i++) {
              DisplayMessage(result.errors[i], "error");
            }
          }
        }
      };

      try {
        const patchResponse = await patchRequest;
        await Promise.all([deleteTexts(), deleteConditions()]);
        await postConditionsAndTexts();

        if (patchResponse && !patchResponse.ok) {
          const result = await patchResponse.json();
          for (let i = 0; i < result.errors.length; i++) {
            DisplayMessage(result.errors[i], "error");
          }
          this.setState({ loading: false, textToBeAdded: [], conditionToBeAdded: [], textToBeDeleted: [], conditionToBeDeleted: [] });
        } else {
          DisplayMessage(resources["WorkflowWasSaved"], "success");
          this.setState({ loading: false, TaskWorkflowWasCreated: true, redirect: "/taskworkflows" });

        }
      } catch (error) {
        this.setState({ loading: false });
      }
    };


    performAsyncOperations();

  }

  renderOutlets = () => {
    if (this.props.accessibleMoboOutlets === undefined) return;
    var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)

    outlets = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
    return outlets;
  }

  renderTeams = () => {
    if (this.props.workshopPlanningTeams === undefined) return;
    var teams = this.props.workshopPlanningTeams.filter(x => x.teamOutletId == this.state.outletId).sort((a, b) => (a.teamDescription.toLowerCase() > b.teamDescription.toLowerCase()) ? 1 : -1)

    teams = teams.map(x => <option key={x.teamId} value={x.teamId}> {x.teamDescription}</option>);
    return teams;
  }

  renderTriggers = () => {
    if (this.state.triggers === undefined) return;
    var triggers = this.state.triggers.sort((a, b) => (a.codeDescription > b.codeDescription) ? 1 : -1)

    triggers = triggers.map(x => <option key={x.code} value={x.code}> {x.codeDescription}</option>);
    return triggers;
  }

  renderOldValues = () => {
    var trigger = this.state.triggers.find(x => x.code == this.state.triggerCode);
    if (trigger != null) {
      var oldValues = trigger.selectionValues.sort((a, b) => (a.applicationValueDescription > b.applicationValueDescription) ? 1 : -1)

      oldValues = oldValues.map(x => <option key={x.applicationValue ?? 'null'} value={x.applicationValue ?? 'null'}> {x.applicationValueDescription}</option>);
      return oldValues;
    }
    return;
  }

  renderNewValues = () => {
    var trigger = this.state.triggers.find(x => x.code == this.state.triggerCode);
    if (trigger != null) {
      var newValues = trigger.selectionValues.filter(x => x.applicationValue != this.state.oldValue).sort((a, b) => (a.applicationValueDescription > b.applicationValueDescription) ? 1 : -1)

      newValues = newValues.map(x => <option key={x.applicationValue ?? 'null'} value={x.applicationValue ?? 'null'}> {x.applicationValueDescription}</option>);
      return newValues;
    }
    return;
  }

  renderTaskTexts() {
    var taskTexts = this.state.taskTexts.map(x =>
      <div key={x.id} className="d-flex">
        <div className="col-3"></div>
        <div className="col-6 d-flex text-left px-0">
          <div className="col-4 px-0">{x.language}</div>
          <div className="col-7 px-0">{x.languageDescription}</div>
          <div className="col-1">
            <IconButton onClick={() => this.removeLanguage(x.id)} size="small"><DeleteIcon style={{ color: "#C4455B" }} /></IconButton>
          </div>
        </div>
      </div>
    );
    return taskTexts;
  }

  renderAddLanguage() {
    const { resources } = this.props;
    const { showAddLanguage, language, languageDescription, taskTexts } = this.state;

    var nlLanguageUsed = taskTexts.find(x => x.language === 'nl') !== undefined;
    var frLanguageUsed = taskTexts.find(x => x.language === 'fr') !== undefined;
    var enLanguageUsed = taskTexts.find(x => x.language === 'en') !== undefined;
    var deLanguageUsed = taskTexts.find(x => x.language === 'de') !== undefined;
    var csLanguageUsed = taskTexts.find(x => x.language === 'cs') !== undefined;

    if (showAddLanguage) {
      return (
        <div>
          <Form.Group className="pt-3 d-flex"  >
            <ThemeProvider theme={SelectTheme}>
              <div className="col-3"></div>
              <FormControl className="col-2 pr-2 helper-text-red" variant="outlined" onChange={this.handleChange}>
                <InputLabel htmlFor="outlined-type-native-simple">{resources["Language"]}</InputLabel>
                <Select
                  id="tasktexts-language"
                  native
                  label={resources["Language"]}
                  value={language}
                  inputProps={{
                    name: 'language',
                    id: 'tasktexts-language',
                  }}>
                  {!nlLanguageUsed ? <option value={"nl"} key={"nl"}>{"Nederlands"}</option> : ""}
                  {!frLanguageUsed ? <option value={"fr"} key={"fr"}>{"Français"}</option> : ""}
                  {!enLanguageUsed ? <option value={"en"} key={"en"}>{"English"} </option> : ""}
                  {!deLanguageUsed ? <option value={"de"} key={"de"}>{"Deutsch"}</option> : ""}
                  {!csLanguageUsed ? <option value={"cs"} key={"cs"}>{"Czech"}</option> : ""}
                </Select>
              </FormControl>

              <TextField className="col-4" id="taskworkflow-language-description" value={languageDescription} label={resources["Description"]}
                variant="outlined" name="languageDescription" onChange={this.handleChange} inputProps={{ maxLength: 100 }} />
              <div className="col-2 px-2 text-left">
                <IconButton size="small"><CheckIcon onClick={() => this.saveLanguage()} /></IconButton>
                <IconButton size="small"><CloseIcon onClick={() => this.closeAddLanguage()} /></IconButton>
              </div>
            </ThemeProvider >
          </Form.Group>
        </div>)
    }
    else {
      return;
    }
  }

  renderAdditionalConditions() {
    var taskTexts = this.state.additionalConditions.map(x =>
      <div key={x.id} className="d-flex">
        <div className="col-3"></div>
        <div className="col-6 d-flex text-left px-0">
          <div className="col-4 px-0">{x.codeDescription}</div>
          <div className="col-1 px-0">=</div>
          <div className="col-6 px-0">{x.applicationValueDescription}</div>
          <div className="col-1"><IconButton onClick={() => this.removeCondition(x.id)} size="small"><DeleteIcon style={{ color: "#C4455B" }} /></IconButton></div>
        </div>
      </div>
    );
    return taskTexts;
  }

  renderConditionsDropdown() {
    const { triggerValueLessThan100 } = this.state;
    var conditions = this.state.conditions;
    var additionalConditions = this.state.additionalConditions;

    var conditionsCodes = additionalConditions.map(x => x.code);
    conditions = conditions.filter(val => !conditionsCodes.includes(val.code));

    if (triggerValueLessThan100) {
      conditions = conditions.filter(x => x.code < 100);
    }

    var conditionsOptions = conditions.map(x => <option key={x.code} value={x.code}> {x.codeDescription}</option>);
    return conditionsOptions;
  }

  renderConditionValuesDropdown() {
    var condition = this.state.conditions.find(x => x.code == this.state.conditionCode);

    if (condition != null) {
      var conditionValues = condition.selectionValues.sort((a, b) => (a.applicationValueDescription > b.applicationValueDescription) ? 1 : -1)

      conditionValues = conditionValues.map(x => <option key={x.applicationValue} value={x.applicationValue}> {x.applicationValueDescription}</option>);
      return conditionValues;
    }
    return;
  }

  renderAddCondition() {
    const { resources } = this.props;
    const { showAddCondition, conditionCode, conditionValue } = this.state;

    if (showAddCondition) {
      return (
        <div>
          <Form.Group className="pt-3 d-flex"  >
            <ThemeProvider theme={SelectTheme}>
              <div className="col-3"></div>
              <FormControl className="col-3 pr-2 helper-text-red" variant="outlined" onChange={this.handleChange}>
                <InputLabel htmlFor="outlined-type-native-simple">{resources["Condition"]}</InputLabel>
                <Select
                  id="tasktexts-condition-code"
                  native
                  label={resources["Condition"]}
                  value={conditionCode}
                  inputProps={{
                    name: 'conditionCode',
                    id: 'tasktexts-condition-code',
                  }}>
                  <option value={0}>{resources["DefaultDropdownValue"]}</option>
                  {this.renderConditionsDropdown()}
                </Select>
              </FormControl>

              <FormControl className="col-3 pr-2 helper-text-red" variant="outlined" onChange={this.handleChange}>
                <InputLabel htmlFor="outlined-type-native-simple">{resources["Value"]}</InputLabel>
                <Select
                  id="tasktexts-condition-value"
                  native
                  label={resources["Value"]}
                  value={conditionValue}
                  inputProps={{
                    name: 'conditionValue',
                    id: 'tasktexts-condition-value',
                  }}>
                  <option value=' '>{resources["DefaultDropdownValue"]}</option>
                  {this.renderConditionValuesDropdown()}
                </Select>
              </FormControl>

              <div className="col-2 px-2 text-left">
                <IconButton size="small"><CheckIcon onClick={() => this.saveCondition()} /></IconButton>
                <IconButton size="small"><CloseIcon onClick={() => this.closeAddCondition()} /></IconButton>
              </div>
            </ThemeProvider >
          </Form.Group>
        </div>)
    }
    else {
      return;
    }
  }

  renderUserDropdown() {
    const { outletUsers } = this.state;
    var outletUsersOptions = outletUsers.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

    outletUsersOptions = outletUsersOptions.map(x => <option key={x.id} value={x.id}> {x.name}</option>);
    return outletUsersOptions;
  }

  renderUserGroupDropdown() {
    const { userGroups } = this.state;
    var userGroupsOptions = userGroups.sort((a, b) => (a.description.toLowerCase() > b.description.toLowerCase()) ? 1 : -1);

    userGroupsOptions = userGroupsOptions.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
    return userGroupsOptions;
  }

  renderSentTo() {
    const { resources } = this.props;
    const { sendTo, sendToOutletUser, sendToUserGroup, triggerCode } = this.state;

    return (
      <Form.Group style={{ display: "flex", width: "100%", justifyContent: "left" }}  >
        <div className="col-3"> </div>
        <div className="col-2 px-0">
          <RadioGroup defaultValue={sendTo} value={sendTo} onClick={(e) => this.handleSendTo(e.target.value)}>
            <FormControlLabel value={99} control={<Radio />} label={resources["Everybody"]} />
            <FormControlLabel value={10} control={<Radio />} label={resources["DossierOwner"]} />
            {triggerCode >= 100 ? <FormControlLabel value={11} control={<Radio />} label={resources["JobAssignee"]} /> : ""}
            <FormControlLabel value={20} control={<Radio />} label={resources["User"]} />
            <FormControlLabel value={30} control={<Radio />} label={resources["UserGroup"]} />
          </RadioGroup>
        </div>
        <div className="col-4">
          <div className="fadeOut">
            <Form.Group className="d-flex"  >
              <ThemeProvider theme={SelectTheme}>
                <FormControl variant="outlined">
                  <Select>
                  </Select>
                </FormControl>
              </ThemeProvider >
            </Form.Group>
          </div>
          <div className="fadeOut">
            <Form.Group className="d-flex"  >
              <ThemeProvider theme={SelectTheme}>
                <FormControl variant="outlined">
                  <Select></Select>
                </FormControl>
              </ThemeProvider >
            </Form.Group>
          </div>
          {triggerCode >= 100 ?
            <div className="fadeOut">
              <Form.Group className="d-flex"  >
                <ThemeProvider theme={SelectTheme}>
                  <FormControl variant="outlined">
                    <Select></Select>
                  </FormControl>
                </ThemeProvider >
              </Form.Group>
            </div> : ""}
          <div className={this.state.sendTo == 20 ? "fadeIn" : "fadeOutPartially"}>
            <Form.Group className="d-flex"  >
              <ThemeProvider theme={SelectTheme}>
                <FormControl className="col-12 helper-text-red" variant="outlined" onChange={this.handleChange}>
                  <InputLabel htmlFor="outlined-type-native-simple">{resources["OutletUser"]}</InputLabel>
                  <Select
                    id="sendTo-outlet-user"
                    native
                    label={resources["OutletUser"]}
                    value={sendToOutletUser}
                    inputProps={{
                      name: 'sendToOutletUser',
                      id: 'sendTo-outletUser',
                    }}>
                    <option value={0}>{resources["DefaultDropdownValue"]}</option>
                    {this.renderUserDropdown()}
                  </Select>
                </FormControl>
              </ThemeProvider >
            </Form.Group>
          </div>
          <div className={this.state.sendTo == 30 ? "fadeIn" : "fadeOutPartially"}>
            <Form.Group className="d-flex"  >
              <ThemeProvider theme={SelectTheme}>
                <FormControl className="col-12 helper-text-red" variant="outlined" onChange={this.handleChange}>
                  <InputLabel htmlFor="outlined-type-native-simple">{resources["UserGroup"]}</InputLabel>
                  <Select
                    id="sendTo-user-group"
                    native
                    label={resources["UserGroup"]}
                    value={sendToUserGroup}
                    inputProps={{
                      name: 'sendToUserGroup',
                      id: 'sendTo-userGroup',
                    }}>
                    <option value={0}>{resources["DefaultDropdownValue"]}</option>
                    {this.renderUserGroupDropdown()}
                  </Select>
                </FormControl>
              </ThemeProvider >
            </Form.Group>
          </div>
        </div>
      </Form.Group>)
  }

  renderWorkflows = () => {
    var workflows = this.state.workflows.sort((a, b) => (a.description > b.description) ? 1 : -1)

    workflows = workflows.filter(x => x.isTriggeredByOtherWorkflow == true).map(x => <option key={x.id} value={x.id}> {x.description}</option>);
    return workflows;
  }

  renderModifiedByInfo = () => {
    let resources = this.props.resources;
    if (this.state.taskWorkflowId > 0) {
      return (<div className="d-flex mt-4">
        <div className="w-100">
          <span className=" mr-2">
            <img className="rounded-circle" alt="user-avatar" src={this.state.modifiedByAvatar != null ? this.state.modifiedByAvatar : "/static/media/defaultavatar.02624da3.png"} width="35" />
          </span>
          <span>
            {`${resources["ModifiedOn"]} ${moment(this.state.modifiedOn).format('DD/MM/YYYY HH:mm').toLocaleString()} ${resources["By"]} ${this.state.modifiedByName}`}
          </span>
        </div>
      </div>);
    }
    return;
  }

  renderForm() {
    const handleSubmit = (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.handleSubmit();
    };
    const { taskWorkflowId, description, isActive, hasOneTask, expiryPeriod, triggeredByOtherWorkflow, outletId, hasUsedGroupName, sendToUserGroup } = this.state;
    const { resources } = this.props;

    var helperTextDescription = description.trim().length <= 0 ? "helper-text-red" : "helper-text-grey";

    return (
      <Paper elevation={3} className="p-4 mt-3 mb-3">
        <Form onSubmit={handleSubmit} >
          <Form.Group className="pt-3 d-flex"  >
            <div className="col-3"></div>
            <ThemeProvider theme={SelectTheme}>
              <TextField className={"col-6 " + helperTextDescription} id="taskworkflow-description" value={description} label={resources["Description"]}
                helperText={resources["InputOfDescriptionIsMandatory"]}
                variant="outlined" name="description" placeholder={resources["GiveYourWorkflowAMeaningfulDescription"]} onChange={this.handleChange} inputProps={{ maxLength: 250 }} />
            </ThemeProvider >
          </Form.Group>

          <div className={taskWorkflowId != 0 ? "fadeOutPartially" : "fadeIn"}>
            <Form.Group className="d-flex">
              <div className="col-3"></div>
              <FormControl className="col-6 helper-text-red" variant="outlined" onChange={this.handleChangeOutlet}>
                <ThemeProvider theme={SelectTheme}>
                  <InputLabel htmlFor="outlined-outlet-native-simple">{resources["Outlet"]}</InputLabel>
                </ThemeProvider >
                <Select
                  id="taskworkflow-outlet"
                  native
                  label={resources["Outlet"]}
                  value={this.state.outletId}
                  inputProps={{
                    name: 'outlet',
                    id: 'outlined-outlet-native-simple',
                  }}>
                  <option value={0}>{resources["All"]}</option>
                  {this.renderOutlets()}
                </Select>
              </FormControl>
            </Form.Group>
          </div>
          <div className={outletId == 0 || taskWorkflowId != 0 ? "fadeOutPartially" : "fadeIn"}>
            <Form.Group className="d-flex" >
              <div className="col-3"></div>
              <FormControl className="col-6 helper-text-red" variant="outlined" onChange={this.handleChangeTeam}>
                <ThemeProvider theme={SelectTheme}>
                  <InputLabel htmlFor="outlined-team-native-simple">{resources["Team"]}</InputLabel>
                </ThemeProvider >
                <Select
                  id="taskworkflow-team"
                  native
                  label={resources["Team"]}
                  value={this.state.teamId}
                  inputProps={{
                    name: 'team',
                    id: 'outlined-team-native-simple',
                  }}>
                  <option value={0}>{resources["All"]}</option>
                  {this.renderTeams()}
                </Select>
              </FormControl>
            </Form.Group>
          </div>

          <Form.Group style={{ display: "flex" }} >
            <div className="col-md-3 "> </div>
            <FormControlLabel className="col-md-6 text-right label-mt my-auto p-0"
              control={<Checkbox id="taskworkflow-is-active" checked={isActive} onChange={this.handleChangeCheckBox} name="isActive" color="primary" />}
              label={resources["IsActive"]} />
            <div className="col-md-4"> </div>
          </Form.Group>

          <Form.Group style={{ display: "flex" }} >
            <div className="col-md-3 "> </div>
            <FormControlLabel className="col-md-6 text-right label-mt my-auto p-0"
              control={<Checkbox id="taskworkflow-has-one-task" checked={hasOneTask} onChange={this.handleChangeCheckBox} name="hasOneTask" color="primary" />}
              label={resources["HasOnlyOneOpenTaskSimultaneously"]} />
            <div className="col-md-4"> </div>
          </Form.Group>

          <Form.Group className="d-flex"  >
            <div className="col-3"></div>
            <ThemeProvider theme={SelectTheme}>
              <TextField className={"col-6"} id="taskworkflow-expiryPeriod" inputProps={{ maxLength: 2 }} value={expiryPeriod} name="expiryPeriod" type="number"
                onKeyDown={ExcludeSpecialCharactersFromInputTypeNumber(false)}
                label={resources["ExpiryPeriod"]} variant="outlined" onChange={this.handleChange} />
            </ThemeProvider >
          </Form.Group>

          <Form.Group className="d-flex">
            <div className="col-3"></div>
            <div className="col-6 px-0 text-left">
              <hr className="MuiDivider-root mt-2 mb-3" />
              <h6>{resources["Trigger"]}</h6>
            </div>
          </Form.Group>

          <div className={taskWorkflowId == 0 ? "fadeIn" : "fadeOutPartially"}>
            <Form.Group style={{ display: "flex" }} >
              <div className="col-md-3 "> </div>
              <FormControlLabel className="col-md-6 text-right label-mt my-auto p-0"
                control={<Checkbox id="taskworkflow-triggeredByOtherWorkflow" checked={triggeredByOtherWorkflow} onChange={this.handleChangeCheckBox} name="triggeredByOtherWorkflow" color="primary" />}
                label={resources["TriggeredByOtherWorkflow"]} />
              <div className="col-md-4"> </div>
            </Form.Group>
          </div>
        </Form>

        <div className={triggeredByOtherWorkflow || taskWorkflowId != 0 ? "fadeOutPartially" : "fadeIn"}>
          <Form.Group className="d-flex" >
            <div className="col-3"></div>
            <div className="col-2 text-left px-0">{resources["If"]}</div>
            <FormControl className="col-4 helper-text-red" variant="outlined" onChange={this.handleChangeTrigger}>
              <ThemeProvider theme={SelectTheme}>
                <InputLabel htmlFor="outlined-team-native-simple">{resources["Trigger"]}</InputLabel>
              </ThemeProvider >
              <Select
                id="taskworkflow-trigger"
                native
                label={resources["Trigger"]}
                value={this.state.triggerCode}
                inputProps={{
                  name: 'trigger',
                  id: 'outlined-trigger-native-simple',
                }}>
                <option value={0}>{resources["DefaultDropdownValue"]}</option>
                {this.renderTriggers()}
              </Select>
            </FormControl>
          </Form.Group>
        </div>

        <div className={triggeredByOtherWorkflow ? "fadeOutPartially" : "fadeIn"}>
          <Form.Group className="d-flex" >
            <div className="col-3"></div>
            <div className="col-2 text-left px-0">{resources["ChangesFrom"]}</div>
            <FormControl className="col-4 helper-text-red" variant="outlined" onChange={this.handleChangeOldValue}>
              <ThemeProvider theme={SelectTheme}>
                <InputLabel htmlFor="outlined-team-native-simple">{resources["OldValue"]}</InputLabel>
              </ThemeProvider >
              <Select
                id="taskworkflow-oldvalue"
                native
                label={resources["OldValue"]}
                value={this.state.oldValue}
                inputProps={{
                  name: 'old-values',
                  id: 'outlined-old-values-native-simple',
                }}>
                <option value={0}>{resources["DefaultDropdownValue"]}</option>
                {this.renderOldValues()}
              </Select>
            </FormControl>
          </Form.Group>
        </div>

        <div className={triggeredByOtherWorkflow ? "fadeOutPartially" : "fadeIn"}>
          <Form.Group className="d-flex" >
            <div className="col-3"></div>
            <div className="col-2 text-left px-0">{resources["To"]}</div>
            <FormControl className="col-4 helper-text-red" variant="outlined" onChange={this.handleChangeNewValue}>
              <ThemeProvider theme={SelectTheme}>
                <InputLabel htmlFor="outlined-team-native-simple">{resources["NewValue"]}</InputLabel>
              </ThemeProvider >
              <Select
                id="taskworkflow-newvalue"
                native
                label={resources["NewValue"]}
                value={this.state.newValue}
                inputProps={{
                  name: 'new-values',
                  id: 'outlined-new-values-native-simple',
                }}>
                <option value={0}>{resources["DefaultDropdownValue"]}</option>
                {this.renderNewValues()}
              </Select>
            </FormControl>
          </Form.Group>
        </div>

        <div>
          <div className="d-flex">
            <div className="col-3"></div>
            <div className="col-6 px-0 text-left">
              <hr className="MuiDivider-root mt-2 mb-3" />
              <h6>{resources["TaskTexts"]}</h6>
            </div>
          </div>
          <div className="d-flex pb-3">
            <div className="col-3"></div>
            <div className="col-6 px-0 text-left d-flex">
              <InfoOutlinedIcon color="disabled"></InfoOutlinedIcon>
              <div className="ml-2 color-grey">
                {resources["UseCreatedByAndGroupName"]}
              </div>
            </div>
          </div>
          {this.renderTaskTexts()}
          {this.renderAddLanguage()}
          {this.state.showAddLanguage ? "" :
            <div className="d-flex my-2">
              <div className="col-9 px-0 text-right">
                <Button id="add-language" variant="contained" color="primary" onClick={this.toggleAddLanguage}>
                  <i className="fas fa-plus mr-2" title={resources["AddLanguage"]} />
                  {resources["AddLanguage"]}
                </Button>
              </div>
            </div>
          }
        </div>

        <div>
          <div className="d-flex pb-3">
            <div className="col-3"></div>
            <div className="col-6 px-0 text-left">
              <hr className="MuiDivider-root mt-2 mb-3" />
              <h6>{resources["AdditionalConditions"]}</h6>
            </div>
          </div>
          {this.renderAdditionalConditions()}
          {this.renderAddCondition()}
          {this.state.showAddCondition ? "" :
            <div className="d-flex my-2">
              <div className="col-9 px-0 text-right">
                <Button id="add-condition" variant="contained" color="primary" onClick={() => this.toggleAddCondition()}>
                  <i className="fas fa-plus mr-2" title={resources["AddCondition"]} />
                  {resources["AddCondition"]}
                </Button>
              </div>
            </div>
          }
        </div>

        <div>
          <div className="d-flex pb-3">
            <div className="col-3"></div>
            <div className="col-6 px-0 text-left">
              <hr class="MuiDivider-root mt-2 mb-3" />
              <h6>{resources["SendTo"]}</h6>
            </div>
          </div>
          {this.renderSentTo()}

          {hasUsedGroupName && sendToUserGroup == 0 ? (
            <div className="d-flex">
              <div className="col-3"></div>
              <div className="col-6 px-0 text-left d-flex">
                <InfoOutlinedIcon style={{ color: "#C4455B" }}></InfoOutlinedIcon>
                <div className="ml-2 color-red">
                  {resources["YouUsedGroupName"]}
                </div>
              </div>
            </div>) : (<div></div>)}
          {!hasUsedGroupName && sendToUserGroup != 0 ? (
            <div className="d-flex">
              <div className="col-3"></div>
              <div className="col-6 px-0 text-left d-flex">
                <InfoOutlinedIcon style={{ color: "#C4455B" }}></InfoOutlinedIcon>
                <div className="ml-2 color-red">
                  {resources["YouHaveNotUsedGroupName"]}
                </div>
              </div>
            </div>) : (<div></div>)}
        </div>

        <div className="d-flex">
          <div className="col-3"></div>
          <div className="col-6 px-0 text-left">
            <hr class="MuiDivider-root mt-2 mb-3" />
            <h6>{resources["NextWorkflow"]}</h6>
          </div>
        </div>
        <div className="d-flex pb-3">
          <div className="col-3"></div>
          <div className="col-6 px-0 text-left d-flex">
            <InfoOutlinedIcon color="disabled"></InfoOutlinedIcon>
            <div className="ml-2 color-grey">
              {resources["OptionallySelectWorkflow"]}
            </div>
          </div>
        </div>
        <Form.Group className="d-flex" >
          <div className="col-3"></div>
          <FormControl className="col-6 helper-text-red" variant="outlined" onChange={this.handleChangeWorkflow}>
            <ThemeProvider theme={SelectTheme}>
              <InputLabel htmlFor="outlined-outlet-native-simple">{resources["Workflow"]}</InputLabel>
            </ThemeProvider >
            <Select
              id="workflow-optional"
              native
              label={resources["Workflow"]}
              value={this.state.workflowId}
              inputProps={{
                name: 'workflowId',
                id: 'outlined-outlet-native-simple',
              }}>
              <option value={0}>{resources["DefaultDropdownValue"]}</option>
              {this.renderWorkflows()}
            </Select>
          </FormControl>
        </Form.Group>
        {this.renderModifiedByInfo()}
      </Paper>
    )
  }

  renderMain() {
    const { loading } = this.state;

    if (loading) return (<div className="centered-loader"><CircularProgress /></div>);

    return <div className={"py-4 grey " + (this.state.loading ? 'fadeOut' : 'fadeIn')} style={{ textAlign: "center" }}>
      {this.renderForm()}
    </div>;
  }

  render() {
    const { redirect, open, TaskWorkflowWasCreated, outletId, loading, taskWorkflowId } = this.state;
    const { resources } = this.props;

    return (
      <div>
        {redirect && <DelayedRedirect to={{ pathname: redirect, state: { refreshGrid: TaskWorkflowWasCreated, outletId } }} delay={250} />}
        <Dialog fullScreen open={open} onClose={this.handleClose} TransitionComponent={Transition}>
          <AppBar className="position-sticky">
            <Toolbar>
              <IconButton id="close-add-taskworkflow" edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" style={{ flex: 1 }}>
                {taskWorkflowId == 0 ? resources["NewWorkflowTitle"] : resources["EditWorkflowTitle"]}
              </Typography>
              <Button id="taskworkflow-save" color="inherit" disabled={loading} onClick={this.handleSubmit}>{resources["Save"]}</Button>
            </Toolbar>
          </AppBar>
          <div className="container">
            {this.renderMain()}
          </div>
        </Dialog>
      </div >
    );
  }
}