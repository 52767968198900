import React from 'react';
import { Route, Redirect } from 'react-router';

export function RestrictedRoute({ children, userPermissions, routePermissions, resources, render, ...rest }) {
    let isUserAccessAllowed = false;

    if (routePermissions.length == 0) {
        isUserAccessAllowed = true;
    } else {
        routePermissions.forEach(routePermission => {
            let existingPermission = userPermissions.find(permission => permission.id === routePermission);
            if (existingPermission) {
                isUserAccessAllowed = true;
            }
        });
    }

    if (isUserAccessAllowed) {
        if (render) {
            return (<Route {...rest} render={render} />);
        } else {
            return (<Route {...rest}>{children}</Route>);
        }
    } else {
        return (<Route {...rest}><Redirect to={{ pathname: "/", state: { showPathNotAllowed: true } }} /></Route>)
    }
}