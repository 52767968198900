import React from 'react';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import OccupancyRates from '../components/WorkshopPlanning/Planning/OccupancyRates/OccupancyRates';
import { Permissions } from '../utils/Permissions';

export default function OccupancyRatesRouting(props) {
    const { currentIdentity, resources } = props;
    return (
        <>
            <RestrictedRoute path='/occupancyrates' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.WorkshopSettings]} resources={resources} render={(props) => {
                return (<OccupancyRates resources={resources} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} />)
            }} />
        </>
    )
}