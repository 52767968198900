import React from 'react';
import { Route, Switch } from 'react-router';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import SnippetsTable from '../components/WorkshopPlanning/General/Snippets/SnippetsTable';
import SnippetCreationV2 from '../components/WorkshopPlanning/General/Snippets/SnippetCreationV2';
import SnippetCreation from '../components/WorkshopPlanning/General/Snippets/SnippetCreation';
import SnippetEditing from '../components/WorkshopPlanning/General/Snippets/SnippetEditing';
import { Permissions } from '../utils/Permissions';

export default function SnippetsRouting(props) {
    const { currentIdentity, resources } = props;
    return (
        <>
            <RestrictedRoute path='/snippets' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.WorkshopSettings]} resources={resources} render={(props) => {
                return (<SnippetsTable resources={resources} refreshGrid={props && props.location && props.location.state && props.location.state.refreshGrid} snipetWasCreated={props && props.location && props.location.state && props.location.state.snippetWasCreated} />)
            }} />
            <Switch>
                {/* <Route exact path='/snippets/new' render={(props) => <SnippetCreation resources={resources} language={currentIdentity.culture.substring(0, 2)} />} /> */}
                <Route exact path='/snippets/new' render={(props) => <SnippetCreationV2 resources={resources} language={currentIdentity.culture.substring(0, 2)} />} />
                <Route exact path='/snippets/:snippetId' render={(props) => {
                    return (<SnippetEditing resources={resources} snippetId={props.match.params.snippetId} language={currentIdentity.culture.substring(0, 2)} {...props} />)
                }} />
            </Switch>
        </>
    )
}