import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TeamMemberNew from './TeamMemberNew';
import { Slide } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
    const [open, setOpen] = React.useState(false);
    var { showEditMember, accessibleMoboOutlets, teamId, memberFrom, memberTill, resources } = props;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.setShowEditMember(false);
    };
    return (

        <div>
            <Button className="btn btn-primary pull-right margin my-2 mx-2" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }} onClick={handleClickOpen}>
                <i className="fas fa-plus mr-2" title={resources["AddNewTeamMember"]} />
                {resources["AddNewTeamMember"]}
            </Button>
            <Dialog fullWidth={true} maxWidth="sm" open={open || showEditMember} onClose={handleClose} TransitionComponent={Transition}>
                <TeamMemberNew onClose={handleClose}
                    memberFrom={memberFrom}
                    memberTill={memberTill}
                    accessibleMoboOutlets={accessibleMoboOutlets}
                    teamId={teamId}
                    resources={resources}
                    closeDialogMembers={handleClose} />
            </Dialog>
        </div>
    );
}
