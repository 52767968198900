import React, { Component, forwardRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import MaterialTable from 'material-table';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Loader from '../../../Loader.js';
import Form from 'react-bootstrap/Form';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Paper from '@material-ui/core/Paper';

import CircularProgress from '@material-ui/core/CircularProgress';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Button from '@material-ui/core/Button';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { DisplayMessage, GetOutletUserDescription } from '../../../../services/helpers.js';
import FontDownloadRoundedIcon from '@material-ui/icons/FontDownloadRounded';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { SelectTheme, LocalizationTheme } from '../../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    FontDownloadRoundedIcon: forwardRef((props, ref) => <FontDownloadRoundedIcon {...props} ref={ref} />)
};

export default class MobilitySolutionsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataGrid: [],
            outletId: 0,
            toOutletId: 0,
            outletDescription: "",
            fetchingData: true,
            showDelete: false,
            showCopy: false,
            deletedItem: 0,

            copiedMobilitySolution: undefined,

            accessibleMoboOutlets: [],
            editedMobilitySolutionId: undefined,
            editedMobilitySolution: undefined,
            showEditMobilitySolution: false,
            requestInProgress: false,
            checkedForData: false,

        };
    }
    componentWillReceiveProps(newProps) {
        if (newProps.refreshGrid && newProps.outletId) {
            this.getData(newProps.outletId);
        }

        this.setState({ redirect: null });
    }

    componentWillMount() {
        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;

        var id = outlets[0].id;
        var description = outlets[0].description;

        if (this.props.outletId !== undefined) {
            this.setState({ outletId: this.props.outletId });
        }
        else {
            this.setState({ outletId: id, outletDescription: description });
        }

        this.getData();
    }

    handleChangeOutlet = e => {
        this.setState({ outletId: e.target.value, toOutletId: 0, outletDescription: this.props.accessibleMoboOutlets[e.target.selectedIndex].description }, () => this.getData(this.state.outletId));
    };

    handleChangeToOutlet = e => {
        this.setState({ toOutletId: e.target.value });
    };

    renderOutlets = () => {
        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;

        var outletOptions = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outletOptions;
    }

    getData = (outletId) => {
        this.setState({ fetchingData: true });

        if (outletId === undefined || outletId === "") {
            var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
            if (this.props.accessibleMoboOutlets === undefined) return;
            outletId = this.state.outletId > 0 ? this.state.outletId : outlets[0].id;
        }

        let initialData = {};
        var resources = this.props.resources;
        initialData.columns = [
            { title: resources["Language"], width: "30%", field: 'language', filtering: false, customSort: (a, b) => a.language.toLowerCase().localeCompare(b.language.toLowerCase()) },
            { title: resources["Description"], width: "50%", field: 'description', filtering: false, customSort: (a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()) },
        ];

        initialData.actions = [
            {
                icon: () => <Edit />,
                tooltip: resources["Edit"],
                onClick: (event, rowData) => this.setState({ redirect: `mobilitysolutions/${rowData.moboOutletId}/${rowData.id}` })
            },
            {
                icon: () => <DeleteOutline />,
                tooltip: resources["Delete"],
                onClick: (event, rowData) => this.handleShowDelete(rowData.id)
            },

            {
                icon: () => <FileCopyIcon />,
                tooltip: resources["Copy"],
                onClick: (event, rowData) => this.handleShowCopy(rowData)
            },
        ]

        fetch('/api/mobopro/v1/mobooutletmobilitysolutions/' + outletId,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {

                if (!response.ok) {
                    response.json().then(data => {
                        for (var i = 0; i < data.errors.length; i++) {
                            DisplayMessage(data.errors[i], "error");
                        }
                    })
                }
                else {
                    response.json().then(result => {

                        const currentRows = [];
                        var daysOfWeek = [];
                        result.forEach(function (rowData) {
                            daysOfWeek.push(rowData.dayOfWeek);
                            currentRows.push({
                                id: rowData.id,
                                language: rowData.language,
                                description: rowData.description,
                                moboOutletId: rowData.moboOutletId,
                                isVisibleInPlanning: rowData.isVisibleInPlanning,
                                shortDescription: rowData.shortDescription,

                                updatedOn: rowData.updatedOn,
                                updatedBy: rowData.updatedBy,
                                createdOn: rowData.createdOn,
                                createdBy: rowData.createdBy,
                            });
                        });

                        initialData.data = currentRows;

                        this.setState({
                            dataGrid: initialData,
                            fetchingData: false,
                            checkedForData: true,
                            outletId: parseInt(outletId)
                        });
                    });
                }
            });
    }

    handleCloseDelete = () => {
        this.setState({ showDelete: false, deletedItem: undefined });
    }

    handleCloseCopy = () => {
        this.setState({ showCopy: false, copiedMobilitySolution: undefined, copiedDescription: "" });
    }

    handleEditMobilitySolution = (itemId, showEditMobilitySolutionDialog) => {
        if (showEditMobilitySolutionDialog === false) { this.getData(this.state.outletId); }
        this.setState({ showEditMobilitySolution: showEditMobilitySolutionDialog, editedMobilitySolutionId: itemId });
    }

    handleShowDelete = (itemId) => {
        this.setState({ showDelete: true, deletedItem: itemId });
    }

    handleDeleteMobilitySolution = () => {
        if (this.state.deletedItem > 0) {
            let resources = this.props.resources;
            this.setState({ requestInProgress: true });
            const url = "/api/mobopro/v1/mobooutletmobilitysolutions/" + this.state.deletedItem;
            fetch(url, {
                method: 'DELETE',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            }).then(response => {
                if (!response.ok) {
                    if (response.statusText.length > 0) {
                        DisplayMessage(response.statusText, "error");
                        return response.json();
                    }

                    for (var i = 0; i < response.errors.length; i++) {
                        DisplayMessage(response.errors[i], "error");
                    }
                }
            }).then(result => {
                DisplayMessage(resources["MobilitySolutionWasDeleted"], "success");
                this.getData(this.state.outletId);
                this.setState({ showDelete: false, deletedItem: 0, requestInProgress: false });
                this.handleCloseDelete();
            });
        }
    }

    handleShowCopy = (item) => {
        var outletDescription = GetOutletUserDescription(this.props.accessibleMoboOutlets, item.moboOutletId)
        this.setState({ showCopy: true, copiedMobilitySolution: item, toOutletId: 0, outletDescription: outletDescription });
    }

    handleCopyMobilitySolution = () => {
        if (this.state.copiedMobilitySolution === undefined) return;

        this.setState({ requestInProgress: true });
        let resources = this.props.resources;

        var copiedMobilitySolution = this.state.copiedMobilitySolution;

        var json = JSON.stringify({
            moboOutletId: this.state.toOutletId,
            language: copiedMobilitySolution.language,
            description: copiedMobilitySolution.description,
            isVisibleInPlanning: copiedMobilitySolution.isVisibleInPlanning,
            shortDescription: copiedMobilitySolution.shortDescription,
        });

        const url = `/api/mobopro/v1/mobooutletmobilitysolutions`;
        fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        })
            .then(response => {
                if (!response.ok) {
                    response.json().then(result => {

                        for (var i = 0; i < result.errors.length; i++) {
                            DisplayMessage(result.errors[i], "error");
                        }
                    });
                }
                this.setState({ requestInProgress: false });
            })
            .then(result => {
                this.setState({
                    updateInProgress: false,
                    mobilitySolutionWasCreated: true,
                    redirect: "/mobilitysolutions",
                    showCopy: false,
                    copiedMobilitySolution: undefined,
                    outletDescription: "",
                    toOutletId: 0,
                }, () => this.getData());

                DisplayMessage(resources["MobilitySolutionWasCopied"], "success");
            });
    }
    render() {
        const { redirect, fetchingData, toOutletId, outletId, dataGrid } = this.state;
        const { resources } = this.props;
        var isDisabled = toOutletId <= 0;
        if (redirect) { return <Redirect to={{ pathname: redirect, state: { outletId: outletId, mobilitySolutions: dataGrid.data, refreshGrid: true } }} /> }

        return (
            <div>
                <div className="position-relative py-4">
                    <h3>{resources["MobilitySolutions"]}</h3>
                    <div className={"offcentered-loader " + (fetchingData ? 'fadeIn' : 'fadeOut')}><CircularProgress /></div>
                    <div className={"mt-4 " + (fetchingData ? 'fadeOut' : 'fadeIn')}>
                        <Form.Group className="main-dropdown" onClick={this.handleTyping}>
                            <FormControl className="col-md-12" variant="outlined" onChange={this.handleChangeOutlet}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="outlined-outlet-native-simple">{resources["Outlet"]}</InputLabel>
                                </ThemeProvider>
                                <Select
                                    native
                                    label={resources["Outlet"]}
                                    value={this.state.outletId}
                                    inputProps={{
                                        name: 'outlet',
                                        id: 'outlined-outlet-native-simple',
                                    }}>
                                    {this.renderOutlets()}
                                </Select>
                            </FormControl>
                        </Form.Group>
                        <div style={{ float: "right", marginBottom: "10px" }}>
                            <Link to={{ pathname: `/mobilitysolutions/new`, state: { outletId: this.state.outletId } }} style={{ textDecoration: 'none' }}>
                                <Button id="add-mobilitysolution" variant="contained" color="primary">
                                    <i className="fas fa-plus mr-2" title={resources["AddNewMobilitySolution"]} />
                                    {resources["AddNewMobilitySolution"]}
                                </Button>
                            </Link >
                        </div>
                        <div style={{ clear: "both", }}></div>

                        <Paper elevation={3} className="p-3 mt-3">
                            <MuiThemeProvider theme={LocalizationTheme()}>
                                <MaterialTable
                                    icons={tableIcons}
                                    title={""}
                                    localization={{
                                        header: { actions: resources["Actions"] },
                                        body: {
                                            emptyDataSourceMessage: resources["NoRecordsToBeDisplayed"],
                                            deleteTooltip: resources["DeleteTooltip"],
                                            editTooltip: resources["EditTooltip"],
                                        },
                                        toolbar: {
                                            searchPlaceholder: resources["Search"],
                                            searchTooltip: resources["Search"]
                                        },
                                        pagination: {
                                            firstTooltip: resources["FirstTooltip"],
                                            lastTooltip: resources["LastTooltip"],

                                            previousTooltip: resources["PreviousTooltip"],
                                            nextTooltip: resources["NextTooltip"],

                                            labelRowsPerPage: resources["LabelRowsPerPage"],
                                            labelRowsSelect: resources["LabelRowsSelect"],
                                        }
                                    }}
                                    data={this.state.dataGrid.data}
                                    columns={this.state.dataGrid.columns}
                                    actions={this.state.dataGrid.actions}
                                    options={{ emptyRowsWhenPaging: false, pageSize: 10, pageSizeOptions: [10, 15, 20] }}
                                />
                            </MuiThemeProvider>
                        </Paper>
                    </div>
                    <div className={"loader-overlap position-absolute " + (this.state.fetchingData ? 'fadeIn' : 'fadeOut')}><div className="container"><Loader /></div></div>

                    <Rodal visible={this.state.showDelete} onClose={this.handleCloseDelete} className="rodal" customStyles={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                    }}>
                        <div className="modal-body" style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>{resources["AreYouSureYouWantToDeleteMobilitySolution"]}</div>
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <button className="btn btn-secondary" id="btnCancelDelete" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                                onClick={this.handleCloseDelete} disabled={this.state.requestInProgress}>{resources["Cancel"]}</button>
                            <button className="btn btn-primary" id="btnDelete" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }}
                                onClick={this.handleDeleteMobilitySolution} disabled={this.state.requestInProgress}>{resources["Yes"]}</button>
                        </div>

                        <span className="rodal-close"></span>
                    </Rodal>

                    <Rodal visible={this.state.showCopy} onClose={this.handleCloseCopy} className="rodal" style={{ height: "350px!important" }} >

                        <div className="modal-body" >{resources["CopyMobilitySolution"]}</div>
                        <Form.Group style={{ display: "flex" }} >
                            <Form.Label className="col-md-3 text-left label-mt my-auto" >{resources["From"]}:  </Form.Label>
                            <Form.Label className="col-md-8 text-left label-mt my-auto pl-0 form-label" >{this.state.outletDescription}  </Form.Label>
                        </Form.Group>

                        <Form.Group style={{ display: "flex" }}>
                            <Form.Label className="col-md-3 text-left label-mt my-auto" >{resources["To"]}:  </Form.Label>
                            <FormControl className="col-md-8 helper-text-red" variant="outlined" onChange={this.handleChangeToOutlet}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="outlined-tooutlet-native-simple">{resources["Outlet"]}</InputLabel>
                                </ThemeProvider>
                                <Select
                                    native
                                    label={resources["Outlet"]}
                                    value={this.state.toOutletId}
                                    inputProps={{
                                        name: 'outlet',
                                        id: 'outlined-tooutlet-native-simple',
                                    }}>
                                    <option value={0}>{resources["DefaultDropdownValue"]}</option>
                                    {this.renderOutlets()}
                                </Select>
                            </FormControl>
                        </Form.Group>


                        <div className="modal-footer" style={{ borderTop: "1px solid white" }}>
                            <button className="btn btn-secondary" id="btnCancelCopy" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                                onClick={this.handleCloseCopy} disabled={this.state.requestInProgress}>{resources["Cancel"]}</button>
                            <button className="btn btn-primary" id="btnCopy" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }}
                                onClick={this.handleCopyMobilitySolution} disabled={this.state.requestInProgress} disabled={isDisabled} >{resources["Save"]} </button>
                        </div>
                        <span className="rodal-close"></span>
                    </Rodal>

                </div>
            </div >
        )
    };
}