import React, { Component } from 'react';
import { Container } from 'reactstrap';
import HomeIcon from "@material-ui/icons/Home";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupIcon from '@material-ui/icons/Group';
import ScheduleIcon from '@material-ui/icons/Schedule';
import TriggersIcon from '@material-ui/icons/FlashOnOutlined';
import ChatMessagesIcon from '@material-ui/icons/ChatOutlined';
import Sidebar from "./Sidebar";
import Profile from "./Profile";
import { Forum, Link, PersonOutline, QueryBuilderOutlined, CommuteOutlined, InboxOutlined, Subject, FlagOutlined, TimerOffOutlined, ListOutlined,EventBusyOutlined,PauseCircleOutline,AccountTree, LastPage } from '@material-ui/icons';
import { CalendarTodaySharp, DateRange, LocalOffer, Payment, GroupWorkOutlined } from '@material-ui/icons';
import { Permissions } from "../utils/Permissions";
import EventBusyOutlinedIcon from '@material-ui/icons/EventBusyOutlined';
import {ReactComponent as WaitOnPremiseIcon} from '../icons/waitOnPremise.svg';
import {ReactComponent as Grading} from '../icons/grading.svg';
export class Layout extends Component {
    static displayName = Layout.name;

    getMenuItems = () => {
      const { resources } = this.props;
      var items = [
           
            "divider",
            { name: "home", label: resources["Home"], route: "/", Icon: HomeIcon },
            {
                name: "settings",
                label: resources["Settings"],
                Icon: SettingsIcon,
                items: [
                    "divider",
                    {
                        name: "general",
                        label: resources["General"],
                        items: [
                            "divider",
                            { name: "ChatGroups", label: resources["ChatGroups"], route: "/chatgroups", Icon: Forum, requiredPermissions: [Permissions.AccountManagement]  },
                            { name: "OutletUsers", label: resources["OutletUsers"], route: "/outletusers", Icon: PersonOutline, requiredPermissions: [Permissions.AccountManagement] },
                            { name: "TaskWorkflows", label: resources["TaskWorkflows"], route: "/taskworkflows", Icon: AccountTree, requiredPermissions: [Permissions.WorkshopSettings] },
                        ]
                    },
                    "divider",
                    {
                        name: "workshop",
                        label: resources["Workshop"],
                        items: [
                            "divider",
                            {
                                name: "general",
                                label: resources["General"],
                                Icon: SettingsIcon,
                                items: [
                                    "divider",                                    
                                    { name: "BookingItems", label: resources["BookingItems"], route: "/bookingitems", Icon: ListOutlined, requiredPermissions: [Permissions.WorkshopSettings] },
                                    { name: "WaitOnPremise", label: resources["WaitOnPremise"], route: "/bookingwaitslots", Icon: WaitOnPremiseIcon, requiredPermissions: [Permissions.WorkshopSettings] },                                    
                                    { name: "DateMarkers", label: resources["DateMarkers"], route: "/bookingdatemarkers", Icon: EventBusyOutlinedIcon, requiredPermissions: [Permissions.WorkshopSettings] },
                                    { name: "OpeningHours", label: resources["OpeningHours"], route: "/openinghours", Icon: QueryBuilderOutlined, requiredPermissions: [Permissions.WorkshopSettings] },
                                    { name: "MobilitySolutions", label: resources["MobilitySolutions"], route: "/mobilitysolutions", Icon: CommuteOutlined, requiredPermissions: [Permissions.WorkshopSettings] },
                                    { name: "Tags", label: resources["Tags"], route: "/tags", Icon: FlagOutlined, requiredPermissions: [Permissions.WorkshopSettings]  },
                                    { name: "DossierStacks", label: resources["DossierStacks"], route: "/dossierstacks", Icon: InboxOutlined, requiredPermissions: [Permissions.WorkshopSettings]  },
                                    { name: "PauseReasons", label: resources["PauseReasons"], route: "/pausereasons", Icon: PauseCircleOutline, requiredPermissions: [Permissions.WorkshopSettings]  },
                                    { name: "Snippets", label: resources["Snippets"], route: "/snippets", Icon: Subject, requiredPermissions: [Permissions.WorkshopSettings]  },
                                    { name: "FirstAppointmentDate", label: resources["FirstAppointmentDate"], route: "/firstappointmentdate", Icon: LastPage, requiredPermissions: [Permissions.WorkshopSettings] },
                                    /*{ name: "Disclaimers", label: resources["Disclaimers"], route: "/disclaimers", Icon: Grading, requiredPermissions: [Permissions.WorkshopSettings] },*/
                                ]
                            },

                            {
                                name: "Planning",
                                label: resources["Planning"],
                                Icon: CalendarTodaySharp,
                                items: [
                                    "divider",
                                    { name: "Teams", label: resources["Teams"], route: "/teams", Icon: GroupIcon, requiredPermissions: [Permissions.WorkshopSettings]  },
                                    { name: "PlanningIndicators", label: resources["PlanningIndicators"], route: "/indicators", Icon: LocalOffer, requiredPermissions: [Permissions.WorkshopSettings]  },
                                    { name: "Linked Indicators", label: resources["LinkedIndicators"], route: "/linkedindicators", Icon: Link, requiredPermissions: [Permissions.WorkshopSettings]  },
                                    { name: "PlanningTimetables", label: resources["PlanningTimetables"], route: "/timetables", Icon: ScheduleIcon, requiredPermissions: [Permissions.WorkshopSettings]  },
                                    { name: "Agendas", label: resources["PlanningAgendas"], route: "/agendas", Icon: DateRange, requiredPermissions: [Permissions.WorkshopSettings]  },
                                    { name: "LinkAgendas", label: resources["LinkAgendas"], route: "/linkagendas", Icon: Link, requiredPermissions: [Permissions.WorkshopSettings] },
                                    { name: "Absences", label: resources["Absences"], route: "/absences", Icon: TimerOffOutlined, requiredPermissions: [Permissions.WorkshopSettings] },
                                    { name:"Absence types", label:resources["AbsenceTypes"], route:"/absencetypes", Icon:TimerOffOutlined,requiredPermissions: [Permissions.WorkshopSettings] },
                                    { name:"Occupancy rates", label:resources["OccupancyRates"], route:"/occupancyrates", Icon:EventBusyOutlined,requiredPermissions: [Permissions.WorkshopSettings] }
                                
                                ]
                            },
                            {
                                name: "Work Preparation",
                                label: resources["WorkPreparation"],
                                Icon: GroupWorkOutlined,
                                route: "/workpreparations",
                                requiredPermissions : [Permissions.AccountManagement, Permissions.WorkshopSettings ]
                            },
                            {
                                name: "triggers",
                                label: resources["Triggers"],
                                Icon: TriggersIcon,
                                requiredPermissions : [Permissions.WorkshopSettings ],
                                items: [
                                    "divider",
                                    { name: "ChatMessages", label: resources["ChatMessages"], route: "/chatmessages", Icon: ChatMessagesIcon, requiredPermissions: [Permissions.WorkshopSettings]  },
                                ]
                            },
                        ]
                    },
             
                    "divider",
                    {
                        name: "payments",
                        label: resources["Payments"],
                        items: [
                            "divider",
                            { name: "Connections", label: resources["Connections"], route: "/paymentconnections", Icon: Payment, requiredPermissions: [Permissions.Payments] }
                        ]
                    }
                ]
            }
        ];

        return items;
    };

    removeItemsWithRequiredPermissionId = (items, permissionId) => {
        //Filter items that have the required permission
        items = items.filter(item => item.requiredPermissions === undefined || item.requiredPermissions.indexOf(permissionId) < 0 || item.requiredPermissions.length > 1);

        //Remove permission from items that have multiple permissions
        items.forEach(item => {
            if (item.requiredPermissions && item.requiredPermissions.indexOf(permissionId) > -1) {
                item.requiredPermissions = item.requiredPermissions.filter(rp => rp !== permissionId);
            }
        });

        //Recursive - apply the filter to the sub-menu items
        items.forEach(item => {
            if (item.items && item.items.length > 0) {
                item.items = this.removeItemsWithRequiredPermissionId(item.items, permissionId);
            }
        });

        //Remove entries that have only the divider
        items = items.filter(item => !(item.items && item.items.length == 1 && item.items[0] === "divider"));

        //Remove duplicate consecutive dividers
        items = items.filter((item, index, array) => index === 0 || item !== array[index - 1]);

        return items;
    };

    render() {
        let existsPermissions = this.props.currentIdentity.permissions !== null;

        var items = this.getMenuItems();

        if (existsPermissions === true) {
            var existsWorkShopPermission = false;
            var hasAccessToPayments = false;
            var hasAccessToAccountManagement = false;

            this.props.currentIdentity.permissions.forEach(element => {
                if (element.id === Permissions.WorkshopSettings) {
                    existsWorkShopPermission = true;
                }
                else if (element.id == Permissions.Payments) {
                    hasAccessToPayments = true;
                }
                else if (element.id == Permissions.AccountManagement) {
                    hasAccessToAccountManagement = true;
                }
            });

            if (existsWorkShopPermission === false) {
                items = this.removeItemsWithRequiredPermissionId(items, Permissions.WorkshopSettings);
            }

            if (hasAccessToPayments === false) {
                items = this.removeItemsWithRequiredPermissionId(items, Permissions.Payments);
            }

            if (hasAccessToAccountManagement === false) {
                items = this.removeItemsWithRequiredPermissionId(items, Permissions.AccountManagement);
            }

        }

        return (
            <div>
                <Sidebar items={items} currentIdentity={this.props.currentIdentity}>
                    <Profile currentIdentity={this.props.currentIdentity}
                        logoutCallback={this.props.logoutCallback}
                        resources={this.props.resources}
                    />
                </Sidebar>
                < div className="main-container" >
                    <Container >
                        {this.props.children}
                    </Container>
                </div>
            </div>
        );
    }
}
