import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import 'toastr/build/toastr.min.css';
import { DisplayMessage } from '../../../services/helpers.js';
import DelayedRedirect from '../../../utils/DelayedRedirect';
import { Transition } from '../../../utils/ModalHelpers';

import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import 'toastr/build/toastr.min.css';
import Select from '@material-ui/core/Select';

import { SelectTheme } from '../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";

export default class ChatGroupsCreation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            outletId: props.selectedOutletId,

            description: "",
            internalChatName: "",

            isActive: false,

            selectedOutletId: 0,
            loading: this.props.editedChatGroupId !== undefined,
            redirect: false,
            accessibleMoboOutlets: [],
            editedChatGroupId: this.props.editedChatGroupId,
            requestInProgress: false,
            redirect: null,
            open: true,
            ChatGroupWasCreated: false,           
        };
    }

    componentDidMount() {
        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;
        var outlet = outlets[0].id;

        if (this.props.outletId !== undefined) {
            this.setState({ outletId: this.props.outletId });
        }
        else {
            this.setState({ outletId: outlet });
        }
    }

    handleClose = () => {
        this.setState({ open: false, redirect: "/chatgroups" });
    };



    handleChange = e => {
        if (this.state.outletId === 0) {
            var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
            this.setState({ [e.target.name]: e.target.value, outletId: outlets[0].id, requestInProgress: false });
            return;
        }

        if (e.target.name === "internalChatName") {
            this.setState({ [e.target.name]: e.target.value.replace(/ /g, ''), requestInProgress: false });
            return;
        }

        this.setState({ [e.target.name]: e.target.value, requestInProgress: false });
    };

    handleChangeCheckBox = e => {
        this.setState({ [e.target.name]: e.target.checked, requestInProgress: false });
    };


    handleChangeOutlet = e => {
        this.setState({ outletId: e.target.value, requestInProgress: false });
    };


    handleSubmit = () => {
        this.saveNewChatGroup();
    }

    saveNewChatGroup = () => {
        this.setState({ requestInProgress: true });

        let resources = this.props.resources;
        if (this.state.description.trim().length <= 0) {
            DisplayMessage(resources["InvalidDescripton"], "error");
            return;
        }

        if (this.state.internalChatName.trim().length <= 0) {
            DisplayMessage(resources["InvalidChatName"], "error");
            return;
        }

        var json = JSON.stringify({
            moboOutletId: this.state.outletId !== "0" ? this.state.outletId : null,
            description: this.state.description != "" ? this.state.description : null,
            internalChatName: this.state.internalChatName != "" ? this.state.internalChatName : null,
            isActive: this.state.isActive,
        });

        const url = "/api/mobopro/v1/internalchatgroups";
        fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {

            if (!response.ok) {
                response.json().then(result => {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                });

            }
            else {
                DisplayMessage(resources["NewChatGroupWasSaved"], "success");
                this.setState({ ChatGroupWasCreated: true, redirect: "/chatgroups" });
            }
        });
    }

    renderOutlets = () => {

        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;

        var outlets = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outlets;
    }

    renderModifiedByInfo = () => {
        let resources = this.props.resources;
        if (this.state.id > 0) {
            return (<Form.Group style={{ display: "flex" }}>
                <div className="w-100">
                    <span className=" mr-2">
                        <img className="rounded-circle" alt="user-avatar" src={this.state.modifiedByAvatar != null ? this.state.modifiedByAvatar : "/static/media/defaultavatar.02624da3.png"} width="35" />
                    </span>
                    <span>
                        {`${resources["ModifiedOn"]} ${moment(this.state.modifiedOn).format('DD/MM/YYYY HH:mm').toLocaleString()} ${resources["By"]} ${this.state.modifiedByName}`}
                    </span>
                </div>
            </Form.Group>);
        }

        return;
    }

    renderForm() {
        const handleSubmit = (event) => {
            event.preventDefault();
            event.stopPropagation();
            this.handleSubmit();
        };
        const { isActive, description, internalChatName } = this.state;

        var resources = this.props.resources;
        var helperTextDescription = description.trim().length <= 0 ? "helper-text-red": "helper-text-grey";
        var helperTextChatName = internalChatName === "" ? "helper-text-red" : "helper-text-grey";

        return (
            <Paper elevation={3} className="p-4 mt-3 mb-3">
                <Form onSubmit={handleSubmit} >
                    <Form.Group className="pt-3 d-flex"  >
                        <div className="col-3"></div>
                        <ThemeProvider theme={SelectTheme}>
                            <TextField className={"col-6 " + helperTextDescription} id="chatgroup-description" value={description} label={resources["Description"]}
                                helperText={resources["InputOfDescriptionIsMandatory"]}
                                variant="outlined" name="description" onChange={this.handleChange} inputProps={{ maxLength: 100 }} />
                        </ThemeProvider >
                    </Form.Group>

                    <Form.Group className="d-flex"  >
                        <div className="col-3"></div>
                        <ThemeProvider theme={SelectTheme}>
                            <TextField className={"col-6 " + helperTextChatName} id="chatgroup-chatname" value={internalChatName} label={resources["ChatName"]}
                                helperText={resources["InputOfChatNameIsMandatory"]}
                                variant="outlined" name="internalChatName" onChange={this.handleChange} inputProps={{ maxLength: 20 }} />
                        </ThemeProvider >
                    </Form.Group>
                    <Form.Group className="d-flex" >
                        <div className="col-3"></div>
                        <FormControl className="col-6 helper-text-red" variant="outlined" onChange={this.handleChangeOutlet}>
                            <ThemeProvider theme={SelectTheme}>
                                <InputLabel htmlFor="outlined-outlet-native-simple">{resources["Outlet"]}</InputLabel>
                            </ThemeProvider >
                            <Select
                                id="chatgroup-outlet"
                                native
                                label={resources["Outlet"]}
                                value={this.state.outletId}
                                inputProps={{
                                    name: 'outlet',
                                    id: 'outlined-outlet-native-simple',
                                }}>
                                <option value={0}>{resources["DefaultDropdownValue"]}</option>
                                {this.renderOutlets()}
                            </Select>
                        </FormControl>
                    </Form.Group>
                    <Form.Group style={{ display: "flex" }} >
                        <div className="col-md-3 "> </div>
                        <FormControlLabel className="col-md-6 text-right label-mt my-auto p-0"
                            control={<Checkbox id="chatgroup-is-active" checked={isActive} onChange={this.handleChangeCheckBox} name="isActive" color="primary" />}
                            label={resources["IsActive"]} />
                        <div className="col-md-4"> </div>
                    </Form.Group>
                </Form>
            </Paper>
        );
    }

    renderMain() {
        const { loading } = this.state;

        if (loading) return (<div className="centered-loader"><CircularProgress /></div>);

        return <div className={"py-4 grey " + (this.state.loading ? 'fadeOut' : 'fadeIn')} style={{ textAlign: "center" }}>
            {this.renderForm()}
        </div>;
    }

    render() {
        const { redirect, open, ChatGroupWasCreated, outletId, requestInProgress } = this.state;
        const { resources } = this.props;

        return (
            <div>
                {redirect && <DelayedRedirect to={{ pathname: redirect, state: { refreshGrid: ChatGroupWasCreated, outletId } }} delay={250} />}
                <Dialog fullScreen open={open} onClose={this.handleClose} TransitionComponent={Transition}>
                    <AppBar className="position-sticky">
                        <Toolbar>
                            <IconButton id="close-add-chatgroup" edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flex: 1 }}>
                                {resources["AddNewChatGroupTitle"]}
                            </Typography>
                            <Button id="chatgroup-save" color="inherit" disabled={requestInProgress} onClick={this.handleSubmit}>{resources["Save"]}</Button>
                        </Toolbar>
                    </AppBar>
                    <div className="container">
                        {this.renderMain()}
                    </div>
                </Dialog>
            </div >
        );
    }
}