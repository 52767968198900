import { createTheme } from '@material-ui/core/styles';

export const LocalizationTheme = () => createTheme({
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiIcon: {
      root: {
        fontSize: "1rem"
      }
    },
    MuiTable: {
      root: {
        width: "98%",
        margin: "auto"
      }
    },
    MuiTableCell: {
      head: {
        fontWeight: "600"
      }
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottomColor: "#53B3EA !important",
        }
      }
    }
  }
})

export const SelectTheme = () => createTheme({
  palette: {
    primary: {
      main: "#53B3EA",
    },
    error: {
      main: "#C4455B",
    }
  },
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiInputBase: {
      root: {
        backgroundColor: "white",
        "& .fontSizeOverride": {
          fontSize: "14px !important",
        },
      },
    },
    MuiFormGroup: {
      root: {
        paddingLeft: "0px"
      }
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#53B3EA"
        }
      },
    },
    MuiOutlinedInput: {
      root: {
        "&$focused": {
          notchedOutline: {
            borderColor: "#53B3EA !important",
          },
        }
      },
      multiline: {
        padding: '0 !important',
      },
      input: {
        padding: "9px 14px !important"
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 11px) scale(1)"
      }
    },
  }
});

export const DatePickerTheme = () => createTheme({
  MuiInput: {
    root: {
      "& input::-webkit-clear-button": {
        display: "none"
      }
    }
  }
});