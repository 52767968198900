import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import 'toastr/build/toastr.min.css';
import { DisplayMessage } from '../../../../services/helpers.js';
import DelayedRedirect from '../../../../utils/DelayedRedirect';
import { Transition } from '../../../../utils/ModalHelpers';

import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import 'toastr/build/toastr.min.css';

import { SelectTheme } from '../../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";


export default class AbsenceTypeEdit extends Component {
    constructor(props) {
        super(props);

        var absenceTypeId = undefined;

        if (this.props !== undefined) {
            absenceTypeId = this.props.absenceTypeId;
        }

        this.state = {
            id: absenceTypeId,
            description: "",
            isActive: false,

            modifiedOn: "",
            modifiedByName: "",
            modifiedByAvatar: "",

            redirect: false,
            editedAbsenceId: absenceTypeId,
            requestInProgress: false,
            redirect: null,
            open: true,
            absenceTypeWasCreated:false,

        };
    }


    componentDidMount() {
        if (this.props.location !== undefined && this.props.location.state !== undefined && this.props.location.state.editedAbsenceType !== undefined) {
            this.getEditedAbseceTypeDetails(this.props.location.state.editedAbsenceType);
        } else {
            if (this.state.editedAbsenceId !== undefined) {
                this.getDataById();
            }
        }
        this.setState({ redirect: null });
    }

    getEditedAbseceTypeDetails = (absenceTypeItem) => {
        this.setState({
        id: absenceTypeItem.id,
        description: absenceTypeItem.description,
        isActive: absenceTypeItem.isActive,
        modifiedOn: absenceTypeItem.modifiedOn,
        modifiedByName: absenceTypeItem.modifiedBy["firstName"] + " " + absenceTypeItem.modifiedBy["lastName"],
        modifiedByAvatar: absenceTypeItem.modifiedBy["profilePicture"],
    });
}


    getDataById = () => {
        fetch(`/api/mobopro/v1/absencetypes`,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {
                if (!response.ok) {
                    response.json().then(data => {
                        for (var i = 0; i < data.errors.length; i++) {
                            DisplayMessage(data.errors[i], "error");
                        }
                    })
                } else {
                    response.json().then(result => {
                        let absenceTypeItem = [];
                        let editedAbsenceId = parseInt(this.state.editedAbsenceId);
                        result.forEach(function (rowData) {
                            if (rowData.id === editedAbsenceId) {
                                absenceTypeItem = rowData;
                            }
                        });

                        this.setState({
                            id: editedAbsenceId,
                            description: absenceTypeItem.description,
                            isActive: absenceTypeItem.isActive,
                            modifiedOn: absenceTypeItem.modifiedOn,
                            modifiedByName: absenceTypeItem.modifiedBy["firstName"] + " " + absenceTypeItem.modifiedBy["lastName"],
                            modifiedByAvatar: absenceTypeItem.modifiedBy["profilePicture"],
                        });

                        return;
                    });
                }
            });
    };

    handleClose = () => {
      this.setState({ open: false, redirect: "/absencetypes" });
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleChangeCheckBox = e => {
        this.setState({ [e.target.name]: e.target.checked });
    };


    handleTyping = e => {
        this.setState({ requestInProgress: false });
    };
    handleSubmit = () => {
        this.saveEditedAbsenceType();
    }

    saveEditedAbsenceType = () => {
        this.setState({ requestInProgress: true });
        let resources = this.props.resources;

        if ((this.state.description.length > 0 && this.state.description.trim().length <= 0) || this.state.description.trim().length > 100) {
            DisplayMessage(resources["InvalidDescripton"], "error");
            return;
        }

        var bodyJson = {};

        if (this.state.description !== "") bodyJson.description = this.state.description;
        bodyJson.isActive = this.state.isActive;

        var json = JSON.stringify(
            bodyJson
        );

        const url = "/api/mobopro/v1/absencetypes/" + this.state.id;
        fetch(url, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        })
            .then(response => {
                response.json().then(result => {
                    if (!response.ok) {
                        for (var i = 0; i < result.errors.length; i++) {
                            DisplayMessage(result.errors[i], "error");
                        }
                    }
                    else {
                        if (result.id !== undefined) {
                            this.setState({
                                id: result.id,
                                description: result.description,
                                isActive: result.isActive,
                                absenceTypeWasCreated: true,
                                requestInProgress:false,
                                redirect: "/absencetypes"
                            });

                            DisplayMessage(resources["AbsenceTypeEdited"], "success");
                        }
                    }
                    this.setState({ requestInProgress: false });
                })
            });
    }


    renderModifiedByInfo = () => {
        let resources = this.props.resources;
        if (this.state.id > 0) {
            return (<Form.Group className="d-flex">
                <div className="w-100">
                    <span className=" mr-2">
                        <img className="rounded-circle" alt="user-avatar" src={this.state.modifiedByAvatar != null ? this.state.modifiedByAvatar : "/static/media/defaultavatar.02624da3.png"} width="35" />
                    </span>
                    <span>
                        {`${resources["ModifiedOn"]} ${moment(this.state.modifiedOn).format('DD/MM/YYYY HH:mm').toLocaleString()} ${resources["By"]} ${this.state.modifiedByName}`}
                    </span>
                </div>
            </Form.Group>);
        }
        return;
    }

    renderForm() {
        const handleSubmit = (event) => {
            const form = event.currentTarget;
            event.preventDefault();
            event.stopPropagation();
        };

        const { isActive, description } = this.state;
        var resources = this.props.resources;

        return (
            <Paper elevation={3} className="p-4 mt-3 mb-3">
                <Form onSubmit={handleSubmit} >
                    <Form.Group className="pt-3" style={{ display: "flex" }} onClick={this.handleTyping}>
                        <div className="col-md-3"></div>
                        <ThemeProvider theme={SelectTheme}>
                            <TextField className="col-md-6 helper-text-red" id="absencetype-description" value={description} label={resources["Description"]}
                                variant="outlined" name="description" onChange={this.handleChange} inputProps={{ maxLength: 100 }} />
                        </ThemeProvider>
                    </Form.Group>

                    <Form.Group style={{ display: "flex" }} >
                        <div className="col-md-3 "> </div>
                        <FormControlLabel className="col-md-6 text-right label-mt my-auto p-0"
                            control={<Checkbox id="absencetype-is-active" checked={isActive} onChange={this.handleChangeCheckBox} name="isActive" color="primary" />}
                            label={resources["IsActive"]} />
                        <div className="col-md-4"> </div>
                    </Form.Group>

                    {this.renderModifiedByInfo()}
                </Form>
            </Paper>
        );
    }

    renderMain() {
        const { loading } = this.state;

        if (loading) return (<div className="centered-loader"><CircularProgress /></div>);

        return <div className={"py-4 grey " + (this.state.loading ? 'fadeOut' : 'fadeIn')} style={{ textAlign: "center" }}>
            {this.renderForm()}
        </div>;
    }

    render() {
        const { open, redirect, absenceTypeWasCreated} = this.state;
        const { resources } = this.props;

        return (
            <div>
                {redirect && <DelayedRedirect  to={{ pathname: redirect, state: { refreshGrid: absenceTypeWasCreated} }} delay={250} />}
                <Dialog fullScreen open={open} onClose={this.handleClose} TransitionComponent={Transition}>
                    <AppBar className="position-sticky">
                        <Toolbar>
                            <IconButton id="close-edit-absencetype" edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flex: 1 }}>
                                {resources["Edit"]}
                            </Typography>
                            <Button id="absencetype-save" color="inherit" onClick={this.handleSubmit}>{resources["Save"]}</Button>
                        </Toolbar>
                    </AppBar>
                    <div className="container">
                        {this.renderMain()}
                    </div>
                </Dialog>
            </div >
        );
    }
}