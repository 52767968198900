import React from 'react';
import { Route, Switch } from 'react-router';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import IndicatorsTable from '../components/WorkshopPlanning/Planning/Indicators/IndicatorsTable';
import IndicatorsCreation from '../components/WorkshopPlanning/Planning/Indicators/IndicatorsCreation';
import IndicatorsEditing from '../components/WorkshopPlanning/Planning/Indicators/IndicatorsEditing';

import { Permissions } from '../utils/Permissions';

export default function IndicatorsRouting(props) {

    const { currentIdentity, resources } = props;

    return (
        <>
            <RestrictedRoute path='/indicators' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.WorkshopSettings]} resources={resources} render={(props) => (<IndicatorsTable resources={resources} refreshGrid={props && props.location && props.location.state && props.location.state.refreshGrid} outletId={props && props.location && props.location.state && props.location.state.outletId} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} />)} />
            <Switch>
                <Route exact path='/indicators/new' render={(props) => (<IndicatorsCreation resources={resources} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} outletId={props && props.location && props.location.state && props.location.state.outletId} />)} />
                <Route exact path='/indicators/:indicatorId' render={(props) => {
                    return (<IndicatorsEditing resources={resources} indicatorId={props.match.params.indicatorId} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} />)
                }} />
            </Switch>
        </>
    )
}