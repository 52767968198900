import React from 'react';
import { Route, Switch } from 'react-router';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import TimetablesGrid from '../components/WorkshopPlanning/Planning/Timetables/TimetablesGrid';
import TimetableCreation from '../components/WorkshopPlanning/Planning/Timetables/TimetableCreation';
import TimetableEditing from '../components/WorkshopPlanning/Planning/Timetables/TimetableEditing';
import { Permissions } from '../utils/Permissions';

export default function TimetablesRouting(props) {
    const { currentIdentity, resources } = props;
    return (
        <>
           <RestrictedRoute path='/timetables' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.WorkshopSettings]} resources={resources} render={(props) => {
                return (<TimetablesGrid resources={resources} refreshGrid={props && props.location && props.location.state && props.location.state.refreshGrid} />)
            }} />
            <Switch>
                <Route exact path='/timetables/new' render={(props) => (<TimetableCreation resources={resources} />)} />
                <Route exact path='/timetables/:timetableId' render={(props) => {
                    return (<TimetableEditing resources={resources} timetableId={props.match.params.timetableId} />)
                }} />
            </Switch>
        </>
    )
}