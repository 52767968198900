import React from 'react';
import { Route, Switch } from 'react-router';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import AbsencesTable from '../components/WorkshopPlanning/Planning/Absences/AbsencesTable.js';
import AbsenceNew from '../components/WorkshopPlanning/Planning/Absences/AbsenceNew.js';
import { Permissions } from '../utils/Permissions';

export default function AbsencesRouting(props) {
    const { currentIdentity, resources } = props;
    return (
        <>
            <RestrictedRoute path='/absences' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.WorkshopSettings]} resources={resources} render={(props) => {
                return (<AbsencesTable resources={resources} refreshGrid={props && props.location && props.location.state && props.location.state.refreshGrid} language={currentIdentity.culture.substring(0, 2)} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} />)
            }} />
            <Switch>
                <Route exact path='/absences/new' render={(props) => (<AbsenceNew resources={resources} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} outletId={props && props.location && props.location.state && props.location.state.outletId} {...props} />)} />
            </Switch>
        </>
    )
}