import React from "react";
import { Route, Switch } from "react-router";
import { RestrictedRoute } from "../utils/RestrictedRoute";
import DossierStacksTable from "../components/WorkshopPlanning/General/DossierStacks/DossierStacksTable";
import DossierStackCreation from "../components/WorkshopPlanning/General/DossierStacks/DossierStackCreation";
import { Permissions } from "../utils/Permissions";

export default function DossierStacksRouting(props) {
  const { currentIdentity, resources } = props;
  return (
    <>
      <RestrictedRoute
        path="/dossierstacks"
        userPermissions={currentIdentity.permissions}
        routePermissions={[Permissions.WorkshopSettings]}
        resources={resources}
        render={(props) => {
          return (
            <DossierStacksTable
              resources={resources}
              accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets}
              refreshGrid={
                props &&
                props.location &&
                props.location.state &&
                props.location.state.refreshGrid
              }
              dossierStackWasCreated={
                props &&
                props.location &&
                props.location.state &&
                props.location.state.dossierStackWasCreated
              }
              outletId={
                props &&
                props.location &&
                props.location.state &&
                props.location.state.outletId
              }
            />
          );
        }}
      />
      <Switch>
        <Route
          exact
          path="/dossierstacks/new"
          render={(props) => (
            <DossierStackCreation
              resources={resources}
              accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets}
              outletId={
                props &&
                props.location &&
                props.location.state &&
                props.location.state.outletId
              }
              dossierStackId={
                props &&
                props.location &&
                props.location.state &&
                props.location.state.dossierStackId
              }
            />
          )}
        />
        <Route
          exact
          path="/dossierstacks/:dossierStackId"
          render={(props) => {
            return (
              <DossierStackCreation
                resources={resources}
                dossierStackId={props.match.params.dossierStackId}
                editedDossierStack={props.match.params.editedDossierStack}
                accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets}
                {...props}
              />
            );
          }}
        />
      </Switch>
    </>
  );
}
