import React from 'react';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import FirstAppointmentDateTable from '../components/General/FirstAppointmentDate/FirstAppointmentDateTable';
import { Permissions } from '../utils/Permissions';

export default function FirstAppointmentDateRouting(props) {
  const { currentIdentity, resources } = props;
  return (
    <>
      <RestrictedRoute path='/firstappointmentdate' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.WorkshopSettings]} resources={resources} render={(props) => {
        return (<FirstAppointmentDateTable resources={resources} refreshGrid={props && props.location && props.location.state && props.location.state.refreshGrid} />)
      }} />
    </>
  )
}