import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import 'toastr/build/toastr.min.css';
import { DisplayMessage, ExcludeSpecialCharactersFromInputTypeNumber } from '../../../../services/helpers.js';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment';


import 'toastr/build/toastr.min.css';
import Select from '@material-ui/core/Select';

import { SelectTheme } from '../../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";
import { Button } from 'react-bootstrap';
import Delete from '@material-ui/icons/Delete';
import Rodal from 'rodal';

import { MuiThemeProvider } from '@material-ui/core/styles';



export default class OccupancyRates extends Component {
    constructor(props) {
        super(props);

        var outlets = this.props.accessibleMoboOutlets.filter(x => x.hasWorkshopPlanning === true || x.hasWorkshopPlanningLight === true).sort((a, b) => (a.description > b.description) ? 1 : -1)

        this.state = {
            id: 0,
            outletId: outlets[0].id,

            modifiedByAvatar: "",
            modifiedByName: "",
            modifiedOn: "",

            loading: true,
            requestInProgress: true,
            showDelete: false,

            internalRateOrange: undefined,
            internalRateRed: undefined,
            externalRateOrange: undefined,
            externalRateRed: undefined,

        };
    }

    componentDidMount() {
        this.getDetails();

    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value, requestInProgress: !this.checkIfRequestIsValid(false) });
    };

    handleChangeOutlet = e => {
        this.setState({ outletId: parseInt(e.target.value), requestInProgress: !this.checkIfRequestIsValid(false) }, () => this.getDetails());
    };

    checkIfRequestIsValid() {
        let resources = this.props.resources;
        var isValidRequest = true;

        if (this.state.outletId < 0) {
            DisplayMessage(resources["InvalidOutlet"], "error");
            return;
        }
        if (this.state.internalRateRed.length > 9) {
            DisplayMessage(resources["InvalidInternalRateRed"], "error");
            return;
        }
        if (this.state.externalRateRed.length > 9) {
            DisplayMessage(resources["InvalidExternalRateRed"], "error");
            return;
        }
        if (this.state.externalRateOrange.length > 9) {
            DisplayMessage(resources["InvalidExternalRateOrange"], "error");
            return;
        }
        if (this.state.internalRateOrange.length > 9) {
            DisplayMessage(resources["InvalidInternalRateOrange"], "error");
            return;
        }
        if (this.state.internalRateRed <= 0 || this.state.internalRateRed === "") {
            isValidRequest = false;
        }
        if (this.state.externalRateRed <= 0 || this.state.externalRateRed === "") {
            isValidRequest = false;
        }
        if (this.state.externalRateOrange <= 0 || this.state.externalRateOrange === "") {
            isValidRequest = false;
        }
        if (this.state.internalRateOrange <= 0 || this.state.internalRateOrange === "") {
            isValidRequest = false;
        }
        return isValidRequest;
    }

    checkIfDeleteIsValid() {
        let resources = this.props.resources;
        var isValidRequest = true;

        if (this.state.outletId < 0) {
            DisplayMessage(resources["InvalidOutlet"], "error");
            return;
        }

        return isValidRequest;
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value, requestInProgress: false });
    };

    updateRate = () => {
        this.setState({ requestInProgress: true });

        let resources = this.props.resources;

        if (!this.checkIfRequestIsValid()) return;

        var json = JSON.stringify({
            internalOrange: this.state.internalRateOrange,
            internalRed: this.state.internalRateRed,
            externalOrange: this.state.externalRateOrange,
            externalRed: this.state.externalRateRed,
        });

        var url = "/api/mobopro/v1/workshopavailabilityratios/" + this.state.id;
        fetch(url, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {
            if (!response.ok) {
                response.json().then(data => {
                    for (var i = 0; i < data.errors.length; i++) {
                        DisplayMessage(data.errors[i], "error");
                    }
                });
            }
            else {
                DisplayMessage(resources["OccupancyRateWasUpdated"], "success");
                response.json().then(data => {
                    this.setState({
                        id: data.id,
                        internalRateOrange: data.internalOrange,
                        internalRateRed: data.internalRed,
                        externalRateOrange: data.externalOrange,
                        externalRateRed: data.externalRed,
                        modifiedOn: data.modifiedOn,
                        modifiedByAvatar: data.modifiedBy.avatar,
                        modifiedByName: data.modifiedBy.firstName + " " + data.modifiedBy.lastName,
                        loading: false,
                        requestInProgress: false,
                    })
                });
            }
        });
    }

    saveNewRate = () => {
        this.setState({ requestInProgress: true });

        let resources = this.props.resources;

        if (!this.checkIfRequestIsValid()) return;

        var json = JSON.stringify({
            moboOutletId: this.state.outletId,
            internalOrange: this.state.internalRateOrange,
            internalRed: this.state.internalRateRed,
            externalOrange: this.state.externalRateOrange,
            externalRed: this.state.externalRateRed,
        });

        fetch('/api/mobopro/v1/workshopavailabilityratios', {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {
            if (!response.ok) {
                response.json().then(data => {
                    for (var i = 0; i < data.errors.length; i++) {
                        DisplayMessage(data.errors[i], "error");
                    }
                });
            }
            else {
                DisplayMessage(resources["NewOccupancyRateSaved"], "success");
                response.json().then(data => {
                    this.setState({
                        id: data.id,
                        internalRateOrange: data.internalOrange,
                        internalRateRed: data.internalRed,
                        externalRateOrange: data.externalOrange,
                        externalRateRed: data.externalRed,
                        modifiedOn: data.modifiedOn,
                        modifiedByAvatar: data.modifiedBy.avatar,
                        modifiedByName: data.modifiedBy.firstName + " " + data.modifiedBy.lastName,
                        loading: false,
                        requestInProgress: false,
                    })
                });

            }

        });

    }

    deleteRate = () => {
        this.setState({ requestInProgress: true });

        let resources = this.props.resources;

        if (!this.checkIfDeleteIsValid()) return;

        var url = "/api/mobopro/v1/workshopavailabilityratios/" + this.state.id;
        fetch(url, {
            method: 'DELETE',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),

        }).then(response => {
            if (!response.ok) {
                response.json().then(data => {
                    for (var i = 0; i < data.errors.length; i++) {
                        DisplayMessage(data.errors[i], "error");
                    }
                });
            }
            else {
                DisplayMessage(resources["OccupancyRateWasDeleted"], "success");
                this.setState({
                    id: 0,
                    internalRateOrange: "",
                    internalRateRed: "",
                    externalRateOrange: "",
                    externalRateRed: "",
                    showDelete: false,
                });
            }
        });
    }


    getDetails = () => {
        const { outletId } = this.state;

        let url = `/api/mobopro/v1/workshopavailabilityratios?moid=` + outletId;
        fetch(url,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {
                if (!response.ok) {
                    response.json().then(data => {
                        for (var i = 0; i < data.errors.length; i++) {
                            DisplayMessage(data.errors[i], "error");
                        }
                    });

                    this.setState({
                        id: 0,
                        internalRateOrange: "",
                        internalRateRed: "",
                        externalRateOrange: "",
                        externalRateRed: "",
                        loading: false,
                    });

                }
                else {
                    response.json().then(row => {

                        this.setState({
                            id: row.id,
                            internalRateOrange: row.internalOrange,
                            internalRateRed: row.internalRed,
                            externalRateOrange: row.externalOrange,
                            externalRateRed: row.externalRed,
                            modifiedOn: row.modifiedOn,
                            modifiedByAvatar: row.modifiedBy.avatar,
                            modifiedByName: row.modifiedBy.firstName + " " + row.modifiedBy.lastName,
                            loading: false,
                            requestInProgress: false,
                        });

                    });
                }

            });
    }


    handleCloseDelete = () => {
        this.setState({ showDelete: false, });
    }
    handleOpen = () => {
        this.setState({ showDelete: true, });
    }


    renderOutlets = () => {

        var outlets = this.props.accessibleMoboOutlets.filter(x => x.hasWorkshopPlanning === true || x.hasWorkshopPlanningLight === true).sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;

        var outletOptions = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outletOptions;
    }

    renderModifiedByInfo = () => {
        let resources = this.props.resources;
        const { modifiedOn, modifiedByName, modifiedByAvatar } = this.state;

        if (this.state.id > 0) {
            return (<Form.Group style={{ display: "flex" }}>
                <div className="w-100">
                    <span className=" mr-2">
                        <img className="rounded-circle" alt="user-avatar" src={modifiedByAvatar != null ? modifiedByAvatar : "/static/media/defaultavatar.02624da3.png"} width="35" />
                    </span>
                    <span>
                        {`${resources["ModifiedOn"]} ${moment(modifiedOn).format('DD/MM/YYYY HH:mm').toLocaleString()} ${resources["By"]} ${modifiedByName}`}
                    </span>
                </div>
            </Form.Group>);
        }

        return;
    }

    renderForm() {
        const handleSubmit = (event) => {
            const form = event.currentTarget;
            event.preventDefault();
            event.stopPropagation();

        };
        const { outletId, internalRateOrange, internalRateRed, externalRateOrange, externalRateRed } = this.state;

        var resources = this.props.resources;
        var helperTextInternalRateOrange = internalRateOrange <= 0 ? "helper-text-red" : "helper-text-grey";
        var helperTextInternalRateRed = internalRateRed <= 0 ? "helper-text-red" : "helper-text-grey";
        var helperTextExternalRateOrange = externalRateOrange <= 0 ? "helper-text-red" : "helper-text-grey";
        var helperTextExternalRateRed = externalRateRed <= 0 ? "helper-text-red" : "helper-text-grey";

        return (

            <Paper elevation={3} className="p-4 mt-3 mb-3">
                <MuiThemeProvider theme={SelectTheme()}>
                    <Form onSubmit={handleSubmit} >
                        <Form.Group className="d-flex" >
                            <div className="col-3"></div>
                            <FormControl className="col-6 helper-text-red" variant="outlined" onChange={this.handleChangeOutlet}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="outlined-outlet-native-simple">{resources["Outlet"]}</InputLabel>
                                </ThemeProvider >
                                <Select
                                    id="rates-outlets-dropdown"
                                    native
                                    label={resources["Outlet"]}
                                    value={outletId != undefined ? outletId : 0}
                                    inputProps={{
                                        name: 'outlet',
                                        id: 'outlined-outlet-native-simple',
                                    }}>
                                    {this.renderOutlets()}
                                </Select>
                            </FormControl>
                        </Form.Group>
                        <br></br>
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className={"col-6 " + helperTextInternalRateOrange} id="internalRateOrange"
                                    value={internalRateOrange} label={resources["InternalRateOrange"]}
                                    variant="outlined" name="internalRateOrange" onChange={this.handleChange} type="number"
                                    onKeyDown={ExcludeSpecialCharactersFromInputTypeNumber(true)}
                                    helperText={resources["InputOfInternalRateOrangeIsMandatory"]}
                                    InputProps={{ inputProps: { min: 1 } }} />
                            </ThemeProvider >
                        </Form.Group>

                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className={"col-6 " + helperTextInternalRateRed} id="internalRateRed"
                                    value={internalRateRed} label={resources["InternalRateRed"]}
                                    variant="outlined" name="internalRateRed" onChange={this.handleChange} type="number"
                                    onKeyDown={ExcludeSpecialCharactersFromInputTypeNumber(true)}
                                    helperText={resources["InputOfInternalRateRedIsMandatory"]}
                                    InputProps={{ inputProps: { min: 1 } }} />
                            </ThemeProvider >
                        </Form.Group>

                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className={"col-6 " + helperTextExternalRateOrange} id="externalRateOrange"
                                    value={externalRateOrange} label={resources["ExternalRateOrange"]}
                                    variant="outlined" name="externalRateOrange" onChange={this.handleChange} type="number"
                                    onKeyDown={ExcludeSpecialCharactersFromInputTypeNumber(true)}
                                    helperText={resources["InputOfExternalRateOrangeIsMandatory"]}
                                    InputProps={{ inputProps: { min: 1 } }} />
                            </ThemeProvider >
                        </Form.Group>

                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className={"col-6 " + helperTextExternalRateRed} id="externalRateRed"
                                    value={externalRateRed} label={resources["ExternalRateRed"]}
                                    variant="outlined" name="externalRateRed" onChange={this.handleChange} type="number"
                                    onKeyDown={ExcludeSpecialCharactersFromInputTypeNumber(true)}
                                    helperText={resources["InputOfExternalRateRedIsMandatory"]}
                                    InputProps={{ inputProps: { min: 1 } }} />
                            </ThemeProvider >
                        </Form.Group>
                        <br></br>

                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <div id="buttons" className="col-6" style={{ padding: 0 }}>
                                <div style={{ float: "left", marginBottom: "30px" }}>
                                    <Button id="delete-rates" onClick={this.handleOpen} disabled={this.state.id === 0} variant="outline-secondary" style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Delete />
                                        <span style={{ color: "#7CB9E8" }}> {resources["Delete"]}</span>
                                    </Button>
                                </div>
                                <div style={{ float: "left", marginBottom: "30px" }}>
                                    <Button style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }} id="save-rates" color="primary" onClick={this.state.id === 0 ? this.saveNewRate : this.updateRate} disabled={!this.checkIfRequestIsValid()}>
                                        {resources["SaveRates"]}
                                    </Button>
                                </div>
                            </div>
                        </Form.Group>
                        <br></br>
                        <div>
                            {this.renderModifiedByInfo()}
                        </div>

                    </Form>
                </MuiThemeProvider>
            </Paper>

        );
    }

    renderMain() {
        const { loading } = this.state;

        if (loading) return (<div className="centered-loader"><CircularProgress /></div>);

        return <div className={"py-4 grey " + (this.state.loading ? 'fadeOut' : 'fadeIn')} style={{ textAlign: "center" }}>
            {this.renderForm()}
        </div>;
    }

    render() {

        const { resources } = this.props;

        return (
            <div>
                <div className="position-relative py-4">
                    <h3>{resources["OccupancyRates"]}</h3>
                    <div className="container" id="edit-paper">
                        {this.renderMain()}
                    </div>
                    <Rodal visible={this.state.showDelete} onClose={this.handleCloseDelete} className="rodal" customStyles={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                    }}>
                        <div className="modal-body" style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>{resources["AreYouSureYouWantToDeleteOccupancyRate"]}</div>
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <button className="btn btn-secondary" id="btnCancelDelete" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                                onClick={this.handleCloseDelete} disabled={this.state.requestInProgress}>{resources["Cancel"]}</button>
                            <button className="btn btn-primary" id="btnDelete" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }}
                                onClick={this.deleteRate} disabled={this.state.requestInProgress}>{resources["Yes"]}</button>

                        </div>
                        <span className="rodal-close"></span>
                    </Rodal>


                </div>
            </div >
        );
    }
}