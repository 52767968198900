import React, { Component, forwardRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import MaterialTable from 'material-table';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Loader from '../../../Loader.js';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Paper from '@material-ui/core/Paper';
import Form from 'react-bootstrap/Form';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import CircularProgress from '@material-ui/core/CircularProgress';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Button from '@material-ui/core/Button';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { LocalizationTheme } from '../../../../utils/utils.js';
import { DisplayMessage } from '../../../../services/helpers.js';
import FontDownloadRoundedIcon from '@material-ui/icons/FontDownloadRounded';
import { DeleteForever } from '@material-ui/icons';

import { SelectTheme } from '../../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";
import Block from '@material-ui/icons/Block';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    FontDownloadRoundedIcon: forwardRef((props, ref) => <FontDownloadRoundedIcon {...props} ref={ref} />)
};

export default class TagsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: 3,
            outletId: 0,
            dataGrid: [],
            currenttagId: this.props.currenttagId,
            tagId: 0,
            fetchingData: true,
            showDelete: false,
            deletedItem: 0,
            accessibleMoboOutlets: [],
            editedtagId: undefined,
            editedTag: undefined,
            showEditTag: false,
            requestInProgress: false,
            tags: []
        };
    }

    componentWillReceiveProps(newProps) {
        if (newProps.refreshGrid && newProps.outletId !== undefined) {
            this.setState({ outletId: newProps.outletId, type: parseInt(newProps.type) });
            this.getData(newProps.outletId, parseInt(newProps.type));

        } else {
            if (newProps.refreshGrid) {
                this.getData();
            }
        }

        this.setState({ redirect: null });
    }

    componentDidMount() {
        this.getData();
    }

    getData = (outletId, typeId) => {
        this.setState({ fetchingData: true });
        let initialData = {};
        var resources = this.props.resources;
        var accessibleMoboOutlets = this.props.accessibleMoboOutlets;
        var moboOutletId = outletId != undefined ? outletId : this.state.outletId;
        var type = typeId != undefined ? typeId : this.state.type;
        var url = "";

        initialData.columns = [
            {
                title: resources["Color"], field: 'colorHex', width: "15%", render: rowData => {
                    if (/^[0-9A-F]{6}$/i.test(rowData.colorHex)) {
                        return <div id={"color-" + rowData.colorHex} className="color-card" style={{ backgroundColor: "#" + rowData.colorHex }}>
                        </div>
                    }
                    else {
                        return <div id="color-none" className="color-card">
                            <Block />
                        </div>
                    }
                }
            },
            { title: resources["Outlet"], width: "20%", field: 'moboOutletDescription', filtering: false, customSort: (a, b) => a.moboOutletDescription.toLowerCase().localeCompare(b.moboOutletDescription.toLowerCase()) },
            { title: resources["Description"], width: "40%", field: 'description', filtering: false, customSort: (a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()) },
            { title: resources["IsPause"], width: "20%", field: 'isPause', filtering: false, customSort: (a, b) => a.isPause.toLowerCase().localeCompare(b.isPause.toLowerCase()) },
            { title: resources["IsVisibleInPlanning"], width: "20%", field: 'isVisibleInPlanning', filtering: false, customSort: (a, b) => a.isVisibleInPlanning.toLowerCase().localeCompare(b.isVisibleInPlanning.toLowerCase()) },
        ];

        initialData.actions = [
            {
                icon: () => <Edit />,
                tooltip: resources["Edit"],
                onClick: (event, rowData) => this.setState({ redirect: `tags/${rowData.id}`, editedtagId: rowData.id })
            },

            rowData => (!rowData.isDeletedValue && rowData.id === this.state.currenttagId ? {
                icon: () => <DeleteForever />,
                tooltip: resources["YouCantDeleteTag"],

            } : null),

            rowData => (!rowData.isDeletedValue && rowData.id !== this.state.currenttagId ? {
                icon: () => <DeleteOutline />,
                tooltip: resources["Delete"],
                onClick: () => this.handleShowDelete(rowData.id),

            } : null),
        ]

        url = `/api/mobopro/v2/tags?type=` + type;
        if (moboOutletId != undefined && moboOutletId != 0) {
            url = url + `&moId=` + moboOutletId + `&settings=true`;
        }

        fetch(url,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {
                if (!response.ok) { throw new Error(); }
                return response.json();
            }).then(result => {
                const currentRows = [];
                const tags = [];

                result.forEach(function (rowData) {
                    var moboOutlet = rowData.moboOutletId !== undefined && rowData.moboOutletId !== null ? accessibleMoboOutlets.find(x => x.id === rowData.moboOutletId) : null;
                    var moboOutletDesc = moboOutlet !== undefined && moboOutlet !== null ? moboOutlet.description : "";

                    tags.push(rowData);

                    currentRows.push({
                        id: rowData.id,
                        colorHex: rowData.colorHex,
                        moboOutletDescription: moboOutletDesc,
                        description: rowData.description,
                        isPause: rowData.isPause ? resources["Yes"] : resources["No"],
                        isVisibleInPlanning: rowData.isVisibleInPlanning
                    });
                });

                initialData.data = currentRows;

                this.setState({
                    dataGrid: initialData,
                    fetchingData: false,
                    tags: tags
                });
            });
    }

    handleCloseDelete = () => {
        this.setState({ showDelete: false, deletedItem: 0 });
    }

    handleShowDelete = (itemId) => {
        this.setState({ showDelete: true, deletedItem: itemId });
    }

    handleDeleteTag = () => {
        if (this.state.deletedItem > 0) {
            let resources = this.props.resources;
            this.setState({ requestInProgress: true });
            const url = "/api/mobopro/v1/tags/" + this.state.deletedItem;
            fetch(url, {
                method: 'DELETE',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            }).then(response => {
                if (!response.ok) {
                    if (response.statusText.length > 0) {
                        DisplayMessage(response.statusText, "error");
                        return response.json();
                    }

                    for (var i = 0; i < response.errors.length; i++) {
                        DisplayMessage(response.errors[i], "error");
                    }
                }
            }).then(result => {
                DisplayMessage(resources["TagWasDeleted"], "success");
                this.getData();
                this.setState({ showDelete: false, deletedItem: 0, requestInProgress: false });
                this.handleCloseDelete();

            });
        }
    }

    renderOutlets = () => {

        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;

        var outletOptions = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outletOptions;
    }

    handleChangeOutlet = e => {
        this.setState({ outletId: parseInt(e.target.value), requestInProgress: false }, () => this.getData(this.state.outletId));
    };

    handleTypeChange = e => {
        this.setState({ [e.target.name]: parseInt(e.target.value), requestInProgress: false }, () => this.getData(this.state.outletId));
    };

    render() {
        const { redirect, fetchingData, type, editedtagId, outletId } = this.state;
        const { resources } = this.props;

        if (redirect) {
            return <Redirect to={{
                pathname: redirect,
                state: { editedtagId: editedtagId, editedTag: this.state.tags.find(t => t.id === editedtagId), refreshGrid: true }
            }}
            />
        }

        return (
            <div>
                <div className="position-relative py-4">
                    <h3>{resources["TagsTitle"]}</h3>
                    <div className={"offcentered-loader " + (fetchingData ? 'fadeIn' : 'fadeOut')}><CircularProgress /></div>

                    <div className={"mt-4 " + (fetchingData ? 'fadeOut' : 'fadeIn')}>
                        <Form.Group className="main-dropdown">
                            <FormControl className="col-md-12" variant="outlined" onChange={this.handleChangeOutlet}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="outlined-outlet-native-simple">{resources["Outlet"]}</InputLabel>
                                </ThemeProvider >
                                <Select
                                    id="tag-outlet-dropdown"
                                    native
                                    label={resources["Outlet"]}
                                    value={outletId != undefined ? outletId : 0}
                                    inputProps={{
                                        name: 'outlet',
                                        id: 'tag-outlet-dropdown',
                                    }}>
                                    <option value={0}>{resources["All"]}</option>
                                    {this.renderOutlets()}
                                </Select>
                            </FormControl>
                        </Form.Group>
                        <div style={{ clear: "both", }}></div>
                        <Form.Group className="main-dropdown">
                            <FormControl className="col-md-12" variant="outlined" onChange={this.handleTypeChange}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="outlined-type-native-simple">{resources["Type"]}</InputLabel>
                                </ThemeProvider>
                                <Select
                                    id="tag-type-dropdown"
                                    native
                                    label={resources["Type"]}
                                    value={type}
                                    inputProps={{
                                        name: 'type',
                                        id: 'tag-type-dropdown',
                                    }}>
                                    <option value={3} key={3}>{resources["Dossiers"]}</option>
                                    <option value={4} key={4}>{resources["RepairOrderItems"]}</option>
                                    <option value={5} key={5}>{resources["Warranty"]}</option>
                                </Select>
                            </FormControl>
                        </Form.Group>
                        <div style={{ float: "right", marginBottom: "10px" }}>
                            <Link to={{ pathname: "/tags/new", state: { tagId: this.state.tagId, accessibleMoboOutlets: this.state.accessibleMoboOutlets, outletId: outletId, type: type } }} style={{ textDecoration: 'none' }}>
                                <Button id="add-tag" variant="contained" color="primary">
                                    <i className="fas fa-plus mr-2" title={resources["AddNewTag"]} />
                                    {resources["AddNewTag"]}
                                </Button>
                            </Link >
                        </div>
                        <div style={{ clear: "both", }}></div>

                        <Paper elevation={3} className="p-3 mt-3">
                            <MuiThemeProvider theme={LocalizationTheme()}>
                                <MaterialTable
                                    icons={tableIcons}
                                    title={""}
                                    localization={{
                                        header: { actions: resources["Actions"] },
                                        body: {
                                            emptyDataSourceMessage: resources["NoRecordsToBeDisplayed"],
                                            deleteTooltip: resources["DeleteTooltip"],
                                            editTooltip: resources["EditTooltip"],
                                        },
                                        toolbar: {
                                            searchPlaceholder: resources["Search"],
                                            searchTooltip: resources["Search"]
                                        },
                                        pagination: {
                                            firstTooltip: resources["FirstTooltip"],
                                            lastTooltip: resources["LastTooltip"],

                                            previousTooltip: resources["PreviousTooltip"],
                                            nextTooltip: resources["NextTooltip"],

                                            labelRowsPerPage: resources["LabelRowsPerPage"],
                                            labelRowsSelect: resources["LabelRowsSelect"],
                                        }
                                    }}
                                    data={this.state.dataGrid.data}
                                    columns={this.state.dataGrid.columns}
                                    actions={this.state.dataGrid.actions}
                                    options={{
                                        emptyRowsWhenPaging: false, pageSize: 10, pageSizeOptions: [10, 15, 20]
                                    }}

                                />
                            </MuiThemeProvider>
                        </Paper>
                    </div>
                    <div className={"loader-overlap position-absolute " + (this.state.fetchingData ? 'fadeIn' : 'fadeOut')}><div className="container"><Loader /></div></div>

                    <Rodal visible={this.state.showDelete} onClose={this.handleCloseDelete} className="rodal" customStyles={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                    }}>
                        <div className="modal-body" style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>{resources["AreYouSureYouWantToDeleteTag"]}</div>
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <button className="btn btn-secondary" id="btnCancelDelete" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                                onClick={this.handleCloseDelete} disabled={this.state.requestInProgress}>{resources["Cancel"]}</button>
                            <button className="btn btn-primary" id="btnDelete" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }}
                                onClick={this.handleDeleteTag} disabled={this.state.requestInProgress}>{resources["Yes"]}</button>

                        </div>
                        <span className="rodal-close"></span>
                    </Rodal>

                </div>
            </div >
        )
    };
}