import React from 'react';
import { Route, Switch } from 'react-router';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import BookingItemsTable from '../components/WorkshopPlanning/General/BookingItems/BookingItemsTable';
import BookingItemsCreation from '../components/WorkshopPlanning/General/BookingItems/BookingItemsCreation';
import BookingItemsEditing from '../components/WorkshopPlanning/General/BookingItems/BookingItemsEditing';
import { Permissions } from '../utils/Permissions';

export default function BookingItemsRouting(props) {
    const { currentIdentity, resources } = props;
    return (
        <>
             <RestrictedRoute path='/bookingitems' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.WorkshopSettings]} resources={resources} render={(props) => {
                return (<BookingItemsTable resources={resources} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets}
                    refreshGrid={props && props.location && props.location.state && props.location.state.refreshGrid}
                    outletId={props && props.location && props.location.state && props.location.state.outletId} />)
            }} />
            <Switch>
                <Route exact path='/bookingitems/new' render={(props) => (<BookingItemsCreation outletId={props && props.location && props.location.state && props.location.state.outletId} resources={resources}
                    language={currentIdentity.culture.substring(0, 2)} objectType={currentIdentity.objectType} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} />)} />
                <Route exact path='/bookingitems/:outletId/:bookingItemId' render={(props) => {
                    return (<BookingItemsEditing resources={resources}
                        outletId={props.match.params.outletId}
                        bookingItems={props && props.location && props.location.state && props.location.state.bookingItems}
                        objectType={currentIdentity.objectType}
                        bookingItemId={props.match.params.bookingItemId} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} />)
                }} />
            </Switch>
        </>
    )
}