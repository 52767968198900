import moment from 'moment';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import reactCSS from 'reactcss'

export function GeTypeMapping(type) {
    var typeItem = "";
    switch (type) {
        case 1:
            typeItem = "TeamTypeWorkshop";
            break;
        case 2:
            typeItem = "TeamTypeTyreChanges";
            break;
        case 3:
            typeItem = "TeamTypePDI";
            break;
    }
    return typeItem;
}

export function GetFormattedDate(date, addHour) {
    if (date !== undefined && date !== null && date !== "") {

        date = (date == undefined) ? new Date() : new Date(date);
        var minute = String(date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes());
        var hour = String(date.getHours());
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = date.getFullYear();

        const formattedDate = yyyy + '-' + mm + '-' + dd;
        const formattedDateWithHour = yyyy + '-' + mm + '-' + dd + " " + hour + ":" + minute;
        return addHour == true ? formattedDateWithHour : formattedDate;
    }
    return null;
}

export function GetUTCFormattedDate(date, onlytime) {
    if (date !== undefined && date !== null && date !== "") {

        date = (date == undefined) ? new Date() : new Date(date);
        var minute = String(date.getUTCMinutes() <= 9 ? "0" + date.getUTCMinutes() : date.getUTCMinutes());
        var hour = String(date.getUTCHours());
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = date.getFullYear();

        const formattedTime = hour + ":" + minute;
        const formattedDate = yyyy + '-' + mm + '-' + dd;
        const formattedDateWithHour = formattedDate + " " + formattedTime;
        return onlytime == true ? formattedTime : formattedDateWithHour;
    }
    return null;
}

export function GetFormattedDateEuro(date) {
    var dateParts = date.split("/");
    return dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];
}

export function ValidateDate(date) {
    var date = moment(date);
    if (date < new Date("2000-01-01") || date > new Date("2999-12-31")) return false;
    return true;
}

export function DisplayMessage(message, messageType, delay) {
    toastr.options = {
        positionClass: "toast-top-right",
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 5000,
        extendedTimeOut: 1000,
        preventDuplicates: true,
        closeButton: false,
        debug: false,
        newestOnTop: false,
        progressBar: false,
        onclick: null,
        showEasing: "swing",
        hideEasing: "linear",
        showMethod: "fadeIn",
        hideMethod: "fadeOut"
    };
    toastr.clear();

    var milliseconds = delay == undefined ? 500 : delay;
    switch (messageType) {
        case "error":
            setTimeout(() => toastr.error(message), milliseconds);
            break;
        case "info":
            setTimeout(() => toastr.info(message), milliseconds);
        case "success":
            setTimeout(() => toastr.success(message), milliseconds);
            break;
        default:
            setTimeout(() => toastr.warning(message), milliseconds);
    }
}

export function GetDefaultAgendasDetails(year) {
    const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    var timetables = [];
    months.forEach(month => {
        var mondays = GetMondays(month, year);

        mondays.map(monday => {
            timetables.push({
                "startOfWeek": SetFixedDatetimeFormat(monday),
                "timetableId": 0
            });
        })
    });
    return timetables;

}

export function SetFixedDatetimeFormat(monday) {
    var day = monday.getDate() < 10 ? "0" + monday.getDate() : monday.getDate().toString();
    var month = monday.getMonth() < 9 ? "0" + (monday.getMonth() + 1) : monday.getMonth() + 1;
    return `${month}/${day}/${monday.getFullYear()}`;
}

export function GetMondays(month, year) {
    var d = new Date(year, month, 1),
        mondays = [];

    d.setDate(1);

    // Get the first Monday in the month
    while (d.getDay() !== 1) {
        d.setDate(d.getDate() + 1);
    }

    // Get all the other Mondays in the month
    while (d.getMonth() === month) {
        mondays.push(new Date(d.getTime()));
        d.setDate(d.getDate() + 7);
    }

    return mondays;
}

export function SearchTimetable(key, timetables) {
    if (timetables === undefined) return 0;

    var startOfWeek = SetFixedDatetimeFormat(key);
    for (var i = 0; i < timetables.length; i++) {
        if (timetables[i].startOfWeek === startOfWeek) {
            return timetables[i].timetableId;
        }
    }
}

export function GetValidTimetables(timetables) {
    var validatedTimeTables = [];

    if (timetables === undefined) return validatedTimeTables;

    for (var i = 0; i < timetables.length; i++) {
        if (timetables[i].timetableId !== 0) {
            validatedTimeTables.push(timetables[i]);
        }
    }
    return validatedTimeTables;

}

export function GetDayOfWeekName(dayOfWeek, resources) {
    var dayOfWeekName = "";
    switch (dayOfWeek) {
        case 1:
            dayOfWeekName = resources['Monday'];
            break;
        case 2:
            dayOfWeekName = resources['Tuesday'];
            break;
        case 3:
            dayOfWeekName = resources['Wednesday'];
            break;
        case 4:
            dayOfWeekName = resources['Thursday'];
            break;
        case 5:
            dayOfWeekName = resources['Friday'];
            break;
        case 6:
            dayOfWeekName = resources['Saturday'];
            break;
        case 0:
            dayOfWeekName = resources['Sunday'];
            break;
        default:
            break;

    }
    return dayOfWeekName;
}

export function TranslateDayOfWeekName(dayOfWeek, resources) {
    var dayOfWeekName = "";
    switch (dayOfWeek) {
        case "Monday":
            dayOfWeekName = resources['Monday'];
            break;
        case "Tuesday":
            dayOfWeekName = resources['Tuesday'];
            break;
        case "Wednesday":
            dayOfWeekName = resources['Wednesday'];
            break;
        case "Thursday":
            dayOfWeekName = resources['Thursday'];
            break;
        case "Friday":
            dayOfWeekName = resources['Friday'];
            break;
        case "Saturday":
            dayOfWeekName = resources['Saturday'];
            break;
        case "Sunday":
            dayOfWeekName = resources['Sunday'];
            break;
        default:
            break;

    }
    return dayOfWeekName;
}


export function GetOutletUserDescription(outletUsers, outletuserId) {
    var description = '';

    outletUsers.forEach(outletUser => {
        if (outletUser.id === outletuserId) {
            description = outletUser.description;
            return description;
        }
    });

    return description;
}

export function GetPickerCss(color) {
    return reactCSS({
        'default': {
            color: {
                width: '36px',
                height: '25px',
                borderRadius: '2px',
                background: color,

            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
                marginRight: '10px'
            },
            popover: {
                paddingTop: '6px',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });
}
export function ExcludeSpecialCharactersFromInputTypeNumber(includeDot) {
    if (includeDot) {
        return e => ['e', 'E', '+', '-', '*', '.'].includes(e.key) && e.preventDefault()
    }
    else {
        return e => ['e', 'E', '+', '-', '*'].includes(e.key) && e.preventDefault()
    }
}

export function ValidateIntegerFromInputTypeNumber(includeDot) {

    if (includeDot) {
        return e => ((['e', 'E', '+', '-', '*', '.'].includes(e.key)) || e.target.value[0] == '0' && !isNaN(e.key)) && e.preventDefault()
    }
    else {
        return e => ((['e', 'E', '+', '-', '*'].includes(e.key)) || e.target.value[0] == '0' && !isNaN(e.key)) && e.preventDefault()
    }
}

export function Uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
}
