import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { Button, CircularProgress, FormControl, InputLabel, Paper, Select, ThemeProvider, Typography } from '@material-ui/core';
import 'toastr/build/toastr.min.css';
import { DisplayMessage} from '../../../services/helpers.js';
import { Dialog, AppBar, Toolbar,Slide } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Redirect } from 'react-router-dom';
import { SelectTheme } from '../../../utils/utils.js';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default class WorkPreparationsNew extends Component {

    constructor(props) {
        super(props);

        var moboOutletId = 0;
        if (this.props != undefined) {
            moboOutletId = this.props.moboOutletId;
        }
        
        this.state = {
            roles: [],
            moboOutletId: moboOutletId,
            roleId: 0,

            open: true,
            loading: false,
            requestInProgress: true,
            redirect: null,
            moboOutletRoleWasCreated: false,
        };
    }

    componentDidMount() {
        this.getRoles();
    }
   
    handleSubmit = () => {
        this.saveMoboOutletRole();
    }

    saveMoboOutletRole = () => {
        let resources = this.props.resources;

        var json = JSON.stringify({
            roleId: this.state.roleId,
            moboOutletId: this.state.moboOutletId
        });

        const url = "/api/mobopro/v1/workpreparations/mobooutletroles";
        fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {
            if (!response.ok) {
                response.json().then(result => {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                });
            }
            else {
                DisplayMessage(resources["NewRoleWasSaved"], "success");
                this.setState({ requestInProgress: false, redirect: "/workpreparations", moboOutletRoleWasCreated: response.ok });
            }
        });
    }

    validateData(moboOutletId, roleId){
       return  moboOutletId > 0 && roleId > 0;
    }

  
    handleChangeOutlet = e => {
        this.setState({ moboOutletId: parseInt(e.target.value), requestInProgress: !this.validateData(parseInt(e.target.value), this.state.roleId)});
    };

    handleChangeRole = e => {
        this.setState({ roleId: parseInt(e.target.value), requestInProgress: !this.validateData(this.state.moboOutletId, parseInt(e.target.value))});
    };

    handleClose = () => {
      this.setState({ open: false, redirect: "/workpreparations" });
    }

    getRoles = () => {

        const url = "/api/mobopro/v1/workpreparations/roles";
        fetch(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            })
        })
            .then(response => response.json())
            .then(result => {
                const roles = [];

                result.forEach(function (item) {
                    roles.push({
                        id: item.id,
                        description: item.description,
                        isActive: item.isActive
                    });
                });

                var activeRoles = roles.filter(role => role.isActive === true);
                var rolesSorted = activeRoles.sort((a, b) => (a.description > b.description) ? 1 : -1);

                this.setState({
                    roles: rolesSorted
                });
            });
    }


    renderRoles = () => {
        if (this.state.roles === undefined) return;

        return this.state.roles.map(x => <option key={x.id} value={x.id.toString()}> {x.description}</option>);
    }

    renderOutlets = () => {
        if (this.props.accessibleMoboOutlets === undefined) return;

        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description.toLowerCase().localeCompare(b.description.toLowerCase())))
        return outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
    }

    renderForm() {
        const { redirect, requestInProgress, moboOutletId, roleId, moboOutletRoleWasCreated, open} = this.state;
        const { resources } = this.props;

        if (redirect) { return <Redirect to={{ pathname: this.state.redirect, state: { moboOutletId: moboOutletId } }} /> }

        const handleSubmit = (event) => {
            const form = event.currentTarget;
            event.preventDefault();
            event.stopPropagation();
            this.handleSubmit();
        };
        return (
            <Dialog fullScreen  open={open}  onClose={this.handleClose} TransitionComponent={Transition}>
                <Form onSubmit={handleSubmit} >

                    <AppBar className="position-sticky">
                        <Toolbar>
                            <IconButton edge="start" id="close-add-workpreparation-role" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flex: 1 }}>
                                {resources["AddNewRoleTitle"]}
                            </Typography>
                            <Button id="workpreparation-role-save" color="inherit" type="submit" disabled={requestInProgress}>{resources["Save"]}</Button>
                        </Toolbar>
                    </AppBar>

                    <div className="container">
                        <div className="py-4 grey">
                            <Paper elevation={3} className="p-4 mt-3 mb-3">
                                <Form.Group className="pt-3" style={{ display: "flex" }}>
                                    <div className="col-md-3"></div>
                                    <FormControl className="col-md-6 helper-text-red" variant="outlined" onChange={this.handleChangeOutlet}>
                                        <ThemeProvider theme={SelectTheme}>
                                            <InputLabel htmlFor="moboOutlet-workpreparation">{resources["Outlet"]}</InputLabel>
                                        </ThemeProvider>
                                        <Select
                                            native
                                            label={resources["Outlet"]}
                                            value={moboOutletId}
                                            inputProps={{
                                                name: 'moboOutletId',
                                                id: 'moboOutlet-id',
                                            }}>
                                            {this.renderOutlets()}
                                        </Select>
                                    </FormControl>
                                </Form.Group>
                                <Form.Group style={{ display: "flex" }}>
                                    <div className="col-md-3"></div>
                                    <FormControl className="col-md-6 helper-text-red" variant="outlined" onChange={this.handleChangeRole}>
                                        <ThemeProvider theme={SelectTheme}>
                                            <InputLabel htmlFor="role">{resources["Role"]}</InputLabel>
                                        </ThemeProvider>
                                        <Select
                                            native
                                            label={resources["Role"]}
                                            value={roleId}
                                            inputProps={{
                                                name: 'roleId',
                                                id: 'workpreparation-role',
                                            }}>
                                             <option value={0}>{resources["Select"]}</option>
                                            {this.renderRoles()}
                                        </Select>
                                    </FormControl>
                                </Form.Group>
                            </Paper>
                        </div>
                    </div>
                </Form>
            </Dialog >
        );
    }

    render() {
        const { loading } = this.state;

        if (loading) return (<div className="centered-loader"><CircularProgress /></div>)

        return (
            <div className={"pt-4 grey text-center" + (this.state.loading ? 'fadeOut' : 'fadeIn')} >

                <div className="container">
                    {this.renderForm()}
                </div>
            </div>
        );
    }
}