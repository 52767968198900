import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { Button, Grid, FormControl, FormHelperText, InputLabel, Paper, Select, TextField, ThemeProvider, Typography, Radio, RadioGroup } from '@material-ui/core';
import { Dialog, AppBar, Toolbar, FormControlLabel, Slide } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { DisplayMessage, GetFormattedDate } from '../../../../services/helpers.js';
import { SelectTheme } from '../../../../utils/utils.js';
import { TimePicker } from 'antd';
import moment from 'moment';

const format = 'HH:mm';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default class AbsenceNew extends Component {
    constructor(props) {
        super(props);
 
        var outlets = [];
        if (this.props.accessibleMoboOutlets !== undefined) {
            outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description.toLowerCase().localeCompare(b.description.toLowerCase())))
        }

        var outletId = this.props.outletId !==undefined ? parseInt(this.props.outletId): outlets[0].id;

        this.state = {
            outletUserId: 0,
            requestInProgress: false,
            openPopup: true,
            dateFrom: "",
            absentHourFrom: null,
            absentHourTill: null,

            absentFrom: "",
            absentTill: "",

            duration: "1",
            reasons: [],
            reason: "none",
            outlets: outlets,
            outletId:outletId,
        };
    }

    componentWillMount() {
        this.getOutletUsers();
        this.getReasons();
    }

    handleDateFrom = e => {
        this.setState({ absentHourFrom: null, absentHourTill: null, dateFrom: e.target.value }, () => this.getTimetables());
    };

    handleChangeOutlet = e => {
        var id = e.target.value
        this.setState({ outletId: id }, () => this.getTimetables());
    };

    handleAbsentFrom = e => {
        if (e.target.value > this.state.absentTill) {
            this.setState({ absentTill: "" });
        }
        this.setState({ absentFrom: e.target.value });
    };

    handleAbsentTill = e => {

        this.setState({ absentTill: e.target.value });
    };

    validateData = (absentFrom, absentTill) => {
        var resources = this.props.resources;

        if (this.state.outletUserId < 1) {
            DisplayMessage(resources["InvalidOutletUser"], "error");
            return false;
        }

        if (this.state.duration == 1) {

            if (this.state.dateFrom === "" || this.state.absentHourFrom === null || this.state.absentHourTill === null || this.state.absentHourFrom > this.state.absentHourTill) {
                DisplayMessage(resources["InvalidAbsentHours"], "error");
                return false;
            }
        }
        else {

            if (this.state.duration == 2 && absentFrom > absentTill) {
                DisplayMessage(resources["InvalidAbsentTill"], "error");
                return false;
            }
        }

        if (this.state.reason == "none") {
            DisplayMessage(resources["InvalidReason"], "error");
            return false;
        }

        return true;
    }

    saveNewAbsence = () => {
        let absentFrom;
        let absentTill;

        if (this.state.duration == 1) {
            if (!this.validateData(absentFrom, absentTill)) return;
            absentFrom = this.state.dateFrom + " " + this.state.absentHourFrom;
            absentTill = this.state.dateFrom + " " + this.state.absentHourTill;
        }

        if (this.state.duration == 2) {
            absentFrom = GetFormattedDate(this.state.absentFrom == "" ? undefined : this.state.absentFrom);
            absentTill = GetFormattedDate(this.state.absentTill == "" ? undefined : this.state.absentTill);
            if (!this.validateData(absentFrom, absentTill)) return;
        }

        let resources = this.props.resources;
        this.setState({ requestInProgress: true });

        var json = JSON.stringify({
            assignedTo: this.state.outletUserId,
            moboOutletId: this.state.outletId,
            absentFrom: absentFrom,
            absentTill: absentTill,
            absenceTypeId: parseInt(this.state.reason),
        });

        const url = "/api/mobopro/v1/planningabsences";
        fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {
            if (!response.ok) {
                response.json().then(result => {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                });
            }
            else {
                DisplayMessage(resources["NewAbsenceWasSaved"], "success");
                this.setState({ requestInProgress: false, redirect: "/absences", absenceWasCreated: true });
            }
        });
        this.setState({ requestInProgress: false });
    }

    handleClose = () => {
      this.setState({ redirect: "/absences" });
    }

    handleChangeOutletUser = e => {
        this.setState({ outletUserId: parseInt(e.target.value) }, () => this.getTimetables());
    };

    handleDuration(duration) {
   
      if (duration!== undefined)  this.setState({ duration:duration });
    }

    handleChangeReason = e => {

        this.setState({  reason: e.target.value });
    };

    renderOutlets = () => {
        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;

        var outletOptions = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outletOptions;
    }

    getOutletUsers() {
        fetch("/api/mobopro/v1/outletusers?includedeleted=false",
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            }).then(response => {
                if (!response.ok) {
                    for (var i = 0; i < response.errors.length; i++) {
                        DisplayMessage(response.errors[i], "error");
                    }
                }
                return response.json();
            }).then(result => {
                const outletUsers = [];

                result.forEach(function (rowData) {
                    outletUsers.push({
                        id: rowData.id,
                        name: `${rowData.firstName} ${rowData.lastName}`,
                    });
                });

                this.setState({
                    outletUsers: outletUsers,
                    fetchingData: false
                });
            });
    }

    getTimetables() {
        if (this.state.outletUserId < 1 || this.state.outletId < 1 || this.state.dateFrom === "") return;

        const url = `/api/mobopro/v1/outletusertimetabledetails?ouid=${this.state.outletUserId}&moid=${this.state.outletId}&requestedDate=${this.state.dateFrom}`
        fetch(url,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),

            }).then(response => {
                response.json().then(result => {
                    if (!response.ok) {

                        if (response.errors !== undefined) {
                            for (var i = 0; i < response.errors.length; i++) {
                                DisplayMessage(response.errors[i], "error");
                            }
                        }
                    }
                    else {
                        this.setState({
                            absentHourFrom: result.startOfDay.substring(0,5),
                            absentHourTill: result.endOfDay.substring(0,5),
                            fetchingData: false
                        });
                    }
                })

            });
    }

    getReasons() {
        fetch("/api/mobopro/v1/absencetypes",
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            }).then(response => {
                if (!response.ok) {
                    for (var i = 0; i < response.errors.length; i++) {
                        DisplayMessage(response.errors[i], "error");
                    }
                }
                return response.json();
            }).then(result => {
                const reasons = [];

                result.forEach(function (rowData) {
                    if (rowData.isActive) {
                        reasons.push({
                            id: rowData.id,
                            description: rowData.description
                        });
                    }
                });

                this.setState({
                    reasons,
                    fetchingData: false
                });
            });
    }

    renderOutletUsers = () => {
        if (this.state.outletUsers == undefined || this.state.outletUsers.length == 0) return;

        var outletUsers = this.state.outletUsers.sort((a, b) => (a.name.toLowerCase().localeCompare(b.name.toLowerCase())))
        return outletUsers.map(x => <option key={x.id} value={x.id}> {x.name}</option>);
    }

    renderReasons() {
        if (this.state.reasons == undefined || this.state.reasons.length == 0) return;

        var reasons = this.state.reasons.sort((a, b) => (a.description.toLowerCase().localeCompare(b.description.toLowerCase())))
        return reasons.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
    }

    handleChangeTimetableInput = (value, inputType) => {
        this.setState({ [inputType]: value === '' ? null : value });
    }

    renderForm() {
        const { redirect, requestInProgress, absenceWasCreated, openPopup, outletUserId, dateFrom, absentFrom, absentTill, absentHourFrom, absentHourTill, reason, duration } = this.state;
        const { resources } = this.props;

        var helperTextDateFrom = dateFrom === "" ? "helper-text-red" : "helper-text-grey";
        var helperTextAbsentFrom = absentFrom === "" ? "helper-text-red" : "helper-text-grey";
        var helperTextAbsentTill = absentTill === "" ? "helper-text-red" : "helper-text-grey";

        var helperTextAbsentHourFrom = absentHourFrom === "" ? "helper-text-red" : "helper-text-grey";
        var helperTextAbsentHourTill = absentHourTill === "" ? "helper-text-red" : "helper-text-grey";

        var displayOnDayOrLess = duration == "1" ? "flex" : "none";
        var displayMoreThanOneDay = duration == "2" ? "flex" : "none";

        if (redirect) { return <Redirect to={{ pathname: this.state.redirect, state: { absenceWasCreated } }} /> }

        const handleSubmit = (event) => {
            const form = event.currentTarget;
            event.preventDefault();
            event.stopPropagation();
            this.saveNewAbsence();
        };

        return (
            <Dialog fullScreen open={openPopup} onClose={this.handleClose} TransitionComponent={Transition}>
                <Form onSubmit={handleSubmit} >
                    <AppBar className="position-sticky">
                        <Toolbar>
                            <IconButton edge="start" id="close-edit-absence" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flex: 1 }}>
                                {resources["AddNewAbsenceTitle"]}
                            </Typography>
                            <Button id="absence-save" color="inherit" type="submit" disabled={requestInProgress}>{resources["Save"]}</Button>
                        </Toolbar>
                    </AppBar>

                    <div className="container">
                        <div className="py-4 grey">
                            <Paper elevation={3} className="p-4 mt-3 mb-3">

                                <Form.Group style={{ display: "flex", width: "100%", justifyContent: "center" }} onClick={this.handleTyping}>
                                    <div className="col-lg-3 d-none d-xs-block"></div>

                                    <FormControl className="col-lg-6 col-12 helper-text-red" variant="outlined" onChange={this.handleChangeOutlet}>
                                        <ThemeProvider theme={SelectTheme}>
                                            <InputLabel htmlFor="outlined-outlet-native-simple">{resources["Outlet"]}</InputLabel>
                                        </ThemeProvider>
                                        <Select
                                            native
                                            label={resources["Outlet"]}
                                            value={this.state.outletId}
                                            inputProps={{
                                                name: 'outlet',
                                                id: 'outlined-outlet-native-simple',
                                            }}>
                                            {this.renderOutlets()}
                                        </Select>
                                    </FormControl>
                                </Form.Group>

                                <Form.Group style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                    <div className="col-lg-3 d-none d-xs-block"></div>

                                    <FormControl className="col-lg-6 col-12 helper-text-red" variant="outlined" onChange={this.handleChangeOutletUser}>
                                        <ThemeProvider theme={SelectTheme}>
                                            <InputLabel htmlFor="absence-outlet-user">{resources["OutletUser"]}</InputLabel>
                                        </ThemeProvider>
                                        <Select
                                            native
                                            label={resources["OutletUser"]}
                                            value={outletUserId}
                                            inputProps={{
                                                name: 'outletUserId',
                                                id: 'absence-outlet-user',
                                            }}>
                                            <option value={0}>{resources["DefaultDropdownValue"]}</option>
                                            {this.renderOutletUsers()}
                                        </Select>
                                        <FormHelperText className={this.state.outletUserId < 1 ? 'color-red' : 'color-grey'} >{resources["OutletUserHelperText"]}</FormHelperText>
                                    </FormControl>
                                    <div className="col-lg-3 d-none d-xs-block"></div>
                                </Form.Group>


                                <Form.Group style={{ display: "flex", width: "100%", justifyContent: "left" }}  >
                                    <div className="col-3"> </div>
                                    <div className="col-6">
                                        <span style={{ fontSize: "0.8rem" }}>{resources["Duration"]}</span>
                                        <RadioGroup defaultValue={this.state.duration} value={this.state.duration} onClick={(e) => this.handleDuration(e.target.value)}>
                                            <FormControlLabel value="1" control={<Radio />} label={resources["OneDayOrLess"]} />
                                            <FormControlLabel value="2" control={<Radio />} label={resources["MoreThanOneDay"]} />
                                        </RadioGroup>
                                    </div>
                                </Form.Group>

                                <Form.Group id="divOneDayOrLess" style={{ display: displayOnDayOrLess, width: "100%", justifyContent: "left" }}  >
                                    <div className="col-lg-3  d-xs-block"></div>
                                    <FormControl className={"col-lg-3 col-6 " + helperTextDateFrom}>
                                        <TextField id="new-absence-date-from-one-day" className="white-date mr-2" label={resources["OneDayAbsenceDate"]}
                                            helperText={resources["PleasePickADate"]}
                                            name="dateFrom" variant="outlined" type="date"
                                            onChange={this.handleDateFrom} value={dateFrom} InputLabelProps={{ shrink: true, required: true }} />
                                    </FormControl>

                                    <div className="col-lg-3 d-none d-xs-block"></div>
                                </Form.Group>
                                <Form.Group id="divOneDayOrLess1" style={{ display: displayOnDayOrLess, width: "100%", justifyContent: "center" }}  >
                                    <FormControl className={"col-lg-3 col-6 " + helperTextAbsentHourFrom}>
                                        <TimePicker className="white-date mr-2" minuteStep={5} placeholder={resources["AbsentFromTime"]} format={format} name="absentHourFrom" value={absentHourFrom !== null ? moment(absentHourFrom, format) : null} onChange={(time, timeString) => this.handleChangeTimetableInput(timeString, "absentHourFrom")} />
                                    </FormControl>
                                    <FormControl className={"col-lg-3 col-6 " + helperTextAbsentHourTill}>
                                        <TimePicker className="white-date " minuteStep={5} placeholder={resources["AbsentTillTime"]} format={format} name="absentHourTill" value={absentHourTill !== null ? moment(absentHourTill, format) : null} onChange={(time, timeString) => this.handleChangeTimetableInput(timeString, "absentHourTill")} />
                                    </FormControl>
                                    <div className="col-lg-3 d-none d-xs-block"></div>
                                </Form.Group>

                                <Form.Group id="divMoreThatnOneDay" style={{ display: displayMoreThanOneDay, width: "100%", justifyContent: "center" }}  >
                                    <div className="col-lg-3 d-none d-xs-block"></div>
                                    <FormControl className={"col-lg-3 col-6 " + helperTextAbsentFrom}>
                                        <TextField id="new-absence-date-from-more-days" className="white-date mr-2" label={resources["MoreDayAbsentFrom"]}
                                            helperText={resources["PleasePickADate"]}
                                            name="absentFrom" variant="outlined" type="date"
                                            onChange={this.handleAbsentFrom} value={absentFrom} InputLabelProps={{ shrink: true, required: true }} 
                                            inputProps={{min:'2000-01-01'}}/>
                                    </FormControl>
                                    <FormControl className={"col-lg-3 col-6 " + helperTextAbsentTill}>
                                        <TextField id="new-absence-date-till-more-days" className="white-date ml-2" label={resources["MoreDaysAbsentTill"]}
                                            helperText={resources["PleasePickADate"]}
                                            name="absentTill" variant="outlined" type="date"
                                            onChange={this.handleAbsentTill} value={absentTill}
                                            InputProps={{ inputProps: { min: absentFrom != "" ? this.state.absentFrom : null } }}
                                            InputLabelProps={{ shrink: true, required: true }} />
                                    </FormControl>
                                    <div className="col-lg-3 d-none d-xs-block"></div>
                                </Form.Group>


                                <Form.Group style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                    <div className="col-lg-3 d-none d-xs-block"></div>
                                    <FormControl className="col-lg-6 col-12 helper-text-red" variant="outlined" onChange={this.handleChangeReason}>
                                        <ThemeProvider theme={SelectTheme}>
                                            <InputLabel htmlFor="absence-reason">{resources["Reason"]}</InputLabel>
                                        </ThemeProvider>
                                        <Select
                                            native
                                            label={resources["Reason"]}
                                            value={reason}
                                            inputProps={{
                                                name: 'reason',
                                                id: 'absence-reason',
                                            }}>
                                            <option value={"none"}>{resources["DefaultDropdownValue"]}</option>
                                            {this.renderReasons()}
                                        </Select>
                                        <FormHelperText className={this.state.reason == "none" ? 'color-red' : 'color-grey'} >{resources["ReasonHelperText"]}</FormHelperText>
                                    </FormControl>
                                    <div className="col-lg-3 d-none d-xs-block"></div>
                                </Form.Group>
                            </Paper>
                        </div>
                    </div>
                </Form>
            </Dialog >
        );
    }

    render() {
        return (
            <div className={"pt-4 grey text-center" + (this.state.loading ? 'fadeOut' : 'fadeIn')} >
                <div className="container">
                    {this.renderForm()}
                </div>
            </div>
        );
    }
}
