import React, { useState } from 'react';
import { FormGroup, Input, Label } from "reactstrap";
import toastr from 'toastr';
import "./Login.css";
import sha1 from 'crypto-js/sha1';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../auth/authConfig";
import { ExternalImages } from '../utils/ExternalImages';
import { AuthenticationTypes } from '../utils/AuthenticationTypes';

export function Login(props) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState({
        value: '',
        show: false
    });
    const [loading, setLoading] = useState(false);

    const { instance } = useMsal();
    const validateForm = () => {
        if (password.show) {
            return email.length > 0 && password.value.length > 0;
        }

        return email.length > 0;
    }

    const onChangeEmail = (email) => {
        if (password.show) {
            setPassword({ value: '', show: false });
        }
        setEmail(email);
    }

    const handleAuthentication = (event) => {
        event.preventDefault();

        setLoading(true);

        fetch("/api/mobopro/v1/authenticationtype?email=" + email)
            .then(response => {
                response.json().then(data => {
                    if (!response.ok) {
                        for (var i = 0; i < data.errors.length; i++) {
                            toastr.error(data.errors[i]);
                        }
                    }
                    else {
                        switch (data.type) {
                            case AuthenticationTypes.AzureActiveDirectory:
                                instance.loginRedirect(loginRequest).catch(e => {
                                    console.log(e);
                                });
                                break;
                            case AuthenticationTypes.BasicAuthentication:
                                setPassword({ show: true, value: password.value });
                                break;
                            default:
                                break;
                        }
                    }
                    setLoading(false);
                })
            })
    }


    const handleSubmit = (event) => {
        //authenticate
        event.preventDefault();

        setLoading(true);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: email, password: password.value })
        };

        fetch("/api/mobopro/v1/authenticate", requestOptions)
            .then(response => {
                response.json().then(data => {
                    if (!response.ok) {
                        for (var i = 0; i < data.errors.length; i++) {
                            toastr.error(data.errors[i]);
                        }
                    }
                    else {
                        var authorizationToken = sha1(data.uniqueKey + "qMymank8Q0rNyJ0RxDXE");
                        props.loginCallback(data.uniqueKey, authorizationToken.toString())
                    }
                    setLoading(false);
                })
            })
    }

    return (
        <div className="Login">
            <div className="text-center">
                <img src={ExternalImages.AutodialogLogo} className="logo" />
            </div>
            <form className="my-4" >
                <FormGroup >
                    <Input type="email" value={email} placeholder="UserName" onChange={e => onChangeEmail(e.target.value)} autoFocus />
                </FormGroup>
                <FormGroup>
                    {password.show && <Input type="password" value={password.value} placeholder="Password" onChange={e => setPassword({ value: e.target.value, show: true })} />}
                </FormGroup>
                <div className="text-center">
                    {loading ? (<CircularProgress />) :
                        (<Button className="login-button" type="submit" variant="contained" color="primary" disabled={!validateForm()} onClick={(e) => password.show ? handleSubmit(e) : handleAuthentication(e)} >
                            Authenticate
                        </Button>)}
                </div>
            </form>
        </div >
    )
}