import React, { useLayoutEffect, useState } from 'react';
import ExitToApp from '@material-ui/icons/ExitToApp';
import defaultavatar from '../defaultavatar.png';

export default function Profile(props) {
    const [width] = useWindowSize();

    if (props.currentIdentity == null) return null;

    const { firstName, lastName, avatar } = props.currentIdentity;
    return (
        <div className="my-4 text-center" >
            <span></span>
            <div>
                <span>
                    <img className="rounded-circle" alt="user-avatar" src={(avatar === null || avatar === "") ? defaultavatar : avatar} width="50" />
                </span>
                {width >= 1024 ? (<span className="clear">
                    <span className="d-block mt1">
                        <strong className="font-bold">{`${firstName} ${lastName}`}</strong>
                    </span>
                </span>) : (<span className="clear"></span>)}

            </div>
            <div className="my-3">
                <div className="text-center mx-4">
                    <a style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => props.logoutCallback()}>
                        <ExitToApp />{props.resources["Logout"]}
                    </a>
                </div>
            </div>
        </div >)
}

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}