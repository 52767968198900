import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import "toastr/build/toastr.min.css";
import { DisplayMessage, GetPickerCss } from "../../../../services/helpers.js";
import DelayedRedirect from "../../../../utils/DelayedRedirect";
import { Transition } from "../../../../utils/ModalHelpers";
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";
import { SketchPicker } from "react-color";
import "toastr/build/toastr.min.css";
import Select from "@material-ui/core/Select";
import { SelectTheme } from "../../../../utils/utils.js";
import { ThemeProvider } from "@material-ui/core";

export default class DossierStackCreation extends Component {
  constructor(props) {
    super(props);

    var editedDossierStack = undefined;
    var outletId = 0;
    var editedDossierStackId = 0;
    var id = 0;
    if (
      this.props.location !== undefined &&
      this.props.location.state !== undefined
    ) {
      editedDossierStack = this.props.location.state.editedDossierStack;
      editedDossierStackId = this.props.location.state.dossierStackId;
      id = parseInt(this.props.dossierStackId);
    } else if (this.props !== undefined) {
      outletId = this.props.outletId;
      if (this.props.dossierStackId !== undefined)
        id = parseInt(this.props.dossierStackId);
    }

    this.state = {
      id: id,
      description: "",
      outletId: outletId,
      color: "",
      modifiedByName: "",
      modifiedOn: "",
      editedDossierStack: editedDossierStack,
      editedDossierStackId: editedDossierStackId,
      loading: this.props.editedDossierStackId !== undefined,
      accessibleMoboOutlets: [],
      requestInProgress: true,
      redirect: null,
      open: true,
      dossierStackWasCreated: false,
      displayColorPicker: false,
      createdByName: "",
      createdOn: "",
    };
  }

  componentDidMount() {
    if (
      this.props.location !== undefined &&
      this.props.location.state !== undefined &&
      this.props.location.state.editedDossierStack !== undefined
    ) {
      this.setStateDossierStackDetails(
        this.props.location.state.editedDossierStack
      );
    } else if (this.state.id !== undefined && this.state.id > 0) {
      this.getDossierStackDetails();
    }
  }

  handleClose = () => {
    this.setState({
      open: false,
      redirect: "/dossierstacks",
      outletId: this.state.outletId,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleClickColorPicker = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleCloseColorPicker = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChangeColorPicker = (color) => {
    this.setState({
      color: color.hex,
      requestInProgress: !this.checkIfRequestIsValid(false),
    });
  };

  handleChangeComplete = () => {
    this.setState({
      requestInProgress: !this.checkIfRequestIsValid(false),
    });
  };

  handleChangeOutlet = (e) => {
    this.setState({
      outletId: parseInt(e.target.value),
      requestInProgress: !this.checkIfRequestIsValid(false),
    });
  };

  checkIfRequestIsValid(displayErrorMessage) {
    let resources = this.props.resources;
    var isValidRequest = true;
    if (
      this.state.description === "undefined" ||
      this.state.description == null ||
      this.state.description.trim().length <= 0 ||
      this.state.description.length > 100
    ) {
      if (displayErrorMessage)
        DisplayMessage(resources["InvalidDescription"], "error");
      isValidRequest = false;
    }

    if (this.state.color.trim().length <= 0) {
      if (displayErrorMessage)
        DisplayMessage(resources["InvalidColorHex"], "error");
      isValidRequest = false;
    }

    if (this.state.outletId < 0) {
      DisplayMessage(resources["InvalidOutlet"], "error");
      return;
    }
    return isValidRequest;
  }

  handleSubmit = () => {
    if (this.state.id === 0) {
      this.setState({ requestInProgress: true }, () =>
        this.saveNewDossierStack()
      );
    } else {
      this.setState({ requestInProgress: true }, () =>
        this.saveEditedDossierStack()
      );
    }
  };

  saveNewDossierStack = () => {
    this.setState({ requestInProgress: true });

    let resources = this.props.resources;

    if (!this.checkIfRequestIsValid(true)) return;

    var json = JSON.stringify({
      outletId: this.state.outletId > 0 ? this.state.outletId : null,
      colorHex: this.state.color.replace("#", ""),
      description: this.state.description,
    });

    fetch("/api/mobopro/v1/dossierstacks", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        PublicKey: localStorage.getItem("publicKey"),
        "Authorization-token": localStorage.getItem("authorizationToken"),
      }),
      body: json,
    }).then((response) => {
      response.json().then((result) => {
        if (!response.ok) {
          response.json().then((result) => {
            for (var i = 0; i < result.errors.length; i++) {
              DisplayMessage(result.errors[i], "error");
            }
          });
        } else {
          DisplayMessage(resources["NewDossierStackWasSaved"], "success");
          this.setState({
            dossierStackWasCreated: true,
            redirect: "/dossierstacks",
          });
        }
      });
    });
  };

  saveEditedDossierStack = () => {
    this.setState({ requestInProgress: true });

    let resources = this.props.resources;

    if (!this.checkIfRequestIsValid(true)) return;

    var bodyJson = {};
    if (this.state.description !== "")
      bodyJson.description = this.state.description;
    if (this.state.colorHex !== "")
      bodyJson.colorHex = this.state.color.replace("#", "");

    var json = JSON.stringify(bodyJson);

    fetch("/api/mobopro/v1/dossierstacks/" + this.state.id, {
      method: "PATCH",
      headers: new Headers({
        "Content-Type": "application/json",
        PublicKey: localStorage.getItem("publicKey"),
        "Authorization-token": localStorage.getItem("authorizationToken"),
      }),
      body: json,
    }).then((response) => {
      response.json().then((result) => {
        if (!response.ok) {
          for (var i = 0; i < result.errors.length; i++) {
            DisplayMessage(result.errors[i], "error");
          }
        } else {
          DisplayMessage(
            resources["DossierStackEditedSuccessfully"],
            "success"
          );
          this.setState({
            dossierStackWasCreated: true,
            redirect: "/dossierstacks",
          });
        }
      });
    });
  };

  setStateDossierStackDetails = (editedDossierStack) => {
    this.setState({
      id: editedDossierStack.id,
      description: editedDossierStack.description,
      outletId: editedDossierStack.outletId,
      color: "#" + editedDossierStack.colorHex,
      modifiedOn: editedDossierStack.modifiedOn,
      modifiedByName: editedDossierStack.modifiedByName,
      createdByName: editedDossierStack.createdByName,
      createdOn: editedDossierStack.createdOn,
      requestInProgress: false,
    });
  };

  getDossierStackDetails = () => {
    const { id } = this.state;

    let url = `/api/mobopro/v1/dossierstacks/${id}`;
    fetch(url, {
      method: "get",
      headers: new Headers({
        PublicKey: localStorage.getItem("publicKey"),
        "Authorization-token": localStorage.getItem("authorizationToken"),
      }),
    }).then((response) => {
      if (response.ok) {
        response.json().then((result) => {
          if (result.id === id) {
            this.setStateDossierStackDetails(result);
          } else {
            this.setState({ open: false, redirect: "/dossierstacks" });
          }
        });
      } else {
        response.json().then((result) => {
          for (var i = 0; i < result.errors.length; i++) {
            DisplayMessage(result.errors[i], "error");
          }
        });
        this.setState({ open: false, redirect: "/dossierstacks" });
      }
    });
  };

  renderOutlets = () => {
    var outlets = this.props.accessibleMoboOutlets.sort((a, b) =>
      a.description > b.description ? 1 : -1
    );
    if (this.props.accessibleMoboOutlets === undefined) return;

    var outletOptions = outlets.map((x) => (
      <option key={x.id} value={x.id}>
        {" "}
        {x.description}
      </option>
    ));
    return outletOptions;
  };

  renderModifiedByInfo = () => {
    let resources = this.props.resources;
    const { modifiedOn, modifiedByName, createdByName, createdOn } = this.state;

    var modificateDate = modifiedOn ?? createdOn;
    var useName = modifiedByName ?? createdByName;

    if (this.state.id > 0) {
      return (
        <Form.Group style={{ display: "flex" }}>
          <div className="w-100">
            <span>
              {`${resources["ModifiedOn"]} ${moment(modificateDate)
                .format("DD/MM/YYYY HH:mm")
                .toLocaleString()} ${resources["By"]} ${useName}`}
            </span>
          </div>
        </Form.Group>
      );
    }
    return;
  };

  renderForm() {
    const handleSubmit = (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.handleSubmit();
    };
    const { description, outletId } = this.state;

    var resources = this.props.resources;
    var helperTextDescription =
      description.trim().length <= 0 ? "helper-text-red" : "helper-text-grey";
    const styles = GetPickerCss(this.state.color);

    return (
      <div>
        <Paper elevation={3} className="p-4 mt-3 mb-3">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="d-flex">
              <div className="col-3"></div>
              <FormControl
                className="col-6 helper-text-red"
                disabled={this.state.id > 0}
                variant="outlined"
                onChange={this.handleChangeOutlet}
              >
                <ThemeProvider theme={SelectTheme}>
                  <InputLabel htmlFor="outlined-outlet-native-simple">
                    {resources["Outlet"]}
                  </InputLabel>
                </ThemeProvider>
                <Select
                  id="dossierStack-outlets-dropdown"
                  native
                  label={resources["Outlet"]}
                  value={outletId !== undefined ? outletId : 0}
                  inputProps={{
                    name: "outlet",
                    id: "outlined-outlet-native-simple",
                  }}
                >
                  <option value={0}>{resources["All"]}</option>
                  {this.renderOutlets()}
                </Select>
              </FormControl>
              <div className="col-3"></div>
            </Form.Group>
            <Form.Group
              className="pt-3"
              style={{ display: "flex" }}
              onClick={this.handleTyping}
            >
              <div className="col-3"></div>
              <ThemeProvider theme={SelectTheme}>
                <TextField
                  className={"col-6 " + helperTextDescription}
                  id="dossierStack-description"
                  label={resources["Description"]}
                  helperText={resources["InputOfDescriptionIsMandatory"]}
                  value={description || ""}
                  variant="outlined"
                  name="description"
                  onChange={this.handleChange}
                  onKeyUp={this.handleChangeComplete}
                  inputProps={{ maxLength: 100 }}
                />
              </ThemeProvider>
            </Form.Group>
            <Form.Group style={{ display: "flex" }} onClick={this.handleTyping}>
              <div className="col-md-3"></div>
              <div>
                <div style={{ display: "flex" }}>
                  <div
                    style={styles.swatch}
                    onClick={this.handleClickColorPicker}
                  >
                    <div style={styles.color} id="color-picker" />
                  </div>
                  <span
                    className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1 align-center"
                    style={{ paddingTop: "8px", paddingLeft: "3px" }}
                  >
                    {resources["ColorHex"]}
                    <em className="text-danger">*</em>
                  </span>
                </div>
                {this.state.displayColorPicker ? (
                  <div style={styles.popover}>
                    <div
                      style={styles.cover}
                      onClick={this.handleCloseColorPicker}
                    />
                    <SketchPicker
                      color={this.state.color}
                      onChange={this.handleChangeColorPicker}
                      onChangeComplete={this.handleChangeComplete}
                    />
                  </div>
                ) : null}
              </div>
            </Form.Group>
            {this.renderModifiedByInfo()}
          </Form>
        </Paper>
      </div>
    );
  }

  renderMain() {
    const { loading } = this.state;

    if (loading)
      return (
        <div className="centered-loader">
          <CircularProgress />
        </div>
      );

    return (
      <div
        className={"py-4 grey " + (this.state.loading ? "fadeOut" : "fadeIn")}
        style={{ textAlign: "center" }}
      >
        {this.renderForm()}
      </div>
    );
  }

  render() {
    const {
      redirect,
      open,
      dossierStackWasCreated,
      outletId,
      id,
      requestInProgress,
    } = this.state;
    const { resources } = this.props;

    return (
      <div>
        {redirect && (
          <DelayedRedirect
            to={{
              pathname: redirect,
              state: { refreshGrid: dossierStackWasCreated, outletId },
            }}
            delay={250}
          />
        )}
        <Dialog
          fullScreen
          open={open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className="position-sticky ">
            <Toolbar>
              <IconButton
                id="close-add-dossierStack"
                edge="start"
                color="inherit"
                onClick={this.handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" style={{ flex: 1 }}>
                {id !== undefined && id > 0
                  ? resources["EditDossierStackTitle"]
                  : resources["AddNewDossierStackTitle"]}
              </Typography>
              <Button
                id="dossierStack-save"
                color="inherit"
                disabled={requestInProgress}
                onClick={this.handleSubmit}
              >
                {resources["Save"]}
              </Button>
            </Toolbar>
          </AppBar>
          <div className="container">{this.renderMain()}</div>
        </Dialog>
      </div>
    );
  }
}
