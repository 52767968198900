import React, { Component, forwardRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import MaterialTable from 'material-table';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Loader from '../../../Loader.js';
import Form from 'react-bootstrap/Form';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Paper from '@material-ui/core/Paper';

import CircularProgress from '@material-ui/core/CircularProgress';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Button from '@material-ui/core/Button';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { LocalizationTheme } from '../../../../utils/utils.js';
import { DisplayMessage } from '../../../../services/helpers.js';
import FontDownloadRoundedIcon from '@material-ui/icons/FontDownloadRounded';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Block from '@material-ui/icons/Block';

import { SelectTheme } from '../../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    FontDownloadRoundedIcon: forwardRef((props, ref) => <FontDownloadRoundedIcon {...props} ref={ref} />)
};

export default class IndicatorsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataGrid: [],
            outletId: 0,
            fetchingData: true,
            showDelete: false,
            showCopy: false,
            deletedItem: 0,
            copiedIndicator: undefined,
            accessibleMoboOutlets: [],
            editedIndicatorId: undefined,
            editedIndicator: undefined,
            showEditIndicator: false,
            requestInProgress: false,
            copiedOutletId: 0,

        };
    }

    componentWillReceiveProps(newProps) {
        if (newProps.refreshGrid && newProps.outletId !== undefined) {
            this.getData(newProps.outletId);
            this.setState({ outletId: newProps.outletId })
        }

        this.setState({ redirect: null });
    }

    componentDidMount() {
        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;
        var outlet = outlets[0].id;
        this.setState({ outletId: outlet })
        this.getData(outlet);
    }


    handleChangeOutlet = e => {
        this.setState({ outletId: e.target.value, copiedOutletId: e.target.value }, () => this.getData(this.state.outletId));
    };

    handleChangeCopiedOutlet = e => {
        this.setState({ copiedOutletId: e.target.value });
    };

    getData = (outletId) => {
        this.setState({ fetchingData: true });
        let initialData = {};
        var resources = this.props.resources;
        initialData.columns = [
            {
                title: resources["Color"], field: 'colorHex', width: "15%", render: rowData => {
                    if (/^[0-9A-F]{6}$/i.test(rowData.colorHex)) {
                        return <div id={"color-" + rowData.colorHex} className="color-card" style={{ backgroundColor: "#" + rowData.colorHex }}>
                        </div>
                    }
                    else {
                        return <div id="color-none" className="color-card">
                            <Block />
                        </div>
                    }
                }
            },

            { title: resources["Description"], field: 'description', customSort: (a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()) },
            { title: resources["IsActive"], field: 'isActive', customSort: (a, b) => a.isActive.toLowerCase().localeCompare(b.isActive.toLowerCase()) },
        ];

        initialData.actions = [
            {
                icon: () => <Edit />,
                tooltip: resources["Edit"],
                onClick: (event, rowData) => this.setState({ redirect: `indicators/${rowData.id}` })
            },
            {
                icon: () => <DeleteOutline />,
                tooltip: resources["Delete"],
                onClick: (event, rowData) => this.handleShowDelete(rowData.id)
            },
            {
                icon: () => <FileCopyIcon />,
                tooltip: resources["Copy"],
                onClick: (event, rowData) => this.handleShowCopy(rowData)
            }
        ]


        if (outletId === 0) return;
        fetch(`/api/mobopro/v1/workshopplanningindicators?moid=${outletId}`,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {
                if (!response.ok) { throw new Error(); }
                return response.json();
            }).then(result => {
                const currentRows = [];

                result.forEach(function (rowData) {
                    currentRows.push({
                        id: rowData.id,
                        description: rowData.description,
                        isActive: rowData.isActive ? resources["Yes"] : resources["No"],
                        colorHex: rowData.colorHex,
                    });
                });

                initialData.data = currentRows;

                this.setState({
                    dataGrid: initialData,
                    fetchingData: false
                });
            });
    }

    handleCloseDelete = () => {
        this.setState({ showDelete: false, deletedItem: undefined });
    }

    handleCloseCopy = () => {
        this.setState({ showCopy: false, copiedIndicator: undefined, copiedDescription: "" });
    }

    handleEditIndicator = (itemId, showEditIndicatorDialog) => {
        if (showEditIndicatorDialog === false) { this.getData(this.state.outletId); }
        this.setState({ showEditIndicator: showEditIndicatorDialog, editedIndicatorId: itemId });

    }

    handleShowDelete = (itemId) => {
        this.setState({ showDelete: true, deletedItem: itemId });
    }

    handleShowCopy = (item) => {
        this.setState({ showCopy: true, copiedIndicator: item, copiedDescription: item.description });
    }

    handleDeleteIndicator = () => {
        if (this.state.deletedItem > 0) {
            let resources = this.props.resources;
            this.setState({ requestInProgress: true });
            const url = "/api/mobopro/v1/workshopplanningindicators/" + this.state.deletedItem;
            fetch(url, {
                method: 'DELETE',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            }).then(response => {
                if (!response.ok) {
                    if (response.statusText.length > 0) {
                        DisplayMessage(response.statusText, "error");
                        return response.json();
                    }

                    for (var i = 0; i < response.errors.length; i++) {
                        DisplayMessage(response.errors[i], "error");
                    }
                }
            }).then(result => {
                DisplayMessage(resources["IndicatorWasDeleted"], "success");
                this.getData(this.state.outletId);
                this.setState({ showDelete: false, deletedItem: 0, requestInProgress: false });
                this.handleCloseDelete();

            });
        }
    }

    handleCopyIndicator = () => {
        if (this.state.copiedIndicator === undefined) return;
        let resources = this.props.resources;
        const url = "/api/mobopro/v1/workshopplanningindicators/" + this.state.copiedIndicator.id;
        fetch(url, {
            method: 'GET',

            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
        }).then(response => response.json()).then(result => {
            if (result.id !== undefined) {
                var json = JSON.stringify({
                    id: result.id,
                    moboOutletId: this.state.copiedOutletId,
                    description: result.description,
                    colorHex: result.colorHex,
                    dailyCapacity: result.dailyCapacity,
                    isFixedTimeslot: result.isFixedTimeslot,
                    isCompetence: result.isCompetence,
                    isActive: result.isActive,
                });

                const url = "/api/mobopro/v1/workshopplanningindicators";
                fetch(url, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'PublicKey': localStorage.getItem('publicKey'),
                        'Authorization-token': localStorage.getItem('authorizationToken'),
                    }),
                    body: json
                }).then(response => {
                    if (!response.ok) {
                        response.json().then(result => {
                            for (var i = 0; i < result.errors.length; i++) {
                                DisplayMessage(result.errors[i], "error");
                            }
                        });
                    }

                    else {
                        DisplayMessage(resources["IndicatorWasCopied"], "success");
                        this.setState({ showCopy: false, copiedItem: 0, requestInProgress: false });
                        this.handleCloseCopy();
                        this.getData(this.state.outletId);
                    }
                });
            }
        });
    }

    renderOutlets = () => {

        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;

        var outletOptions = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outletOptions;
    }


    render() {
        const { redirect, fetchingData } = this.state;
        const { resources } = this.props;

        if (redirect) { return <Redirect to={{ pathname: redirect, state: { outletId: this.state.outletId, refreshGrid: true } }} /> }

        return (
            <div>
                <div className="position-relative py-4">
                    <h3>{resources["PlanningIndicators"]}</h3>
                    <div className={"offcentered-loader " + (fetchingData ? 'fadeIn' : 'fadeOut')}><CircularProgress /></div>
                    <div className={"mt-4 " + (fetchingData ? 'fadeOut' : 'fadeIn')}>
                        <Form.Group className="main-dropdown" onClick={this.handleTyping}>
                            <FormControl className="col-md-12" variant="outlined" onChange={this.handleChangeOutlet}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="outlined-outlet-native-simple">{resources["Outlet"]}</InputLabel>
                                </ThemeProvider>
                                <Select
                                    native
                                    label={resources["Outlet"]}
                                    value={this.state.outletId}
                                    inputProps={{
                                        name: 'outlet',
                                        id: 'outlined-outlet-native-simple',
                                    }}>
                                    {this.renderOutlets()}
                                </Select>
                            </FormControl>
                        </Form.Group>
                        <div style={{ float: "right", marginBottom: "10px" }}>
                            <Link to={{ pathname: "/indicators/new", state: { outletId: this.state.outletId } }} style={{ textDecoration: 'none' }}>
                                <Button id="add-indicator" variant="contained" color="primary">
                                    <i className="fas fa-plus mr-2" title={resources["AddNewIndicator"]} />
                                    {resources["AddNewIndicator"]}
                                </Button>
                            </Link >
                        </div>
                        <div style={{ clear: "both", }}></div>

                        <Paper elevation={3} className="p-3 mt-3">
                            <MuiThemeProvider theme={LocalizationTheme()}>
                                <MaterialTable
                                    icons={tableIcons}
                                    title={""}
                                    localization={{
                                        header: { actions: resources["Actions"] },
                                        body: {
                                            emptyDataSourceMessage: resources["NoRecordsToBeDisplayed"],
                                            deleteTooltip: resources["DeleteTooltip"],
                                            editTooltip: resources["EditTooltip"],
                                        },
                                        toolbar: {
                                            searchPlaceholder: resources["Search"],
                                            searchTooltip: resources["Search"]
                                        },
                                        pagination: {
                                            firstTooltip: resources["FirstTooltip"],
                                            lastTooltip: resources["LastTooltip"],

                                            previousTooltip: resources["PreviousTooltip"],
                                            nextTooltip: resources["NextTooltip"],

                                            labelRowsPerPage: resources["LabelRowsPerPage"],
                                            labelRowsSelect: resources["LabelRowsSelect"],
                                        }
                                    }}
                                    data={this.state.dataGrid.data}
                                    columns={this.state.dataGrid.columns}
                                    actions={this.state.dataGrid.actions}
                                    options={{ emptyRowsWhenPaging: false, pageSize: 10, pageSizeOptions: [10, 15, 20] }}
                                />
                            </MuiThemeProvider>
                        </Paper>
                    </div>
                    <div className={"loader-overlap position-absolute " + (this.state.fetchingData ? 'fadeIn' : 'fadeOut')}><div className="container"><Loader /></div></div>

                    <Rodal visible={this.state.showDelete} onClose={this.handleCloseDelete} className="rodal" customStyles={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                    }}>
                        <div className="modal-body" style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>{resources["AreYouSureYouWantToDeleteIndicator"]}</div>
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <button id="btnCancelDelete" className="btn btn-secondary" id="btnCancelDelete" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                                onClick={this.handleCloseDelete} disabled={this.state.requestInProgress}>{resources["Cancel"]}</button>
                            <button className="btn btn-primary" id="btnDelete" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }}
                                onClick={this.handleDeleteIndicator} disabled={this.state.requestInProgress}>{resources["Yes"]}</button>

                        </div>
                        <span className="rodal-close"></span>
                    </Rodal>

                    <Rodal visible={this.state.showCopy} onClose={this.handleCloseCopy} className="rodal" style={{ height: "350px!important" }} >

                        <div className="modal-body" >{resources["CopyIndicator"]}</div>
                        <Form.Group style={{ display: "flex" }} onClick={this.handleTyping}>
                            <Form.Label className="col-md-10 text-left label-mt my-auto" >{this.state.copiedDescription}  </Form.Label>
                        </Form.Group>
                        <Form.Group style={{ display: "flex", paddingLeft: "15px" }} onClick={this.handleTyping}>
                            <FormControl className="col-8" variant="outlined" onChange={this.handleChangeCopiedOutlet}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="outlined-outlet-native-simple">{resources["Outlet"]}</InputLabel>
                                </ThemeProvider>
                                <Select
                                    native
                                    label={resources["Outlet"]}
                                    value={this.state.copiedOutletId}
                                    inputProps={{
                                        name: 'copiedOutletId',
                                        id: 'outlined-outlet-native-simple',
                                    }}>
                                    {this.renderOutlets()}
                                </Select>
                            </FormControl>
                        </Form.Group>
                        <div className="modal-footer" style={{ borderTop: "1px solid white" }}>
                            <button className="btn btn-secondary" id="btnCancelCopy" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                                onClick={this.handleCloseCopy} disabled={this.state.requestInProgress}>{resources["Cancel"]}</button>
                            <button className="btn btn-primary" id="btnCopy" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }}
                                onClick={this.handleCopyIndicator} disabled={this.state.requestInProgress}>{resources["Save"]}</button>
                        </div>
                        <span className="rodal-close"></span>
                    </Rodal>

                </div>
            </div >
        )
    };
}