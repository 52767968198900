import React, { Component } from 'react';
import { DisplayMessage } from '../services/helpers';

export class Home extends Component {

    constructor(props) {
        super(props);

        if (this.props.location && this.props.location.state && this.props.location.state.showPathNotAllowed === true) {
            DisplayMessage(props.resources["PathNotAllowed"], "error");
        }
    }

    static displayName = Home.name;

    render() {
        return (
            <div>
            </div>
        );
    }
}
