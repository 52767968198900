import { Button, styled } from "@material-ui/core";

export const OkButton = styled(Button)(({ theme }) => ({
    margin: 4,
    textTransform: 'none',
    font: 'inherit',
    fontSize: '16px',
    fontWeight: '400',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    boxShadow: 'none',
    '&:hover': {
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main
    },
    "&:disabled": {
        boxShadow: 'none',
        opacity: 0.65,
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main
    }
}));

export const CancelButton = styled(Button)(({ theme }) => ({
    margin: 4,
    textTransform: 'none',
    font: 'inherit',
    fontSize: '16px',
    fontWeight: '400',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.primary.contrastText
    }
}));

