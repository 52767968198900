import { Box, styled } from '@material-ui/core';

export const ModalContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'absolute',
  margin: 'auto',
  width: 400,
  minHeight: 200,
  backgroundColor: theme.palette.primary.contrastText,
  boxShadow: 24,
  borderRadius: 2,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}));