import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import "toastr/build/toastr.min.css";
import { DisplayMessage, Uuidv4 } from "../../../../services/helpers.js";
import DelayedRedirect from "../../../../utils/DelayedRedirect";
import { Transition } from "../../../../utils/ModalHelpers";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import "toastr/build/toastr.min.css";
import Select from "@material-ui/core/Select";
import { SelectTheme } from "../../../../utils/utils.js";
import { ThemeProvider } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { PictureAsPdf } from "@material-ui/icons";
import { LanguagesWithLanguageCode } from "./../../../../utils/constants";
import {
  CheckIfSasIsValid,
  GenerateSAS,
  UploadFileInStorage,
} from "../../../../services/uploadHelpers";
import { createSnippet } from "./services/snippetApisService.js";

const snippetTypes = Object.freeze({
  SnippetMediaFileNames: 5,
  SnippetPDFLibrary: 6,
});

const SnippetCreationV2 = (props) => {
  const { resources, language } = props;
  const [selectedLanguage, setSelectedLanguage] = useState(language);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [isDefaultLocked, setIsDefaultLocked] = useState(false);
  const [file, setFile] = useState(null);
  const [type, setType] = useState(0);
  const [state, setState] = useState({
    redirect: null,
    open: true,
    snippetWasCreated: false,
  });

  useEffect(() => {
    checkIfRequestIsValid(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, title, type, file, isDefaultLocked]);

  const checkIfRequestIsValid = (displayErrorMessage) => {
    const errors = [];

    if (title.trim().length === 0) {
      errors.push(resources["InvalidTitle"]);
    }

    if (
      content.trim().length === 0 &&
      type !== snippetTypes.SnippetMediaFileNames &&
      type !== snippetTypes.SnippetPDFLibrary
    ) {
      errors.push(resources["InvalidSnippet"]);
    }

    if (type === 0) {
      errors.push(resources["InvalidType"]);
    }

    if (displayErrorMessage && errors.length > 0) {
      errors.forEach((error) => DisplayMessage(error, "error"));
    }

    return errors.length === 0;
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      open: false,
      redirect: "/snippets",
    }));
  };

  const handleChangeType = (e) => {
    setType(parseInt(e.target.value));
    setIsDefaultLocked(false);
    setFile(null);
  };

  //dropzone
  const handleFileChange = (files) => {
    var firstfile = files.length > 0 ? files[0] : null;
    setFile(firstfile);
  };

  const uploadFile = async (file, body, error) => {
    const sas = await GenerateSAS(body);
    if (!CheckIfSasIsValid(sas)) {
      DisplayMessage(resources["ErrorUploadingFileToStorage"], "error");
      return undefined;
    }
    var blobPath = await UploadFileInStorage(
      file,
      sas[0],
      "application/pdf",
      error
    );
    return blobPath;
  };

  const saveNewSnippet = async () => {
    if (!checkIfRequestIsValid(true)) return;

    var newContent = content;
    var pdfFile = null;

    switch (type) {
      case snippetTypes.SnippetMediaFileNames:
        newContent = null;
        break;
      case snippetTypes.SnippetPDFLibrary:
        newContent = null;
        if (file === null) {
          DisplayMessage(resources["PleaseSelectAPdfFile"], "error");
          return;
        } else {
          var sasRequest = JSON.stringify({
            minutes: 5,
            entityType: "snippet",
            fileNames: [`${Uuidv4()}.pdf`],
          });

          var blobPath = await uploadFile(
            file,
            sasRequest,
            resources["ErrorOnLoadingFile"]
          );

          if (blobPath === undefined) {
            return;
          }
          pdfFile = {
            blobRelativePath: blobPath,
            type: 4,
          };
        }
        break;
      default:
        break;
    }

    var json = JSON.stringify({
      type: parseInt(type),
      language: selectedLanguage,
      title: title,
      content: newContent,
      isDefaultLocked: isDefaultLocked,
      file: pdfFile,
    });

    var result = await createSnippet(json);
    if (result !== undefined) {
      setState((prevState) => ({
        ...prevState,
        snippetWasCreated: true,
        redirect: "/snippets",
      }));
      DisplayMessage(resources["NewSnippetWasSaved"], "success");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await saveNewSnippet();
  };

  const renderTypesDropdown = () => {
    var snippetsTypes = [
      { name: resources["SnippetServiceQuote"], value: 1 },
      { name: resources["SnippetExternalDialog"], value: 2 },
      { name: resources["SnippetExternalDossierChat"], value: 3 },
      { name: resources["SnippetInternalDossierChat"], value: 4 },
      { name: resources["SnippetMediaFileNames"], value: 5 },
      { name: resources["SnippetPDFLibrary"], value: 6 },
    ].sort((a, b) => (a.name > b.name ? 1 : -1));

    snippetsTypes.unshift({
      name: resources["DefaultDropdownValue"],
      value: 0,
    });

    snippetsTypes = snippetsTypes.map((x) => (
      <option key={x.value} value={x.value}>
        {x.name}
      </option>
    ));
    return snippetsTypes;
  };

  const handlePreviewIcon = (fileObject, classes) => {
    const iconProps = {
      className: classes.image,
    };
    return <PictureAsPdf {...iconProps} />;
  };

  const generateLanguageOptions = () => {
    var languageOptions = LanguagesWithLanguageCode.map((x) => (
      <option key={x.code} value={x.code}>
        {x.Name}
      </option>
    ));

    return languageOptions;
  };

  const renderForm = () => {
    var helperTextTitle =
      title.trim().length === 0 ? "helper-text-red" : "helper-text-grey";
    var helperTextContent =
      content.trim().length <= 0 ? "helper-text-red" : "helper-text-grey";
    var displayPdf = file !== null ? "dropzone-hidden" : "dropzone";

    return (
      <div>
        <Paper elevation={3} className="p-4 mt-3 mb-3">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="d-flex">
              <div className="col-3"></div>
              <FormControl
                className="col-6 helper-text-red"
                variant="outlined"
                onChange={handleChangeType}
              >
                <ThemeProvider theme={SelectTheme}>
                  <InputLabel htmlFor="outlined-type-native-simple">
                    {resources["Type"]}
                  </InputLabel>
                </ThemeProvider>
                <Select
                  id="snippets-type"
                  native
                  label={resources["Type"]}
                  value={type}
                  inputProps={{
                    name: "type",
                    id: "snippets-type",
                  }}
                >
                  {renderTypesDropdown()}
                </Select>
                <FormHelperText
                  className={type < 1 ? "color-red" : "color-grey"}
                >
                  {resources["SelectionOfTypeIsMandatory"]}
                </FormHelperText>
              </FormControl>
              <div className="col-3"></div>
            </Form.Group>
            <Form.Group className="d-flex">
              <div className="col-3"></div>
              <FormControl
                className="col-6 helper-text-red"
                variant="outlined"
                onChange={(event) => setSelectedLanguage(event.target.value)}
              >
                <ThemeProvider theme={SelectTheme}>
                  <InputLabel htmlFor="outlined-type-native-simple">
                    {resources["Language"]}
                  </InputLabel>
                </ThemeProvider>
                <Select
                  id="snippets-language"
                  native
                  label={resources["Language"]}
                  value={selectedLanguage}
                  inputProps={{
                    name: "language",
                    id: "snippets-language",
                  }}
                >
                  {generateLanguageOptions()}
                </Select>
              </FormControl>
              <div className="col-3"></div>
            </Form.Group>
            <Form.Group className="d-flex">
              <div className="col-3"></div>
              <ThemeProvider theme={SelectTheme}>
                <TextField
                  className={"col-6 " + helperTextTitle}
                  id="snippet-title"
                  value={title}
                  label={resources["Title"]}
                  helperText={resources["InputOfTitleIsMandatory"]}
                  variant="outlined"
                  name="title"
                  onChange={(event) => setTitle(event.target.value)}
                  inputProps={{ maxLength: 100 }}
                />
              </ThemeProvider>
            </Form.Group>
            <Form.Group className="d-flex">
              <div className="col-3"></div>
              <ThemeProvider theme={SelectTheme}>
                <TextField
                  className={"col-6 " + helperTextContent}
                  id="snippet-contnet"
                  value={content}
                  multiline
                  minRows={4}
                  label={resources["Snippet"]}
                  helperText={
                    type === snippetTypes.SnippetMediaFileNames ||
                    type === snippetTypes.SnippetPDFLibrary
                      ? ""
                      : resources["InputOfSnippetIsMandatory"]
                  }
                  autoComplete="off"
                  variant="outlined"
                  name="content"
                  onChange={(event) => setContent(event.target.value)}
                  disabled={
                    type === snippetTypes.SnippetMediaFileNames ||
                    type === snippetTypes.SnippetPDFLibrary
                  }
                />
              </ThemeProvider>
            </Form.Group>
            <Form.Group
              className={
                type !== snippetTypes.SnippetMediaFileNames &&
                type !== snippetTypes.SnippetPDFLibrary
                  ? "d-none"
                  : "d-flex"
              }
            >
              <div className="col-3 "> </div>
              <ThemeProvider theme={SelectTheme}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="snippet-isDefaultLocked"
                      checked={isDefaultLocked}
                      onChange={(event) =>
                        setIsDefaultLocked(event.target.checked)
                      }
                      name="isDefaultLocked"
                      color="primary"
                    />
                  }
                  label={resources["IsDefaultLocked"]}
                />
              </ThemeProvider>
            </Form.Group>
            <Form.Group
              className={
                type === snippetTypes.SnippetPDFLibrary ? "d-flex" : "d-none"
              }
            >
              <div className="col-3"></div>
              <ThemeProvider theme={SelectTheme}>
                <DropzoneArea
                  className="col-4"
                  acceptedFiles={[".pdf"]}
                  filesLimit={1}
                  showAlerts={false}
                  onAlert={(message, variant) =>
                    variant === "error"
                      ? DisplayMessage(message, variant)
                      : undefined
                  }
                  showPreviews={true}
                  previewText=""
                  getPreviewIcon={handlePreviewIcon}
                  dropzoneClass={displayPdf}
                  dropzoneParagraphClass={"dropzone-p"}
                  maxFileSize={30000000}
                  dropzoneText={resources["PDFDocument"]}
                  onChange={handleFileChange}
                />
              </ThemeProvider>
            </Form.Group>
          </Form>
        </Paper>
      </div>
    );
  };

  const { redirect, open, snippetWasCreated } = state;
  return (
    <div>
      {redirect && (
        <DelayedRedirect
          to={{
            pathname: redirect,
            state: { refreshGrid: snippetWasCreated },
          }}
          delay={250}
        />
      )}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className="position-sticky ">
          <Toolbar>
            <IconButton
              id="close-add-Snippet"
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" style={{ flex: 1 }}>
              {resources["AddNewSnippetTitle"]}
            </Typography>
            <Button id="Snippet-save" color="inherit" onClick={saveNewSnippet}>
              {resources["Save"]}
            </Button>
          </Toolbar>
        </AppBar>
        <div className="container">
          <div className={"py-4 grey fadeIn"} style={{ textAlign: "center" }}>
            {renderForm()}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default SnippetCreationV2;
