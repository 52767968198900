export const LanguagesWithLanguageCode = [
    {Name: "Netherlands", code: "nl"},
    {Name: "Français", code: "fr"},
    {Name: "English", code: "en"},
    {Name: "Deutsch", code: "de"},
    {Name: "Czech", code: "cs"},
]

export const HttpMethods = {
    Get: "GET",
    Post: "POST",
    Put: "PUT",
    Patch: "PATCH",
    Delete: "DELETE"
}