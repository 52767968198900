import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import 'toastr/build/toastr.min.css';
import { DisplayMessage, ExcludeSpecialCharactersFromInputTypeNumber } from '../../../services/helpers.js';
import DelayedRedirect from '../../../utils/DelayedRedirect';
import { Transition } from '../../../utils/ModalHelpers';
import { Button, FormHelperText } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import 'toastr/build/toastr.min.css';
import Select from '@material-ui/core/Select';

import { SelectTheme } from '../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";
import { DropzoneArea } from 'material-ui-dropzone';
import Link from '@material-ui/core/Link';
import Rodal from 'rodal';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

export default class OutletUserCreation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            email: "",
            firstName: "",
            lastName: "",
            initials: "",
            password: "",
            language: "nl-be",
            gender: "m",
            phone: "",
            pin: "",
            workPreparationRoles: [],
            workPreparationRoleId: 0,

            profilePicture: "",
            internalDialogName: "",
            internalPhoneNumber: "",
            clockReference: "",
            planningReference: "",
            file: undefined,
            isActive: false,

            accessibleMoboOutlets: [],
            requestInProgress: true,
            redirect: null,
            open: true,
            outletUserWasCreated: false,

            showRodalOutlets: false,
            mainOutlet: 0,
            savedMainOutlet: 0,
            selectedOutlets: [],
            selectedOutletsName: "",
            selectedOutletsChecked: [],
            selectedOutletsCheckedName: "",

            roles: [],
            showRodalRoles: false,
            selectedRoles: [],
            selectedRolesName: "",
            selectedRolesChecked: [],
            selectedRolesCheckedName: "",

            permissions: [],
            showRodalPermissions: false,
            selectedPermissions: [],
            selectedPermissionsName: "",
            selectedPermissionsChecked: [],
            selectedPermissionsCheckedName: "",

            chatGroups: [],
            showRodalChatGroups: false,
            selectedChatGroups: [],
            selectedChatGroupsName: "",
            selectedChatGroupsChecked: [],
            selectedChatGroupsCheckedName: "",

            dossierStacks: [],
            showRodalDossierStacks: false,
            selectedDossierStacks: [],
            selectedDossierStacksName: "",
            selectedDossierStacksChecked: [],
            selectedDossierStacksCheckedName: "",

            loadingOutlets: true,
            loadingRoles: true,
            loadingPermisions: true,
            loadingChatGroups: true,
            loadingDossierStacks: true
        };
    }

    componentDidMount() {
        this.getWorkPreparationRoles();
        this.getRoles();
        this.getPermissions();
    }

    handleClose = () => {
        this.setState({ open: false, redirect: "/outletusers" });
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value }, () => this.handleValidation());
    };

    handleChangePin = e => {
        this.setState({ [e.target.name]: e.target.value === "" ? "" : parseInt(e.target.value) }, () => this.handleValidation());
    };

    handleChangeName = e => {
        this.setState({ [e.target.name]: e.target.value, initials: "" }, () => this.handleValidation());
    };

    handleChangeChatName = e => {
        this.setState({ [e.target.name]: e.target.value.trim() }, () => this.handleValidation());
    };

    handleChangeWorkPreparationRoles = e => {
        this.setState({ workPreparationRoleId: parseInt(e.target.value) });
    };

    handleValidation = () => {
        this.setState({ requestInProgress: !this.checkIfRequestIsValid(false) });
    }

    checkIfEmailIsValid(displayErrorMessage) {
        let resources = this.props.resources;

        const filterEmail = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,63})$/;
        const isEmailValid = filterEmail.test(this.state.email);

        if (!isEmailValid) {
            if (displayErrorMessage) DisplayMessage(resources["InvalidEmail"], "error");
        }

        return isEmailValid;
    }

    checkIfPasswordIsValid() {
        const regexForValidation = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[%!@#$%^&*()?>.<,:;'|}\]{[_~`+\-=\\""])(?!.*\s).{8,14}$/;
        const password = this.state.password;

        if (password === '') return true;
        return regexForValidation.test(password);
    }

    checkIfPinIsValid() {
        const pin = this.state.pin;
        if (pin === "" || pin === 0) return true;
        if (pin < 1000 || pin > 9999) return false;

        var filterPin = /^[0-9]{4}$/;
        return filterPin.test(pin);
    }

    checkIfPhoneIsValid(phone) {
        if (phone === "") return true;

        const filterPhone = /[0-9+]{1,20}$/;
        return filterPhone.test(phone);
    }

    checkIfInternalDialogNameIsValid(internalDialogName) {
        if (internalDialogName === "") return true;

        const filterChatName = /^[\p{L}0-9]+$/gu;
        return filterChatName.test(internalDialogName);
    }

    checkIfRequestIsValid(displayErrorMessage) {
        let resources = this.props.resources;
        let isValidRequest = true;

        isValidRequest = this.checkIfEmailIsValid(displayErrorMessage);

        if (this.state.firstName.trim().length <= 0) {
            if (displayErrorMessage) DisplayMessage(resources["InvalidFirstName"], "error");
            isValidRequest = false;
        }

        if (this.state.lastName.trim().length <= 0) {
            if (displayErrorMessage) DisplayMessage(resources["InvalidLastName"], "error");
            isValidRequest = false;
        }

        if (this.state.password.length > 0) {
            var isPasswordValid = this.checkIfPasswordIsValid();

            if (!isPasswordValid) {
                if (displayErrorMessage) displayErrorMessage(resources["InvalidPassword"], "error");
                isValidRequest = false;
            }
        }

        if (this.state.pin !== "" && this.state.pin !== 0) {
            const isPinValid = this.checkIfPinIsValid();
            if (!isPinValid) {
                if (displayErrorMessage) DisplayMessage(resources["InvalidPin"], "error");
                isValidRequest = false;
            }
        }

        if (this.state.phone !== undefined && this.state.phone !== "") {
            const isPhoneValid = this.checkIfPhoneIsValid(this.state.phone);
            if (!isPhoneValid) {
                if (displayErrorMessage) DisplayMessage(resources["InvalidPhone"], "error");
                isValidRequest = false;
            }
        }

        if (this.state.internalPhoneNumber !== undefined && this.state.internalPhoneNumber !== "") {
            const isPhoneValid = this.checkIfPhoneIsValid(this.state.internalPhoneNumber);
            if (!isPhoneValid) {
                if (displayErrorMessage) DisplayMessage(resources["InvalidInternalPhoneNumber"], "error");
                isValidRequest = false;
            }
        }

        if (this.state.internalDialogName !== undefined) {
            const isInternaDialogNameValid = this.checkIfInternalDialogNameIsValid(this.state.internalDialogName);
            if (!isInternaDialogNameValid) {
                if (displayErrorMessage) DisplayMessage(resources["InvalidChatName"], "error");
                isValidRequest = false;
            }
        }

        if (this.state.mainOutlet < 1 || this.state.selectedRolesChecked.length < 1) {
            if (displayErrorMessage) DisplayMessage(resources["InvalidOutletsOrRoles"], "error");
            isValidRequest = false;
        }

        return isValidRequest;
    }

    generateInitials = (a) => {
        a = a.replace('.', '');
        a = a.replace('Jr', '');
        a = a.replace('Sr', '');
        a = a.replace('-', ' ');

        var c = a.split(" ");
        var res = c.map((a, b) => b < c.length - 1 ? a.substring(0, 1) : a.substring(0, 1))
        return res.join("").toUpperCase();
    }

    getWorkPreparationRoles = () => {
        const url = "/api/mobopro/v1/workpreparations/roles";
        fetch(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            })
        })
            .then(response => response.json())
            .then(result => {
                const workPreparationRoles = [];

                result.forEach(function (item) {
                    workPreparationRoles.push({
                        id: item.id,
                        description: item.description,
                        isActive: item.isActive
                    });
                });

                var workPreparationRolesActive = workPreparationRoles.filter(x => x.isActive === true);
                var workPreparationRolesSorted = workPreparationRolesActive.sort((a, b) => (a.description > b.description) ? 1 : -1);


                this.setState({
                    workPreparationRoles: workPreparationRolesSorted
                });
            });
    }

    //(1) : input of personal data
    saveNewOutletUser = () => {
        this.setState({ requestInProgress: true });

        let resources = this.props.resources;

        if (!this.checkIfRequestIsValid(true)) return;

        var json = JSON.stringify({
            username: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            initials: this.state.initials === "" ? this.generateInitials(this.state.firstName.trim() + " " + this.state.lastName.trim()) : this.state.initials,
            phone: this.state.phone === "" ? null : this.state.phone,
            internalPhoneNumber: this.state.internalPhoneNumber === "" ? null : this.state.internalPhoneNumber,
            gender: this.state.gender,
            pin: this.state.pin,
            internalDialogName: this.state.internalDialogName === "" ? null : this.state.internalDialogName,
            workPreparationRoleId: this.state.workPreparationRoleId !== undefined && this.state.workPreparationRoleId > 0 ? this.state.workPreparationRoleId : null,
            dmsReference: this.state.dmsReference,
            dmsReference2: this.state.dmsReference2,
            clockReference: this.state.clockReference === "" ? null : this.state.clockReference,
            planningReference: this.state.planningReference === "" ? null : this.state.planningReference,
            culture: this.state.language,
            active: true,
            password: this.state.password === "" ? null : this.state.password
        });

        fetch('/api/mobopro/v1/outletuser', {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {
            response.json().then(result => {
                if (!response.ok) {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }

                }
                else {
                    DisplayMessage(resources["NewOutletUserWasSaved"], "success");

                    // Save linked outlets first, and after that image, linked roles, permissions, chat groups and dossier stacks
                    this.handleSaveOutletsWithPermissionsAndChatGroupsAndDossierStacks(result.outletUserId, () => {
                        this.setState({ outletUserWasCreated: true, redirect: "/outletusers" });
                    });

                }
            })
        });
    }

    handleSaveOutletUserProfileImage = (outletId) => {
        let resources = this.props.resources;

        if (outletId < 1 || this.state.file === undefined) return;

        var formData = new FormData();
        formData.append("image", this.state.file);

        fetch(`/api/mobopro/v1/outletuserimage/${outletId}`, {
            method: 'PUT',
            headers: new Headers({
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: formData
        }).then(response => {

            if (!response.ok) {
                response.json().then(result => {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                });
            }
            else {
                DisplayMessage(resources["OutletUserImageWasSaved"], "success");
            }
        });
    }

    handleSaveOutletsWithPermissionsAndChatGroupsAndDossierStacks = (outletUserId, redirectCallback) => {
        let resources = this.props.resources;

        if (outletUserId < 1 || this.state.mainOutlet < 1) return;

        var json = JSON.stringify({
            id: outletUserId,
            mainMoboOutletId: this.state.mainOutlet,
            moboOutletIds: this.state.selectedOutletsChecked,
        });

        fetch(`/api/mobopro/v1/outletuseroutlets`, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {
            if (!response.ok) {
                response.json().then(result => {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                });
            }
            else {
                this.handleSaveOutletUserProfileImage(outletUserId);
                this.handleSaveOutletUserRolesAndPermissions(outletUserId);
                this.handleSaveOutletUserChatGroups(outletUserId);
                this.handleSaveOutletUserDossierStacks(outletUserId);
                DisplayMessage(resources["OutletUserOutletsWasSaved"], "success");
                redirectCallback();
            }
        });
    }

    handleSaveOutletUserRolesAndPermissions = (outletUserId) => {
        let resources = this.props.resources;

        if (outletUserId < 1 || this.state.selectedRolesChecked.length < 1) return;
        var json = JSON.stringify({
            id: outletUserId,
            roleIDs: this.state.selectedRolesChecked,
            permissionIDs: this.state.selectedPermissionsChecked,
        });

        fetch(`/api/mobopro/v1/outletuserrolesandpermissions`, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {

            if (!response.ok) {
                response.json().then(result => {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                });
            }
            else {
                DisplayMessage(resources["OutletUserRolesAndPermissionsWasSaved"], "success");
            }
        });
    }

    handleSaveOutletUserChatGroups = (outletUserId) => {
        let resources = this.props.resources;

        if (outletUserId < 1 || this.state.selectedChatGroupsChecked.length === 0) return;

        var json = JSON.stringify({
            outletUserId: outletUserId,
            internalChatGroups: this.state.selectedChatGroupsChecked,
        });

        fetch(`/api/mobopro/v1/outletuserchatgroups`, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {

            if (!response.ok) {
                response.json().then(result => {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                });
            }
            else {
                DisplayMessage(resources["OutletUserChatGroupsWasSaved"], "success");
            }
        });
    }

    handleSaveOutletUserDossierStacks = (outletUserId) => {
        let resources = this.props.resources;

        if (outletUserId < 1) return;

        var json = JSON.stringify({
            id: outletUserId,
            stackIds: this.state.selectedDossierStacksChecked,
        });

        fetch(`/api/mobopro/v1/outletuserdossierstackaccess`, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {

            if (!response.ok) {
                response.json().then(result => {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                });
            }
            else {
                DisplayMessage(resources["OutletUserDossierStackAccessWasSaved"], "success");
            }
        });
    }

    //dropzone
    handleFileChange = (files) => {
        var firstfile = files.length > 0 ? files[0] : undefined;
        this.setState({
            file: firstfile
        });
    }

    // Mobo Outlets
    handleSelectOutlet = e => {
        var { mainOutlet, selectedOutletsChecked, selectedOutletsCheckedName } = this.state;
        var selectedOutletId = parseInt(e.target.name);

        var outletDesc = this.props.accessibleMoboOutlets.find(x => x.id === selectedOutletId).description;

        if (e.target.checked) {
            selectedOutletsCheckedName = selectedOutletsCheckedName + (selectedOutletsCheckedName !== "" ? ", " + outletDesc : outletDesc);
            this.setState({
                selectedOutletsChecked: [...this.state.selectedOutletsChecked, selectedOutletId], selectedOutletsCheckedName,
                selectedChatGroupsChecked: [], selectedChatGroupsCheckedName: "",
                selectedDossierStacksChecked: [], selectedDossierStacksCheckedName: "",
                requestInProgress: !this.checkIfRequestIsValid(false),
            });
        }
        else {
            var index = selectedOutletsChecked.indexOf(selectedOutletId);
            if (selectedOutletId !== mainOutlet && index !== -1) {
                selectedOutletsChecked = selectedOutletsChecked.filter((_, i) => i !== index);
                selectedOutletsCheckedName = selectedOutletsCheckedName.replace(", " + outletDesc, "").replace(outletDesc + ", ", "").replace(outletDesc, "");
                this.setState({ selectedOutletsChecked, selectedOutletsCheckedName, requestInProgress: !this.checkIfRequestIsValid(false) });
            }
        }

    };

    handleChangeMainOutlet = e => {
        var { selectedOutletsChecked, selectedOutletsCheckedName } = this.state;
        var mainOutlet = parseInt(e.target.value);

        if (selectedOutletsChecked.indexOf(mainOutlet) === -1 && mainOutlet > 0) {
            if (selectedOutletsCheckedName !== "") selectedOutletsCheckedName = selectedOutletsCheckedName + ", ";

            selectedOutletsCheckedName = selectedOutletsCheckedName + this.props.accessibleMoboOutlets.find(x => x.id === mainOutlet).description;
            this.setState({
                selectedOutletsChecked: [...this.state.selectedOutletsChecked, mainOutlet], selectedOutletsCheckedName,
                selectedChatGroupsChecked: [], selectedChatGroupsCheckedName: "", mainOutlet,
                selectedDossierStacksChecked: [], selectedDossierStacksCheckedName: "",
                requestInProgress: !this.checkIfRequestIsValid(false)
            });
        }
        else {
            this.setState({ mainOutlet, requestInProgress: !this.checkIfRequestIsValid(false) });
        }
    };

    renderSelectMainOutlet = () => {

        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;

        outlets = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outlets;
    }

    renderSelectOutlets = () => {
        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;
        outlets = outlets.map(x =>

            <Form.Group className=" mb-0 text-left" >
                <FormControlLabel className="col-md-11 text-right label-mt my-auto p-0"
                    control={<Checkbox id="indicators-is-active" style={{ padding: "5px 9px!important" }} checked={this.state.selectedOutletsChecked.indexOf(x.id) !== -1}
                        onChange={this.handleSelectOutlet} name={x.id} value={x.id} color="primary" />}
                    label={x.description} />
            </Form.Group>
        );
        return outlets;
    }

    handleShowRodalOutlets = (showModal) => {
        var { selectedOutlets, selectedOutletsName, savedMainOutlet } = this.state;
        if (showModal === false) this.setState({ showRodalOutlets: showModal, selectedOutletsChecked: selectedOutlets, selectedOutletsCheckedName: selectedOutletsName, mainOutlet: savedMainOutlet });
        this.setState({ showRodalOutlets: showModal });
    }

    handleConfirmSelectedOutlets = () => {
        let resources = this.props.resources;

        var { selectedOutletsChecked, selectedOutletsCheckedName, mainOutlet } = this.state;

        if (this.state.selectedOutletsChecked.length > 0) {
            this.getChatGroups(false);
            this.getDossierStacks(false);

            var numberOfChatGroups = this.state.selectedChatGroups.length;
            var numberOfDossierStacks = this.state.selectedDossierStacks.length;

            this.setState({
                showRodalOutlets: false,
                selectedChatGroups: [], selectedChatGroupsName: "",
                selectedDossierStacks: [], selectedDossierStacksName: "",
                requestInProgress: !this.checkIfRequestIsValid(false),
                selectedOutlets: selectedOutletsChecked,
                selectedOutletsName: selectedOutletsCheckedName,
                savedMainOutlet: mainOutlet,
                loadingChatGroups: true,
                loadingDossierStacks: true,
            });

            if (numberOfChatGroups > 0) {
                this.setState({ selectedChatGroupsChecked: [], selectedChatGroupsCheckedName: "" });
                DisplayMessage(resources["SelectedChatGoupsWasReset"], "info");
            }

            if (numberOfDossierStacks > 0) {
                this.setState({ selectedDossierStacksChecked: [], selectedDossierStacksCheckedName: "" });
                DisplayMessage(resources["SelectedDossierStacksWasReset"], "info");
            }
        }
    }
    //Roles
    handleSelectRoles = e => {
        var { selectedRolesChecked, selectedRolesCheckedName, roles } = this.state;

        var selectedRoleId = parseInt(e.target.name);
        var roleDesc = roles.find(x => x.id === selectedRoleId).description;

        if (e.target.checked) {
            selectedRolesCheckedName = selectedRolesCheckedName + (selectedRolesCheckedName !== "" ? ", " + roleDesc : roleDesc);
            this.setState({ selectedRolesChecked: [...this.state.selectedRolesChecked, selectedRoleId], selectedRolesCheckedName, requestInProgress: !this.checkIfRequestIsValid(false) });
        }
        else {
            var index = selectedRolesChecked.indexOf(selectedRoleId);
            if (index !== -1) {
                selectedRolesChecked = selectedRolesChecked.filter((_, i) => i !== index);
                selectedRolesCheckedName = selectedRolesCheckedName.replace(", " + roleDesc, "").replace(roleDesc + ", ", "").replace(roleDesc, "");
                this.setState({ selectedRolesChecked, selectedRolesCheckedName, requestInProgress: !this.checkIfRequestIsValid(false) });
            }
        }
    };

    getRoles = () => {

        const url = "/api/mobopro/v1/outletuserroles/";
        fetch(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            })
        })
            .then(response => response.json())
            .then(result => {
                const roles = [];

                result.forEach(function (item) {
                    roles.push({
                        id: item.id,
                        description: item.description,
                    });
                });
                var rolesSorted = roles.sort((a, b) => (a.description > b.description) ? 1 : -1);

                this.setState({
                    roles: rolesSorted,
                    loadingRoles: false
                });

            });
    }

    renderSelectRoles = () => {
        var rolesSorted = this.state.roles;

        var rolesMapped = rolesSorted.map(x =>

            <Form.Group className=" mb-0 text-left" >
                <FormControlLabel className="col-md-11 text-right label-mt my-auto p-0"
                    control={<Checkbox id="indicators-is-active" style={{ padding: "5px 9px!important" }} checked={this.state.selectedRolesChecked.indexOf(x.id) !== -1}
                        onChange={this.handleSelectRoles} name={x.id} color="primary" />}
                    label={x.description} />
            </Form.Group>
        );
        return rolesMapped;
    }

    handleShowRodalRoles = (showModal) => {
        var { selectedRoles, selectedRolesName } = this.state;
        if (showModal === false) this.setState({ showRodalRoles: showModal, selectedRolesChecked: selectedRoles, selectedRolesCheckedName: selectedRolesName });
        this.setState({ showRodalRoles: showModal });
    }

    handleConfirmSelectedRoles = () => {
        var { selectedRolesChecked, selectedRolesCheckedName } = this.state;

        if (this.state.selectedRolesChecked.length > 0) {
            this.setState({ showRodalRoles: false, selectedRoles: selectedRolesChecked, selectedRolesName: selectedRolesCheckedName, requestInProgress: !this.checkIfRequestIsValid(false) });
        }
    }

    //Permissions
    handleSelectPermissions = e => {
        var { selectedPermissionsChecked, selectedPermissionsCheckedName, permissions } = this.state;

        var selectedPermissionId = parseInt(e.target.name);
        var permissionDesc = permissions.find(x => x.id === selectedPermissionId).description;

        if (e.target.checked) {
            selectedPermissionsCheckedName = selectedPermissionsCheckedName + (selectedPermissionsCheckedName !== "" ? ", " + permissionDesc : permissionDesc);
            this.setState({ selectedPermissionsChecked: [...this.state.selectedPermissionsChecked, selectedPermissionId], selectedPermissionsCheckedName });
        }
        else {
            var index = selectedPermissionsChecked.indexOf(selectedPermissionId);
            if (index !== -1) {
                selectedPermissionsChecked = selectedPermissionsChecked.filter((_, i) => i !== index);
                selectedPermissionsCheckedName = selectedPermissionsCheckedName.replace(", " + permissionDesc, "").replace(permissionDesc + ", ", "").replace(permissionDesc, "");
                this.setState({ selectedPermissionsChecked, selectedPermissionsCheckedName });
            }
        }
    };

    getPermissions = () => {

        const url = "/api/mobopro/v1/outletuserpermissions";
        fetch(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            })
        })
            .then(response => response.json())
            .then(result => {
                const permissions = [];

                result.forEach(function (item) {
                    permissions.push({
                        id: item.id,
                        description: item.description,
                    });
                });
                var permissionsSorted = permissions.sort((a, b) => (a.description > b.description) ? 1 : -1);

                this.setState({
                    permissions: permissionsSorted,
                    loadingPermisions: false,
                });

            });
    }

    renderPermissions = () => {
        var permissionsSorted = this.state.permissions;

        var permissionsMapped = permissionsSorted.map(x =>

            <Form.Group className=" mb-0 text-left" >
                <FormControlLabel className="col-md-11 text-right label-mt my-auto p-0"
                    control={<Checkbox id="indicators-is-active" style={{ padding: "5px 9px!important" }} checked={this.state.selectedPermissionsChecked.indexOf(x.id) !== -1}
                        onChange={this.handleSelectPermissions} name={x.id} color="primary" />}
                    label={x.description} />
            </Form.Group>
        );
        return permissionsMapped;
    }

    handleShowRodalPermissions = (showModal) => {
        var { selectedPermissions, selectedPermissionsName } = this.state;
        if (showModal === false) this.setState({ showRodalPermissions: showModal, selectedPermissionsChecked: selectedPermissions, selectedPermissionsCheckedName: selectedPermissionsName });
        else this.setState({ showRodalPermissions: showModal });
    }

    handleConfirmSelectedPermissions = () => {
        var { selectedPermissionsChecked, selectedPermissionsCheckedName } = this.state;

        if (this.state.selectedPermissionsChecked.length >= 0) {
            this.setState({ showRodalPermissions: false, selectedPermissions: selectedPermissionsChecked, selectedPermissionsName: selectedPermissionsCheckedName, requestInProgress: !this.checkIfRequestIsValid(false) });
        }
    }

    //ChatGroups
    handleSelectChatGroups = e => {
        var { selectedChatGroupsChecked, selectedChatGroupsCheckedName, chatGroups } = this.state;
        var selectedChatGroupId = parseInt(e.target.name);
        var chatGroupDesc = chatGroups.find(x => x.id === selectedChatGroupId).description;

        if (e.target.checked) {
            selectedChatGroupsCheckedName = selectedChatGroupsCheckedName + (selectedChatGroupsCheckedName !== "" ? ", " + chatGroupDesc : chatGroupDesc);
            this.setState({ selectedChatGroupsChecked: [...this.state.selectedChatGroupsChecked, selectedChatGroupId], selectedChatGroupsCheckedName });
        }
        else {
            var index = selectedChatGroupsChecked.indexOf(selectedChatGroupId);
            if (index !== -1) {
                selectedChatGroupsChecked = selectedChatGroupsChecked.filter((_, i) => i !== index);
                selectedChatGroupsCheckedName = selectedChatGroupsCheckedName.replace(", " + chatGroupDesc, "").replace(chatGroupDesc + ", ", "").replace(chatGroupDesc, "");
                this.setState({ selectedChatGroupsChecked, selectedChatGroupsCheckedName });
            }
        }
    };

    getChatGroups = (showModal) => {

        var selectedOutlets = this.state.selectedOutletsChecked;
        if (selectedOutlets.length < 1) return;
        const url = "/api/mobopro/v1/internalchatgroups?includenonactive=false";
        fetch(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            })
        })
            .then(response => response.json())
            .then(result => {
                const chatGroups = [];

                result.forEach(function (item) {

                    if (item.moboOutletID === null || selectedOutlets.includes(item.moboOutletID)) {
                        chatGroups.push({
                            id: item.id,
                            description: item.description,
                        });
                    }
                });
                var chatGroupsSorted = chatGroups.sort((a, b) => (a.description > b.description) ? 1 : -1);

                this.setState({
                    chatGroups: chatGroupsSorted,
                    loadingChatGroups: false,
                });
            });
    }

    renderChatGroups = () => {
        var chatGroupsSorted = this.state.chatGroups;

        var chatGroupsMapped = chatGroupsSorted.map(x =>

            <Form.Group className=" mb-0 text-left" >
                <FormControlLabel className="col-md-11 text-right label-mt my-auto p-0"
                    control={<Checkbox id="indicators-is-active" style={{ padding: "5px 9px!important" }} checked={this.state.selectedChatGroupsChecked.indexOf(x.id) !== -1}
                        onChange={this.handleSelectChatGroups} name={x.id} color="primary" />}
                    label={x.description} />
            </Form.Group>
        );
        return chatGroupsMapped;
    }

    handleShowRodalChatGroups = (showModal) => {
        var { selectedChatGroups, selectedChatGroupsName } = this.state;

        if (this.state.selectedOutletsChecked.length > 0 && this.state.chatGroups.length < 1) {
            this.getChatGroups(true);
            this.setState({ showRodalChatGroups: showModal });
        }
        else if (showModal === false) this.setState({ showRodalChatGroups: showModal, selectedChatGroupsChecked: selectedChatGroups, selectedChatGroupsCheckedName: selectedChatGroupsName });
        else this.setState({ showRodalChatGroups: showModal });
    }

    handleConfirmSelectedChatGroups = () => {
        var { selectedChatGroupsChecked, selectedChatGroupsCheckedName } = this.state;
        this.setState({ showRodalChatGroups: false, selectedChatGroups: selectedChatGroupsChecked, selectedChatGroupsName: selectedChatGroupsCheckedName, requestInProgress: !this.checkIfRequestIsValid(false) });

    };

    //DossierStacks
    getDossierStacksCheckedNames = (selectedDossierStacksChecked, dossierStacks) => {
        var selectedDossierStacksCheckedNameList = [];
        selectedDossierStacksChecked.forEach(id => {
            var dossierStacksCheckedNameDesc = dossierStacks.find(x => x.id === id).description;
            selectedDossierStacksCheckedNameList.push(dossierStacksCheckedNameDesc);
        });
        return selectedDossierStacksCheckedNameList;
    }

    handleSelectDossierStacks = e => {
        var { selectedDossierStacksChecked, selectedDossierStacksCheckedName, dossierStacks } = this.state;
        var selectedDossierStackId = parseInt(e.target.name);
        var dossierStackDesc = dossierStacks.find(x => x.id === selectedDossierStackId).description;

        if (e.target.checked) {
            let selectedDossierStacksCheckedNameList = this.getDossierStacksCheckedNames(selectedDossierStacksChecked, dossierStacks);

            selectedDossierStacksCheckedNameList.push(dossierStackDesc);
            selectedDossierStacksCheckedName = selectedDossierStacksCheckedNameList.join(", ");

            this.setState({ selectedDossierStacksChecked: [...this.state.selectedDossierStacksChecked, selectedDossierStackId], selectedDossierStacksCheckedName });
        }
        else {
            var index = selectedDossierStacksChecked.indexOf(selectedDossierStackId);
            if (index !== -1) {
                selectedDossierStacksChecked = selectedDossierStacksChecked.filter((_, i) => i !== index);
                let selectedDossierStacksCheckedNameList = this.getDossierStacksCheckedNames(selectedDossierStacksChecked, dossierStacks);

                selectedDossierStacksCheckedName = selectedDossierStacksCheckedNameList.join(", ");
                this.setState({ selectedDossierStacksChecked, selectedDossierStacksCheckedName });
            }
        }
    };

    getDossierStacks = () => {
        var selectedOutlets = this.state.selectedOutletsChecked;
        if (selectedOutlets.length < 1) return;
        var outlets = this.props.accessibleMoboOutlets;

        const url = "/api/mobopro/v1/dossierstacks?isSettings=true";
        fetch(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            })
        })
            .then(response => response.json())
            .then(result => {
                const dossierStacks = [];

                result.forEach(function (item) {

                    if (item.outletId === null || selectedOutlets.includes(item.outletId)) {
                        dossierStacks.push({
                            id: item.id,
                            description: item.description,
                            fullDescription: item.description + (item.outletId == null ? "" : " (" + outlets.find(x => x.id === item.outletId).description + ") "),
                        });
                    }
                });
                var dossierStacksSorted = dossierStacks.sort((a, b) => (a.fullDescription > b.fullDescription) ? 1 : -1);

                this.setState({
                    dossierStacks: dossierStacksSorted,
                    loadingDossierStacks: false,
                });
            });
    }

    renderDossierStacks = () => {
        var dossierStacksSorted = this.state.dossierStacks;

        var dossierStacksMapped = dossierStacksSorted.map(x =>

            <Form.Group className=" mb-0 text-left" >
                <FormControlLabel className="col-md-11 text-right label-mt my-auto p-0"
                    control={<Checkbox id="indicators-is-active" style={{ padding: "5px 9px!important" }} checked={this.state.selectedDossierStacksChecked.indexOf(x.id) !== -1}
                        onChange={this.handleSelectDossierStacks} name={x.id} color="primary" />}
                    label={x.fullDescription} />
            </Form.Group>
        );
        return dossierStacksMapped;
    }

    handleShowRodalDossierStacks = (showModal) => {
        var { selectedDossierStacks, selectedDossierStacksName } = this.state;

        if (this.state.selectedOutletsChecked.length > 0 && this.state.dossierStacks.length < 1) {
            this.getDossierStacks(true);
            this.setState({ showRodalDossierStacks: showModal });
        }
        else if (showModal === false) this.setState({ showRodalDossierStacks: showModal, selectedDossierStacksChecked: selectedDossierStacks, selectedDossierStacksCheckedName: selectedDossierStacksName });
        else this.setState({ showRodalDossierStacks: showModal });
    }

    handleConfirmSelectedDossierStacks = () => {
        var { selectedDossierStacksChecked, selectedDossierStacksCheckedName } = this.state;
        this.setState({ showRodalDossierStacks: false, selectedDossierStacks: selectedDossierStacksChecked, selectedDossierStacksName: selectedDossierStacksCheckedName, requestInProgress: !this.checkIfRequestIsValid(false) });
    };

    renderWorkPreparationRoles = () => {

        const { workPreparationRoles } = this.state;
        if (workPreparationRoles === undefined) return;

        var wpRoles = workPreparationRoles.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return wpRoles;

    }

    renderForm() {
        const handleSubmit = (event) => {
            event.preventDefault();
            event.stopPropagation();
            this.saveNewOutletUser();
        };
        const { mainOutlet, email, firstName, lastName, language,
            initials, gender, phone, internalPhoneNumber, internalDialogName, pin, file, workPreparationRoleId, dmsReference, dmsReference2, clockReference, planningReference,
            loadingPermisions, loadingRoles, loadingChatGroups, loadingDossierStacks, password } = this.state;

        var resources = this.props.resources;
        var helperTextEmail = !this.checkIfEmailIsValid(false) ? "helper-text-red" : "helper-text-grey";
        var helperTextFirstName = firstName.trim().length <= 0 ? "helper-text-red" : "helper-text-grey";
        var helperTextLastName = lastName.trim().length <= 0 ? "helper-text-red" : "helper-text-grey";
        var helperTextPhone = !this.checkIfPhoneIsValid(phone) ? resources["InvalidPhone"] : '';
        var helperTextInternalPhoneNumber = !this.checkIfPhoneIsValid(internalPhoneNumber) ? resources["InvalidInternalPhoneNumber"] : '';
        var helperTextInternalDialogName = !this.checkIfInternalDialogNameIsValid(internalDialogName) ? resources["InvalidChatName"] : '';
        var helperTextPassword = !this.checkIfPasswordIsValid() ? resources["InvalidPassword"] : '';

        var selectedOutlets = this.state.selectedOutlets.length > 0 ? this.state.selectedOutletsName : resources["NoneSelectedYet"];
        var selectedRoles = this.state.selectedRoles.length > 0 ? this.state.selectedRolesName : resources["NoneSelectedYet"];
        var selectedPermissions = this.state.selectedPermissions.length > 0 ? this.state.selectedPermissionsName : resources["NoneSelectedYet"];
        var selectedChatGroups = this.state.selectedChatGroups.length > 0 ? this.state.selectedChatGroupsName : resources["NoneSelectedYet"];
        var selectedDossierStacks = this.state.selectedDossierStacks.length > 0 ? this.state.selectedDossierStacksName : resources["NoneSelectedYet"];
        var disaplyImage = file !== undefined ? "dropzone-hidden" : "dropzone";
        var displayChatGroups = "col-4 text-right float-right " + (this.state.mainOutlet > 0 ? " pointer " : "  disabled-hover text-secondary");
        var displayDossierStacks = "col-4 text-right float-right " + (this.state.mainOutlet > 0 ? " pointer " : "  disabled-hover text-secondary");
        return (
            <div>

                <Paper elevation={3} className="p-4 mt-3 mb-3">
                    <Form onSubmit={handleSubmit} autoComplete="off">
                        <Form.Group className="pt-3 d-flex"  >
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className={"col-6 " + helperTextEmail} id="outletuser-email" value={email} label={resources["Email"]}
                                    helperText={resources["InputOfEmailIsMandatory"]} autoComplete="off"
                                    variant="outlined" name="email" onChange={this.handleChange} inputProps={{ maxLength: 200 }} />
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className=" d-flex"  >
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className={"col-6 " + helperTextFirstName} id="outletuser-firstname" value={firstName} label={resources["FirstName"]}
                                    helperText={resources["InputOfFirstNameIsMandatory"]} autoComplete="off"
                                    variant="outlined" name="firstName" onChange={this.handleChangeName} inputProps={{ maxLength: 100 }} />
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className="d-flex"  >
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className={"col-6 " + helperTextLastName} id="outletuser-lastname" value={lastName} label={resources["LastName"]}
                                    helperText={resources["InputOfLastNameIsMandatory"]} autoComplete="off"
                                    variant="outlined" name="lastName" onChange={this.handleChangeName} inputProps={{ maxLength: 100 }} />
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className="d-flex"  >
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className="col-3 helper-text-red" id="outletuser-initials" value={initials} label={resources["Initials"]}
                                    variant="outlined" name="initials" onChange={this.handleChange} inputProps={{ maxLength: 10 }} autoComplete="off"/>
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className="d-flex"  >
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className={"col-3 helper-text-red"} id="outletuser-password" value={password} label={resources["Password"]}
                                    variant="outlined" name="password" onChange={this.handleChange} inputProps={{ maxLength: 14 }} autoComplete="new-password"
                                    type='password' helperText={helperTextPassword} />
                                <span style={{ marginLeft: '5px', marginTop: '5px', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}><Tooltip title={resources["PasswordValidationRules"]}>
                                    <InfoIcon />
                                </Tooltip></span>
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <FormControl className="col-6 helper-text-red" variant="outlined" onChange={this.handleChange}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="outlined-type-native-simple">{resources["Language"]}</InputLabel>
                                </ThemeProvider>
                                <Select
                                    id="outletusers-language"
                                    native
                                    label={resources["Language"]}
                                    value={language}
                                    inputProps={{
                                        name: 'language',
                                        id: 'outletusers-language',
                                    }}>
                                    <option value={'nl-be'} key={'nl-be'}>{"Nederlands (BE)"}</option>
                                    <option value={'nl-nl'} key={'nl-nl'}>{"Nederlands (NL)"}</option>
                                    <option value={'fr-be'} key={'fr-be'}>{"Français"}</option>
                                    <option value={'en-gb'} key={'en-gb'}>{"English"} </option>
                                    <option value={'de-de'} key={'de-de'}>{"Deutsch"}</option>
                                    <option value={'cs-cz'} key={'cs-cz'}>{"Czech"}</option>
                                </Select>
                            </FormControl>
                            <div className="col-3"></div>
                        </Form.Group>
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <FormControl className="col-6 helper-text-red" variant="outlined" onChange={this.handleChange}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="outlined-type-native-simple">{resources["Gender"]}</InputLabel>
                                </ThemeProvider>
                                <Select
                                    id="outletusers-gender"
                                    native
                                    label={resources["Gender"]}
                                    value={gender}
                                    inputProps={{
                                        name: 'gender',
                                        id: 'outletusers-gender',
                                    }}>
                                    <option key='m' value="m">{resources["Male"]}</option>
                                    <option key='f' value="f">{resources["Female"]}</option>
                                </Select>
                            </FormControl>
                            <div className="col-3"></div>
                        </Form.Group>
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className="col-3 helper-text-red" id="outletuser-phone" value={phone} label={resources["Phone"]}
                                    helperText={helperTextPhone}
                                    variant="outlined" name="phone" onChange={this.handleChange} inputProps={{ maxLength: 20 }} />
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className="col-3 helper-text-red" id="outletuser-internalphone" value={internalPhoneNumber} label={resources["InternalPhoneNumber"]}
                                    helperText={helperTextInternalPhoneNumber}
                                    variant="outlined" name="internalPhoneNumber" onChange={this.handleChange} inputProps={{ maxLength: 20 }} />
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className="col-3 helper-text-red" id="outletuser-dms-reference" value={dmsReference} label={resources["DmsReference"]}
                                    variant="outlined" name="dmsReference" onChange={this.handleChange} inputProps={{ maxLength: 20 }} />
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className="col-3 helper-text-red" id="outletuser-dms-reference-2" value={dmsReference2} label={resources["DmsReference2"]}
                                    variant="outlined" name="dmsReference2" onChange={this.handleChange} inputProps={{ maxLength: 20 }} />
                            </ThemeProvider >
                        </Form.Group>

                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className="col-3 helper-text-red" id="outletuser-clock-reference" value={clockReference} label={resources["ClockReference"]}
                                    variant="outlined" name="clockReference" autoComplete="off" onChange={this.handleChange} inputProps={{ maxLength: 20 }} />
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className="col-3 helper-text-red" id="outletuser-planning-reference" value={planningReference} label={resources["PlanningReference"]}
                                    variant="outlined" name="planningReference" onChange={this.handleChange} inputProps={{ maxLength: 20 }} />
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className="col-3 helper-text-red" id="outletuser-chatname" value={internalDialogName} label={resources["ChatName"]}
                                    helperText={helperTextInternalDialogName}
                                    variant="outlined" name="internalDialogName" onChange={this.handleChangeChatName} inputProps={{ maxLength: 20 }} />
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className="col-3 helper-text-red" id="outletuser-pin" value={pin} label={resources["Pin"]}
                                    variant="outlined" name="pin" onChange={this.handleChangePin} type="number"
                                    onKeyDown={ExcludeSpecialCharactersFromInputTypeNumber(true)}
                                    inputProps={{ min: 1000, max: 9999 }} />
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <FormControl className="col-6 helper-text-red" variant="outlined" onChange={this.handleChangeWorkPreparationRoles}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="outlined-type-native-simple">{resources["WorkPreparationRole"]}</InputLabel>
                                </ThemeProvider>
                                <Select
                                    id="outletusers-workpreparation-role"
                                    native
                                    label={resources["WorkPreparationRole"]}
                                    value={workPreparationRoleId}
                                    inputProps={{
                                        name: 'workPreparation-role',
                                        id: 'outletusers-workPreparation-role-id',
                                    }}>
                                    <option value={0}>{resources["Select"]}</option>
                                    {this.renderWorkPreparationRoles()}
                                </Select>
                            </FormControl>
                            <div className="col-3"></div>
                        </Form.Group>
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <DropzoneArea className="col-4"
                                    acceptedFiles={['image/*']}
                                    filesLimit={1}
                                    showAlerts={false}
                                    showPreviews={true}
                                    dropzoneClass={disaplyImage}
                                    dropzoneParagraphClass={"dropzone-p"}
                                    previewText={""}
                                    maxFileSize={10000000}
                                    dropzoneText={resources["ProfileImage"]}
                                    onChange={this.handleFileChange}
                                /></ThemeProvider>
                        </Form.Group>

                        <div >
                            <Form.Group className="d-flex"  >
                                <div className="col-3"></div>
                                <div className="col-6 ml-0 p-0 ">
                                    <hr className="MuiDivider-root mt-2 mb-2" />
                                    <ThemeProvider theme={SelectTheme}>
                                        <InputLabel className="col-6 text-left float-left"><b>{resources["Outlets"]}  <em className="text-danger">*</em></b></InputLabel>
                                    </ThemeProvider >
                                    <Link className="col-4 text-right float-right pointer" variant="body2" id="selectedOutlets" onClick={() => this.handleShowRodalOutlets(true)}> {resources["Select"]}   </Link>
                                    <FormHelperText style={{ clear: "both" }}>{selectedOutlets}</FormHelperText>
                                </div>
                            </Form.Group>
                            <Form.Group className="d-flex"  >
                                <div className="col-3"></div>
                                <div className="col-6 ml-0 p-0 ">
                                    <hr className="MuiDivider-root  mb-2" />
                                    <ThemeProvider theme={SelectTheme}>
                                        <InputLabel className="col-6 text-left float-left"><b>{resources["Roles"]}  <em className="text-danger">*</em></b></InputLabel>
                                    </ThemeProvider >
                                    <Link className="col-4 text-right float-right pointer" variant="body2" id="selectedRoles" onClick={() => this.handleShowRodalRoles(true)}>  {resources["Select"]}   </Link>
                                    <FormHelperText style={{ clear: "both" }}>{selectedRoles}</FormHelperText>
                                </div>
                            </Form.Group>
                            <Form.Group className="d-flex"  >
                                <div className="col-3"></div>
                                <div className="col-6 ml-0 p-0  ">
                                    <hr className="MuiDivider-root  mb-2" />
                                    <ThemeProvider theme={SelectTheme}>
                                        <InputLabel className="col-6 text-left float-left "><b>{resources["Permissions"]}</b>  </InputLabel>
                                    </ThemeProvider >
                                    <Link className="col-4 text-right float-right pointer" variant="body2" id="selectedPermissions" onClick={() => this.handleShowRodalPermissions(true)}> {resources["Select"]}   </Link>
                                    <FormHelperText style={{ clear: "both" }}>{selectedPermissions}</FormHelperText>
                                </div>
                            </Form.Group>
                            <Form.Group className="d-flex"  >
                                <div className="col-3"></div>
                                <div className="col-6 ml-0 p-0  ">
                                    <hr className="MuiDivider-root mb-2" />
                                    <ThemeProvider theme={SelectTheme}>
                                        <InputLabel className="col-6 text-left float-left"><b>{resources["ChatGroups"]}</b>  </InputLabel>
                                    </ThemeProvider >
                                    <Link className={displayChatGroups} variant="body2" id="selectedChatGroups" onClick={this.state.mainOutlet > 0 ? () => this.handleShowRodalChatGroups(true) : () => { return true }}> {resources["Select"]}   </Link>
                                    <FormHelperText style={{ clear: "both" }}>{selectedChatGroups}</FormHelperText>
                                </div>
                            </Form.Group>
                            <Form.Group className="d-flex"  >
                                <div className="col-3"></div>
                                <div className="col-6 ml-0 p-0  ">
                                    <hr className="MuiDivider-root mb-2" />
                                    <ThemeProvider theme={SelectTheme}>
                                        <InputLabel className="col-6 text-left float-left"><b>{resources["DossierStacks"]}</b>  </InputLabel>
                                    </ThemeProvider >
                                    <Link className={displayDossierStacks} variant="body2" id="selectedDossierStacks" onClick={this.state.mainOutlet > 0 ? () => this.handleShowRodalDossierStacks(true) : () => { return true }}> {resources["Select"]}   </Link>
                                    <FormHelperText style={{ clear: "both" }}>{selectedDossierStacks}</FormHelperText>
                                </div>
                            </Form.Group>
                        </div>
                    </Form>
                </Paper>

                {/* select Mobo Outlets for outletUser */}
                <Rodal visible={this.state.showRodalOutlets} onClose={() => this.handleShowRodalOutlets(false)} width={500} height={550}>
                    {(this.props.accessibleMoboOutlets === undefined) ? (<div className="centered-loader"><CircularProgress /></div>) :
                        <div>
                            <div className="modal-header text-center mb-0 pb-0" style={{ borderBottom: "1px solid white" }}>
                                <InputLabel className="text-center"> {resources["Outlets"]}</InputLabel>
                            </div>
                            <div className="modal-body mb-0 pb-0" style={{ height: "430px", maxHeight: "430px" }}>

                                <Form.Group className=" overflow-auto" style={{ maxHeight: "300px" }}  >
                                    {this.renderSelectOutlets()}
                                </Form.Group>

                                <hr className="MuiDivider-root mb-2 " />
                                <Form.Group className="pt-3 " style={{ minHeight: "75px" }} >
                                    <div className="col-3"></div>
                                    <div className="float-left col-9 text-left p-0 ml-0" style={{ maxWidth: "100%" }}>
                                        <FormControl style={{ width: "310px" }} variant="outlined" >
                                            <InputLabel htmlFor="outlined-outlets">{resources["MainOutlet"]} </InputLabel>
                                            <Select
                                                label={resources["MainOutlet"]}
                                                native
                                                value={mainOutlet}
                                                name="mainOutlet"
                                                inputProps={{
                                                    name: 'mainOutlet',
                                                    id: 'outlined-outlets',
                                                }}
                                                onChange={this.handleChangeMainOutlet}>
                                                <option value={0}>{resources["DefaultDropdownValue"]}</option>
                                                {this.renderSelectMainOutlet()}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <FormHelperText className="color-red" style={{ display: `${this.state.mainOutlet < 1 ? "block" : 'none'}` }} >{resources["SelectionOfMainOutletIsMandatory"]}</FormHelperText>

                                </Form.Group>

                            </div>

                            <div className="modal-footer mt-0 pt-0"  >
                                <button className="btn btn-primary" id="btOkOutlets" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA", minWidth: "100px" }}
                                    onClick={() => this.handleConfirmSelectedOutlets()} disabled={this.state.selectedOutletsChecked.length < 1}>{resources["Ok"]}</button>

                            </div>
                            <span className="rodal-close"></span>
                        </div>}

                </Rodal>

                {/* select Roles  */}
                <Rodal visible={this.state.showRodalRoles} onClose={() => this.handleShowRodalRoles(false)} width={500} height={550}>
                    {(loadingRoles) ? (<div className="centered-loader"><CircularProgress /></div>) :
                        <div>
                            <div className="modal-header text-center mb-0 pb-0" style={{ borderBottom: "1px solid white" }}>
                                <InputLabel className="text-center"> {resources["Roles"]}</InputLabel>
                            </div>
                            <div className="modal-body mb-0 py-2" >

                                <Form.Group className=" overflow-auto" style={{ height: "400px", maxHeight: "400px" }}  >
                                    {this.renderSelectRoles()}
                                </Form.Group>                    </div>

                            <div className="modal-footer mt-0 pt-0"  >
                                <button className="btn btn-primary" id="btnOkRoles" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA", minWidth: "100px" }}
                                    onClick={() => this.handleConfirmSelectedRoles()} disabled={this.state.selectedRolesChecked.length < 1}>{resources["Ok"]}</button>

                            </div>
                            <span className="rodal-close"></span>
                        </div>}
                </Rodal>

                {/* select Permissions */}
                <Rodal visible={this.state.showRodalPermissions} onClose={() => this.handleShowRodalPermissions(false)} width={500} height={550}>
                    {(loadingPermisions) ? (<div className="centered-loader"><CircularProgress /></div>) :
                        <div>
                            <div className="modal-header text-center mb-0 pb-0" style={{ borderBottom: "1px solid white" }}>
                                <InputLabel className="text-center"> {resources["Permissions"]}</InputLabel>
                            </div>
                            <div className="modal-body mb-0 py-2" >

                                <Form.Group className=" overflow-auto" style={{ height: "400px", maxHeight: "400px" }}  >
                                    {this.renderPermissions()}
                                </Form.Group>

                            </div>

                            <div className="modal-footer mt-0 pt-0"  >
                                <button className="btn btn-primary" id="btnOkPermissions" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA", minWidth: "100px" }}
                                    onClick={() => this.handleConfirmSelectedPermissions()} >{resources["Ok"]}</button>

                            </div>
                            <span className="rodal-close"></span>

                        </div>}
                </Rodal>

                {/* select ChatGroups */}
                <Rodal visible={this.state.showRodalChatGroups} onClose={() => this.handleShowRodalChatGroups(false)} width={500} height={550}>
                    {(loadingChatGroups) ? (<div className="centered-loader"><CircularProgress /></div>) :
                        <div>
                            <div className="modal-header text-center mb-0 pb-0" style={{ borderBottom: "1px solid white" }}>
                                <InputLabel className="text-center"> {resources["ChatGroups"]}</InputLabel>
                            </div>
                            <div className="modal-body mb-0 py-2" >

                                <Form.Group className=" overflow-auto" style={{ height: "400px", maxHeight: "400px" }}  >
                                    {this.renderChatGroups()}
                                </Form.Group>

                            </div>
                            <div className="modal-footer mt-0 pt-0"  >
                                <button className="btn btn-primary" id="btnOkChatGroups" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA", minWidth: "100px" }}
                                    onClick={() => this.handleConfirmSelectedChatGroups()} >{resources["Ok"]}</button>

                            </div>
                            <span className="rodal-close"></span>
                        </div>}
                </Rodal>

                {/* select DossierStacks */}
                <Rodal visible={this.state.showRodalDossierStacks} onClose={() => this.handleShowRodalDossierStacks(false)} width={500} height={550}>
                    {(loadingDossierStacks) ? (<div className="centered-loader"><CircularProgress /></div>) :
                        <div>
                            <div className="modal-header text-center mb-0 pb-0" style={{ borderBottom: "1px solid white" }}>
                                <InputLabel className="text-center"> {resources["DossierStacks"]}</InputLabel>
                            </div>
                            <div className="modal-body mb-0 py-2" >
                                <Form.Group className=" overflow-auto" style={{ height: "400px", maxHeight: "400px" }}  >
                                    {this.renderDossierStacks()}
                                </Form.Group>
                            </div>
                            <div className="modal-footer mt-0 pt-0"  >
                                <button className="btn btn-primary" id="btnOkDossierStacks" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA", minWidth: "100px" }}
                                    onClick={() => this.handleConfirmSelectedDossierStacks()} >{resources["Ok"]}</button>
                            </div>
                            <span className="rodal-close"></span>
                        </div>}
                </Rodal>
            </div >
        );
    }

    renderMain() {
        return <div className={"py-4 grey " + (this.state.loading ? 'fadeOut' : 'fadeIn')} style={{ textAlign: "center" }}>
            {this.renderForm()}
        </div>;
    }

    render() {

        const { redirect, open, outletUserWasCreated, outletId, requestInProgress } = this.state;
        const { resources } = this.props;

        return (
            <div>
                {redirect && <DelayedRedirect to={{ pathname: redirect, state: { refreshGrid: outletUserWasCreated, outletId } }} delay={250} />}
                <Dialog fullScreen open={open} onClose={this.handleClose} TransitionComponent={Transition}>
                    <AppBar className="position-sticky ">
                        <Toolbar>
                            <IconButton id="close-add-outletuser" edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flex: 1 }}>
                                {resources["AddNewOutletUserTitle"]}
                            </Typography>
                            <Button id="outletuser-save" color="inherit" disabled={requestInProgress} onClick={this.saveNewOutletUser}>{resources["Save"]}</Button>
                        </Toolbar>
                    </AppBar>
                    <div className="container">
                        {this.renderMain()}
                    </div>
                </Dialog>
            </div >
        );
    }
}
